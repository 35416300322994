import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControl,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Countries from "../../assets/JSON/Countries";
import RegionsAndCommunes from "../../assets/JSON/RegionsAndCommunes";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#172833",
    borderColor: "#0F7099",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",

    "& fieldset": {
      borderColor: "#0F7099",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },
}));

const SecondFormContent = ({
  handleChangeStage,
  formValuesSecondStage,
  setFormValuesSecondStage,
  errorsSecondStage,
  setErrorsSecondStage,
  idiomaSeleccionado,
  setIdiomaSeleccionado,
}) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    let items = [...formValuesSecondStage.ABOGADOS];
    let item = { ...items[index] };

    switch (name) {
      case "NOMBRE_COMPLETO":
        item.NOMBRE_COMPLETO = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });

        break;

      case "RUT":
        item.RUT = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      case "CORREO":
        item.CORREO = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });

        break;

      case "CONFIRMAR_CORREO":
        item.CONFIRMAR_CORREO = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });

        break;

      case "PAIS":
        if (value !== "CL") {
          item.REGION = "";
          item.COMUNAS = "";
          item.COMUNA_SELECCIONADA = "";
        }

        item.PAIS = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });

        break;

      case "REGION":
        item.COMUNAS = RegionsAndCommunes.regiones.filter(
          (region) => region.NombreRegion.toString() === value.toString()
        )[0].comunas;

        item.REGION = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      case "COMUNAS":
        item.COMUNAS = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      case "COMUNA_SELECCIONADA":
        item.COMUNA_SELECCIONADA = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      case "DIRECCION":
        item.DIRECCION = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      case "TIPO_DOMICILIO":
        item.TIPO_DOMICILIO = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      case "CONTACTO":
        item.CONTACTO = value;
        items[index] = item;

        setFormValuesSecondStage({
          ...formValuesSecondStage,
          ABOGADOS: items,
        });
        break;

      default:
        setFormValuesSecondStage({
          ...formValuesSecondStage,
          [name]: value,
        });
        break;
    }
  };

  const handleAgregarAbogado = () => {
    const cantidadAbogados =
      formValuesSecondStage.ABOGADOS && formValuesSecondStage.ABOGADOS.length;
    let items = { ...formValuesSecondStage };

    setFormValuesSecondStage({
      ...items,
      ABOGADOS: [
        ...items.ABOGADOS,
        {
          INDEX: cantidadAbogados,
          NOMBRE_COMPLETO: "",
          RUT: "",
          CORREO: "",
          CONFIRMAR_CORREO: "",
          PAIS: "CL",
          REGION: "",
          COMUNAS: "",
          COMUNA_SELECCIONADA: "",
          DIRECCION: "",
          TIPO_DOMICILIO: "",
          CONTACTO: "",
        },
      ],
    });
  };

  const handleValidate = () => {
    let err = {};

    if (formValuesSecondStage.TIENE_ABOGADO === "si") {
      let errorsAbogados = [];

      formValuesSecondStage.ABOGADOS.forEach((abogado, index) => {
        let errAbogado = {};
        errAbogado.INDEX = index;
        errAbogado.NOMBRE_COMPLETO =
          abogado.NOMBRE_COMPLETO !== ""
            ? undefined
            : idiomaSeleccionado.validations_second_stage_form_cna[0];

        errAbogado.RUT =
          abogado.RUT !== ""
            ? !/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(abogado.RUT)
              ? idiomaSeleccionado.validations_second_stage_form_cna[2]
              : undefined
            : idiomaSeleccionado.validations_second_stage_form_cna[1];

        errAbogado.CORREO =
          abogado.CORREO !== ""
            ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
                abogado.CORREO
              )
              ? undefined
              : idiomaSeleccionado.validations_second_stage_form_cna[4]
            : idiomaSeleccionado.validations_second_stage_form_cna[3];

        errAbogado.CONFIRMAR_CORREO =
          abogado.CONFIRMAR_CORREO !== ""
            ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
                abogado.CONFIRMAR_CORREO
              )
              ? abogado.CONFIRMAR_CORREO === abogado.CORREO
                ? undefined
                : idiomaSeleccionado.validations_second_stage_form_cna[5]
              : idiomaSeleccionado.validations_second_stage_form_cna[4]
            : idiomaSeleccionado.validations_second_stage_form_cna[3];

        errAbogado.DIRECCION =
          abogado.DIRECCION !== "" ? undefined : idiomaSeleccionado.validations_second_stage_form_cna[6];

        errAbogado.CONTACTO =
          abogado.CONTACTO !== ""
            ? undefined
            : idiomaSeleccionado.validations_second_stage_form_cna[7];

        errAbogado.REGION =
          abogado.PAIS === "CL"
            ? abogado.REGION !== ""
              ? undefined
              : idiomaSeleccionado.validations_second_stage_form_cna[8]
            : "";

        errAbogado.COMUNA_SELECCIONADA =
          abogado.PAIS === "CL" && abogado.REGION !== ""
            ? abogado.COMUNA_SELECCIONADA !== ""
              ? undefined
              : idiomaSeleccionado.validations_second_stage_form_cna[9]
            : "";

        if (
          !errAbogado.NOMBRE_COMPLETO &&
          !errAbogado.RUT &&
          !errAbogado.CORREO &&
          !errAbogado.CONFIRMAR_CORREO &&
          !errAbogado.DIRECCION &&
          !errAbogado.CONTACTO &&
          !errAbogado.REGION &&
          !errAbogado.COMUNA_SELECCIONADA
        ) {
          delete errAbogado.INDEX;
          delete errAbogado.NOMBRE_COMPLETO;
          delete errAbogado.RUT;
          delete errAbogado.CORREO;
          delete errAbogado.CONFIRMAR_CORREO;
          delete errAbogado.DIRECCION;
          delete errAbogado.CONTACTO;
          delete errAbogado.REGION;
          delete errAbogado.COMUNA_SELECCIONADA;
        }

        if (Object.keys(errAbogado).length > 0) {
          errorsAbogados.push(errAbogado);
        }
      });

      err.ERRORS_ABOGADOS = errorsAbogados.length > 0 ? errorsAbogados : "";
    }

    setErrorsSecondStage({
      ...err,
    });

    return Object.values(err).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidate()) {
      handleChangeStage(3);
    }
  };

  const styles = {
    inputSize: {
      width: "550px",
      "@media(max-width: 1810px)": {
        width: "350px",
      },

      "@media(max-width: 400px)": {
        width: "270px",
      },

      "@media(max-width: 310px)": {
        width: "220px",
      },
    },

    contenedorBotones: {
      display: "flex",
      "@media(max-width: 480px)": {
        display: "block",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      textTransform: "none",
      height: "50px",
      width: "200px",
    },
  };

  return (
    <Grid container sx={{ display: "block" }}>
      <Box component="div" pl={3} pt={2} pb={2}>
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{
              fontFamily: "TitilliumWeb-Regular",
              fontSize: "1rem",
            }}
          >
            {idiomaSeleccionado.second_stage_form_cna[0]}
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={formValuesSecondStage.TIENE_ABOGADO}
            name="TIENE_ABOGADO"
            onChange={handleInputChange}
          >
            <FormControlLabel
              value="si"
              control={<Radio />}
              label={
                <Typography fontFamily="TitilliumWeb-Regular" fontSize="1rem">
                  {idiomaSeleccionado.second_stage_form_cna[1]}
                </Typography>
              }
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label={
                <Typography fontFamily="TitilliumWeb-Regular" fontSize="1rem">
                  {idiomaSeleccionado.second_stage_form_cna[2]}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {formValuesSecondStage.ABOGADOS.length > 0 &&
        formValuesSecondStage.TIENE_ABOGADO === "si" &&
        formValuesSecondStage.ABOGADOS.map((abogado, index) => (
          <>
            <Grid item xs={12} key={index}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
                >
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.second_stage_form_cna[3]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pl={2} spacing={5} pt={2} pb={2}>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.second_stage_form_cna[4]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.second_stage_form_cna[5]
                          }
                          name="NOMBRE_COMPLETO"
                          value={abogado.NOMBRE_COMPLETO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsSecondStage.ERRORS_ABOGADOS &&
                            errorsSecondStage.ERRORS_ABOGADOS[index] &&
                            errorsSecondStage.ERRORS_ABOGADOS[index]
                              .NOMBRE_COMPLETO && {
                              error: true,
                              helperText:
                                errorsSecondStage.ERRORS_ABOGADOS[index]
                                  .NOMBRE_COMPLETO,
                            })}
                        />
                      </Box>

                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.second_stage_form_cna[6]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder="XXXXXXXX-X"
                          name="RUT"
                          value={abogado.RUT}
                          sx={styles.inputSize}
                          inputProps={{
                            maxLength: 10,
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsSecondStage.ERRORS_ABOGADOS &&
                            errorsSecondStage.ERRORS_ABOGADOS[index] &&
                            errorsSecondStage.ERRORS_ABOGADOS[index].RUT && {
                              error: true,
                              helperText:
                                errorsSecondStage.ERRORS_ABOGADOS[index].RUT,
                            })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.second_stage_form_cna[7]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.second_stage_form_cna[8]
                          }
                          name="CORREO"
                          value={abogado.CORREO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsSecondStage.ERRORS_ABOGADOS &&
                            errorsSecondStage.ERRORS_ABOGADOS[index] &&
                            errorsSecondStage.ERRORS_ABOGADOS[index].CORREO && {
                              error: true,
                              helperText:
                                errorsSecondStage.ERRORS_ABOGADOS[index].CORREO,
                            })}
                        />
                      </Box>

                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.second_stage_form_cna[9]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.second_stage_form_cna[10]
                          }
                          name="CONFIRMAR_CORREO"
                          value={abogado.CONFIRMAR_CORREO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsSecondStage.ERRORS_ABOGADOS &&
                            errorsSecondStage.ERRORS_ABOGADOS[index] &&
                            errorsSecondStage.ERRORS_ABOGADOS[index]
                              .CONFIRMAR_CORREO && {
                              error: true,
                              helperText:
                                errorsSecondStage.ERRORS_ABOGADOS[index]
                                  .CONFIRMAR_CORREO,
                            })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
                >
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.second_stage_form_cna[11]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pl={2} spacing={5} pt={2} pb={2}>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            <Typography
                              sx={{
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              }}
                            >
                              {idiomaSeleccionado.second_stage_form_cna[12]}
                            </Typography>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="PAIS"
                            value={abogado.PAIS}
                            label="País*"
                            onChange={(e) => handleInputChange(e, index)}
                            sx={styles.inputSize}
                            inputProps={{
                              style: {
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              },
                            }}
                          >
                            {Countries.map((country) => (
                              <MenuItem value={country.value} key={index}>
                                <Typography
                                  sx={{
                                    fontFamily: "TitilliumWeb-Regular",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {country.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {abogado.PAIS === "CL" && (
                        <Box component="div" mb={5}>
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              <Typography
                                sx={{
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                }}
                                {...(errorsSecondStage.ERRORS_ABOGADOS &&
                                  errorsSecondStage.ERRORS_ABOGADOS[index] &&
                                  errorsSecondStage.ERRORS_ABOGADOS[index]
                                    .REGION && {
                                    color: "#d32f2f",
                                  })}
                              >
                                {idiomaSeleccionado.second_stage_form_cna[13]}
                              </Typography>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="REGION"
                              value={abogado.REGION}
                              label="Región *"
                              onChange={(e) => handleInputChange(e, index)}
                              sx={styles.inputSize}
                              inputProps={{
                                style: {
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                },
                              }}
                              {...(errorsSecondStage.ERRORS_ABOGADOS &&
                                errorsSecondStage.ERRORS_ABOGADOS[index] &&
                                errorsSecondStage.ERRORS_ABOGADOS[index]
                                  .REGION && {
                                  error: true,
                                })}
                            >
                              {RegionsAndCommunes.regiones.map(
                                (region, index) => (
                                  <MenuItem
                                    value={region.NombreRegion}
                                    key={index}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "TitilliumWeb-Regular",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {region.NombreRegion}
                                    </Typography>
                                  </MenuItem>
                                )
                              )}
                            </Select>

                            {errorsSecondStage.ERRORS_ABOGADOS &&
                              errorsSecondStage.ERRORS_ABOGADOS[index] &&
                              errorsSecondStage.ERRORS_ABOGADOS[index]
                                .REGION && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {
                                    errorsSecondStage.ERRORS_ABOGADOS[index]
                                      .REGION
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                      )}

                      {abogado.COMUNAS && abogado.COMUNAS.length > 0 && (
                        <Box component="div">
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              <Typography
                                sx={{
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                }}
                                {...(errorsSecondStage.ERRORS_ABOGADOS &&
                                  errorsSecondStage.ERRORS_ABOGADOS[index] &&
                                  errorsSecondStage.ERRORS_ABOGADOS[index]
                                    .COMUNA_SELECCIONADA && {
                                    color: "#d32f2f",
                                  })}
                              >
                                {idiomaSeleccionado.second_stage_form_cna[14]}
                              </Typography>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="COMUNA_SELECCIONADA"
                              value={abogado.COMUNA_SELECCIONADA}
                              label="COMUNA *"
                              onChange={(e) => handleInputChange(e, index)}
                              sx={styles.inputSize}
                              inputProps={{
                                style: {
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                },
                              }}
                              {...(errorsSecondStage.ERRORS_ABOGADOS &&
                                errorsSecondStage.ERRORS_ABOGADOS[index] &&
                                errorsSecondStage.ERRORS_ABOGADOS[index]
                                  .COMUNA_SELECCIONADA && {
                                  error: true,
                                })}
                            >
                              {abogado.COMUNAS.map((comuna, index) => (
                                <MenuItem value={comuna} key={index}>
                                  <Typography
                                    sx={{
                                      fontFamily: "TitilliumWeb-Regular",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {comuna}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>

                            {errorsSecondStage.ERRORS_ABOGADOS &&
                              errorsSecondStage.ERRORS_ABOGADOS[index] &&
                              errorsSecondStage.ERRORS_ABOGADOS[index]
                                .COMUNA_SELECCIONADA && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {
                                    errorsSecondStage.ERRORS_ABOGADOS[index]
                                      .COMUNA_SELECCIONADA
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.second_stage_form_cna[15]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.second_stage_form_cna[16]
                          }
                          name="DIRECCION"
                          value={abogado.DIRECCION}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsSecondStage.ERRORS_ABOGADOS &&
                            errorsSecondStage.ERRORS_ABOGADOS[index] &&
                            errorsSecondStage.ERRORS_ABOGADOS[index]
                              .DIRECCION && {
                              error: true,
                              helperText:
                                errorsSecondStage.ERRORS_ABOGADOS[index]
                                  .DIRECCION,
                            })}
                        />
                      </Box>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.second_stage_form_cna[17]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.second_stage_form_cna[18]
                          }
                          name="TIPO_DOMICILIO"
                          value={abogado.TIPO_DOMICILIO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Box>
                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label= {idiomaSeleccionado.second_stage_form_cna[19]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder="+000 0000 0000"
                          name="CONTACTO"
                          value={abogado.CONTACTO}
                          sx={styles.inputSize}
                          inputProps={{
                            maxLength: 12,
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsSecondStage.ERRORS_ABOGADOS &&
                            errorsSecondStage.ERRORS_ABOGADOS[index] &&
                            errorsSecondStage.ERRORS_ABOGADOS[index]
                              .CONTACTO && {
                              error: true,
                              helperText:
                                errorsSecondStage.ERRORS_ABOGADOS[index]
                                  .CONTACTO,
                            })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        ))}

      <Box component="div" pl={3} pt={2} pb={2}>
        {formValuesSecondStage.ABOGADOS.length > 0 &&
          formValuesSecondStage.TIENE_ABOGADO === "si" && (
            <Box component="div" mb={3}>
              <Button
                sx={{
                  fontFamily: "TitilliumWeb-SemiBoldItalic",
                  fontSize: "1rem",
                  color: "#7C9D1C",
                }}
                onClick={handleAgregarAbogado}
                {...(formValuesSecondStage.ABOGADOS &&
                  formValuesSecondStage.ABOGADOS.length > 9 && {
                    disabled: true,
                  })}
              >
                {formValuesSecondStage.ABOGADOS &&
                formValuesSecondStage.ABOGADOS.length <= 9
                  ?  idiomaSeleccionado.second_stage_form_cna[20]
                  : idiomaSeleccionado.second_stage_form_cna[21]}
              </Button>
            </Box>
          )}

        <Box component="div" sx={styles.contenedorBotones}>
          <Box component="div" mb={2}>
            <Button
              variant="contained"
              sx={{
                ...styles.button,
                backgroundColor: "#7C9D1C",
                marginRight: "1rem",
              }}
              onClick={() => handleChangeStage(1)}
            >
              {idiomaSeleccionado.second_stage_form_cna[22]}
            </Button>
          </Box>
          <Box component="div">
            <Button
              variant="contained"
              sx={{ ...styles.button, backgroundColor: "#4E87AE" }}
              onClick={handleSubmit}
            >
              {idiomaSeleccionado.second_stage_form_cna[23]}
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default SecondFormContent;
