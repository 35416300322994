import React from "react";
import {
  Grid,
  Box,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Procedimiento_Regular from "../../assets/imgs/ServicesCNA/procedimiento_regular.webp";
import Procedimiento_Abreviado from "../../assets/imgs/ServicesCNA/procedimiento_abreviado.webp";
import Procedimiento_Abreviado_Especial from "../../assets/imgs/ServicesCNA/procedimiento_abreviado_especial.webp";
import Mediacion from "../../assets/imgs/ServicesCNA/mediacion.svg";
import Poligono from "../../assets/icons/ServicesCNA/poligono.svg";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const styles = {
  contenedorGeneral: {
    backgroundColor: "#F5F5F5",
    paddingTop: "5rem",
  },

  titulo: {
    fontFamily: "TitilliumWeb-SemiBold",
    fontSize: "3.5rem",
    "@media(max-width: 600px)": {
      fontSize: "2.3rem",
    },
  },

  contenedorImagen: {
    "@media(max-width: 1200px)": {
      textAlign: "center",
    },
  },

  columna: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
    "@media(max-width: 600px)": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },

    "@media(max-width: 300px)": {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },

  imagen: {
    width: "340px",

    "@media(max-width: 1536px)": {
      width: "300px",
    },

    "@media(max-width: 600px)": {
      width: "240px",
    },

    "@media(max-width: 300px)": {
      width: "140px",
    },
  },
};

const Services = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  return (
    <Box component="div">
      <NavbarMenu
        value={2}
        menuItem={4}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Box component="div" sx={styles.contenedorGeneral}>
        <Box component="div" textAlign="center">
          <Typography variant="h1" color="#1C3254" mb={2} sx={styles.titulo}>
            {idiomaSeleccionado.services_cna[0]}
          </Typography>
          <Typography
            variant="h3"
            color="#172833"
            sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
          >
            {idiomaSeleccionado.services_cna[1]}
          </Typography>
        </Box>

        <Box component="div" padding={5}>
          <Typography
            variant="h1"
            mb={3}
            color="#1C3254"
            sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "2rem" }}
          >
            {idiomaSeleccionado.services_cna[2]}
          </Typography>

          <Box component="div" mb={3}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.services_cna[3]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={5}>
                  <Grid item xs={12} lg={3} pl={2} pr={2}>
                    <Box component="div" sx={styles.contenedorImagen}>
                      <Box
                        component="img"
                        alt="Imagen procedimiento regular del CNA"
                        src={Procedimiento_Regular}
                        mb={3}
                        sx={styles.imagen}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[4]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[5]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[6]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[7]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[8]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[9]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[10]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[11]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[12]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[13]}
                      </Typography>
                    </Box>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[14]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[15]}
                      </Typography>
                    </Box>
                    <Box component="div">
                      <Link href="/tarifas/tabla_tarifas" underline="none">
                        <Typography
                          color="#7C9D1C"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBoldItalic",
                            fontSize: "1.2rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[16]}
                        </Typography>
                      </Link>
                      <Link href="/documentacion/reglamentos" underline="none">
                        <Typography
                          color="#7C9D1C"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBoldItalic",
                            fontSize: "1.2rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[17]}
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.services_cna[18]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={5}>
                  <Grid item xs={12} lg={3} pl={2} pr={2} mb={3}>
                    <Box component="div" sx={styles.contenedorImagen}>
                      <Box
                        component="img"
                        alt="Imagen procedimiento abreviado del CNA"
                        src={Procedimiento_Abreviado}
                        sx={styles.imagen}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[19]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[20]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[21]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[22]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[23]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[24]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[25]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[26]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[27]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[28]}
                      </Typography>
                    </Box>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[29]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[30]}
                      </Typography>
                    </Box>
                    <Box component="div">
                      <Link href="/tarifas/tabla_tarifas" underline="none">
                        <Typography
                          color="#7C9D1C"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBoldItalic",
                            fontSize: "1.2rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[31]}
                        </Typography>
                      </Link>
                      <Link href="/documentacion/reglamentos" underline="none">
                        <Typography
                          color="#7C9D1C"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBoldItalic",
                            fontSize: "1.2rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[32]}
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box component="div">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.services_cna[33]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={5}>
                  <Grid item xs={12} lg={3} pl={2} pr={2} mb={3}>
                    <Box component="div" sx={styles.contenedorImagen}>
                      <Box
                        component="img"
                        alt="Imagen procedimiento abreviado especial del CNA"
                        src={Procedimiento_Abreviado_Especial}
                        sx={styles.imagen}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[34]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[35]}
                      </Typography>
                    </Box>

                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[36]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[37]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[38]}
                        </Typography>
                      </Box>
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[39]}
                      </Typography>
                    </Box>
                    <Box component="div">
                      <Link href="/tarifas/tabla_tarifas" underline="none">
                        <Typography
                          color="#7C9D1C"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBoldItalic",
                            fontSize: "1.2rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[40]}
                        </Typography>
                      </Link>
                      <Link href="/documentacion/reglamentos" underline="none">
                        <Typography
                          color="#7C9D1C"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBoldItalic",
                            fontSize: "1.2rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[41]}
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box component="div" padding={5}>
          <Typography
            variant="h1"
            mb={3}
            color="#1C3254"
            sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "2rem" }}
          >
            {idiomaSeleccionado.services_cna[42]}
          </Typography>

          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.services_cna[42]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={5}>
                  <Grid item xs={12} lg={3} pl={2} pr={2} mb={2}>
                    <Box component="div" sx={styles.contenedorImagen}>
                      <Box
                        component="img"
                        alt="Imagen mediación del CNA"
                        src={Mediacion}
                        sx={styles.imagen}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={7} sx={styles.columna}>
                    <Box component="div" mb={3}>
                      <Box component="div" display="flex">
                        <Box
                          component="img"
                          src={Poligono}
                          alt="Poligono"
                          mr={1}
                        />
                        <Typography
                          color="#4E87AE"
                          sx={{
                            fontFamily: "TitilliumWeb-SemiBold",
                            fontSize: "1.3rem",
                          }}
                        >
                          {idiomaSeleccionado.services_cna[43]}
                        </Typography>
                      </Box>

                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.services_cna[44]}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          component="div"
          padding={10}
          textAlign="center"
          sx={{ backgroundColor: "#FFFFFF", marginBottom: "0" }}
        >
          <Typography
            color="#1C3254"
            sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.5rem" }}
          >
            {idiomaSeleccionado.services_cna[45]}
          </Typography>
          <Link href="/procedimientos/como_trabajamos" underline="none">
            <Typography
              color="#7C9D1C"
              sx={{
                fontFamily: "TitilliumWeb-SemiBoldItalic",
                fontSize: "1.2rem",
              }}
            >
              {idiomaSeleccionado.services_cna[46]}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
