import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControl,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Countries from "../../assets/JSON/Countries";
import RegionsAndCommunes from "../../assets/JSON/RegionsAndCommunes";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#172833",
    borderColor: "#0F7099",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",

    "& fieldset": {
      borderColor: "#0F7099",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },
}));

const ThirdFormContent = ({
  handleChangeStage,
  formValuesThirdStage,
  setFormValuesThirdStage,
  errorsThirdStage,
  setErrorsThirdStage,
  idiomaSeleccionado,
  setIdiomaSeleccionado,
}) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    let items = [...formValuesThirdStage.DEMANDADOS];
    let item = { ...items[index] };

    switch (name) {
      case "NOMBRE_COMPLETO":
        item.NOMBRE_COMPLETO = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });

        break;

      case "RUT":
        item.RUT = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      case "CORREO":
        item.CORREO = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });

        break;

      case "CONFIRMAR_CORREO":
        item.CONFIRMAR_CORREO = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });

        break;

      case "PAIS":
        if (value !== "CL") {
          item.REGION = "";
          item.COMUNAS = "";
          item.COMUNA_SELECCIONADA = "";
        }

        item.PAIS = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });

        break;

      case "REGION":
        item.COMUNAS = RegionsAndCommunes.regiones.filter(
          (region) => region.NombreRegion.toString() === value.toString()
        )[0].comunas;

        item.REGION = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      case "COMUNAS":
        item.COMUNAS = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      case "COMUNA_SELECCIONADA":
        item.COMUNA_SELECCIONADA = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      case "DIRECCION":
        item.DIRECCION = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      case "TIPO_DOMICILIO":
        item.TIPO_DOMICILIO = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      case "CONTACTO":
        item.CONTACTO = value;
        items[index] = item;

        setFormValuesThirdStage({
          ...formValuesThirdStage,
          DEMANDADOS: items,
        });
        break;

      default:
        setFormValuesThirdStage({
          ...formValuesThirdStage,
          [name]: value,
        });
        break;
    }
  };

  const handleAgregarAbogado = () => {
    const cantidadDemandados =
      formValuesThirdStage.DEMANDADOS && formValuesThirdStage.DEMANDADOS.length;
    let items = { ...formValuesThirdStage };

    setFormValuesThirdStage({
      ...items,
      DEMANDADOS: [
        ...items.DEMANDADOS,
        {
          INDEX: cantidadDemandados,
          NOMBRE_COMPLETO: "",
          RUT: "",
          CORREO: "",
          CONFIRMAR_CORREO: "",
          PAIS: "CL",
          REGION: "",
          COMUNAS: "",
          COMUNA_SELECCIONADA: "",
          DIRECCION: "",
          TIPO_DOMICILIO: "",
          CONTACTO: "",
        },
      ],
    });
  };

  const handleValidate = () => {
    let err = {};

    let errorsDemandados = [];

    formValuesThirdStage.DEMANDADOS.forEach((demandado, index) => {
      let errDemandado = {};
      errDemandado.INDEX = index;
      errDemandado.NOMBRE_COMPLETO =
        demandado.NOMBRE_COMPLETO !== ""
          ? undefined
          : idiomaSeleccionado.validations_third_stage_form_cna[0];

      errDemandado.RUT =
        demandado.RUT !== ""
          ? !/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(demandado.RUT)
            ? idiomaSeleccionado.validations_third_stage_form_cna[2]
            : undefined
          : idiomaSeleccionado.validations_third_stage_form_cna[1];

      errDemandado.CORREO =
        demandado.CORREO !== ""
          ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
              demandado.CORREO
            )
            ? undefined
            : idiomaSeleccionado.validations_third_stage_form_cna[4]
          : idiomaSeleccionado.validations_third_stage_form_cna[3];

      errDemandado.CONFIRMAR_CORREO =
        demandado.CONFIRMAR_CORREO !== ""
          ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
              demandado.CONFIRMAR_CORREO
            )
            ? demandado.CONFIRMAR_CORREO === demandado.CORREO
              ? undefined
              : idiomaSeleccionado.validations_third_stage_form_cna[5]
            : idiomaSeleccionado.validations_third_stage_form_cna[4]
          : idiomaSeleccionado.validations_third_stage_form_cna[3];

      errDemandado.DIRECCION =
        demandado.DIRECCION !== "" ? undefined : idiomaSeleccionado.validations_third_stage_form_cna[6];

      errDemandado.CONTACTO =
        demandado.CONTACTO !== ""
          ? undefined
          : idiomaSeleccionado.validations_third_stage_form_cna[7];

      errDemandado.REGION =
        demandado.PAIS === "CL"
          ? demandado.REGION !== ""
            ? undefined
            : idiomaSeleccionado.validations_third_stage_form_cna[8]
          : "";

      errDemandado.COMUNA_SELECCIONADA =
        demandado.PAIS === "CL" && demandado.REGION !== ""
          ? demandado.COMUNA_SELECCIONADA !== ""
            ? undefined
            : idiomaSeleccionado.validations_third_stage_form_cna[9]
          : "";

      if (
        !errDemandado.NOMBRE_COMPLETO &&
        !errDemandado.RUT &&
        !errDemandado.CORREO &&
        !errDemandado.CONFIRMAR_CORREO &&
        !errDemandado.DIRECCION &&
        !errDemandado.CONTACTO &&
        !errDemandado.REGION &&
        !errDemandado.COMUNA_SELECCIONADA
      ) {
        delete errDemandado.INDEX;
        delete errDemandado.NOMBRE_COMPLETO;
        delete errDemandado.RUT;
        delete errDemandado.CORREO;
        delete errDemandado.CONFIRMAR_CORREO;
        delete errDemandado.DIRECCION;
        delete errDemandado.CONTACTO;
        delete errDemandado.REGION;
        delete errDemandado.COMUNA_SELECCIONADA;
      }

      if (Object.keys(errDemandado).length > 0) {
        errorsDemandados.push(errDemandado);
      }
    });

    err.ERRORS_DEMANDADOS = errorsDemandados.length > 0 ? errorsDemandados : "";

    setErrorsThirdStage({
      ...err,
    });

    return Object.values(err).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidate()) {
      handleChangeStage(4);
    }
  };

  const styles = {
    inputSize: {
      width: "550px",
      "@media(max-width: 1810px)": {
        width: "350px",
      },

      "@media(max-width: 400px)": {
        width: "270px",
      },

      "@media(max-width: 310px)": {
        width: "220px",
      },
    },

    contenedorBotones: {
      display: "flex",
      "@media(max-width: 480px)": {
        display: "block",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      textTransform: "none",
      height: "50px",
      width: "200px",
    },
  };
  return (
    <Grid container>
      {formValuesThirdStage.DEMANDADOS.length > 0 &&
        formValuesThirdStage.DEMANDADOS.map((demandado, index) => (
          <>
            <Grid item xs={12} key={index}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
                >
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.third_stage_form_cna[0]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pl={2} spacing={5} pt={2} pb={2}>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[1]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.third_stage_form_cna[2]
                          }
                          name="NOMBRE_COMPLETO"
                          value={demandado.NOMBRE_COMPLETO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index]
                              .NOMBRE_COMPLETO && {
                              error: true,
                              helperText:
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .NOMBRE_COMPLETO,
                            })}
                        />
                      </Box>
                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[3]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder="XXXXXXXX-X"
                          name="RUT"
                          value={demandado.RUT}
                          sx={styles.inputSize}
                          inputProps={{
                            maxLength: 10,
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index].RUT && {
                              error: true,
                              helperText:
                                errorsThirdStage.ERRORS_DEMANDADOS[index].RUT,
                            })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[4]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.third_stage_form_cna[5]
                          }
                          name="CORREO"
                          value={demandado.CORREO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index]
                              .CORREO && {
                              error: true,
                              helperText:
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .CORREO,
                            })}
                        />
                      </Box>
                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[6]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.third_stage_form_cna[7]
                          }
                          name="CONFIRMAR_CORREO"
                          value={demandado.CONFIRMAR_CORREO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index]
                              .CONFIRMAR_CORREO && {
                              error: true,
                              helperText:
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .CONFIRMAR_CORREO,
                            })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
                >
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.third_stage_form_cna[8]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pl={2} spacing={5} pt={2} pb={2}>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            <Typography
                              sx={{
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              }}
                            >
                              {idiomaSeleccionado.third_stage_form_cna[9]}
                            </Typography>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="PAIS"
                            value={demandado.PAIS}
                            label="País*"
                            onChange={(e) => handleInputChange(e, index)}
                            sx={styles.inputSize}
                            inputProps={{
                              style: {
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              },
                            }}
                          >
                            {Countries.map((country, index) => (
                              <MenuItem value={country.value} key={index}>
                                <Typography
                                  sx={{
                                    fontFamily: "TitilliumWeb-Regular",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {country.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {demandado.PAIS === "CL" && (
                        <Box component="div" mb={5}>
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              <Typography
                                sx={{
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                }}
                                {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                                  errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                                  errorsThirdStage.ERRORS_DEMANDADOS[index]
                                    .REGION && {
                                    color: "#d32f2f",
                                  })}
                              >
                                {idiomaSeleccionado.third_stage_form_cna[10]}
                              </Typography>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="REGION"
                              value={demandado.REGION}
                              label="Región *"
                              onChange={(e) => handleInputChange(e, index)}
                              sx={styles.inputSize}
                              inputProps={{
                                style: {
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                },
                              }}
                              {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                                errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .REGION && {
                                  error: true,
                                })}
                            >
                              {RegionsAndCommunes.regiones.map(
                                (region, index) => (
                                  <MenuItem
                                    value={region.NombreRegion}
                                    key={index}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "TitilliumWeb-Regular",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {region.NombreRegion}
                                    </Typography>
                                  </MenuItem>
                                )
                              )}
                            </Select>

                            {errorsThirdStage.ERRORS_DEMANDADOS &&
                              errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                              errorsThirdStage.ERRORS_DEMANDADOS[index]
                                .REGION && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {
                                    errorsThirdStage.ERRORS_DEMANDADOS[index]
                                      .REGION
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                      )}

                      {demandado.COMUNAS && demandado.COMUNAS.length > 0 && (
                        <Box component="div">
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              <Typography
                                sx={{
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                }}
                                {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                                  errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                                  errorsThirdStage.ERRORS_DEMANDADOS[index]
                                    .COMUNA_SELECCIONADA && {
                                    color: "#d32f2f",
                                  })}
                              >
                                {idiomaSeleccionado.third_stage_form_cna[11]}
                              </Typography>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="COMUNA_SELECCIONADA"
                              value={demandado.COMUNA_SELECCIONADA}
                              label="COMUNA *"
                              onChange={(e) => handleInputChange(e, index)}
                              sx={styles.inputSize}
                              inputProps={{
                                style: {
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                },
                              }}
                              {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                                errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .COMUNA_SELECCIONADA && {
                                  error: true,
                                })}
                            >
                              {demandado.COMUNAS.map((comuna, index) => (
                                <MenuItem value={comuna} key={index}>
                                  <Typography
                                    sx={{
                                      fontFamily: "TitilliumWeb-Regular",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {comuna}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>

                            {errorsThirdStage.ERRORS_DEMANDADOS &&
                              errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                              errorsThirdStage.ERRORS_DEMANDADOS[index]
                                .COMUNA_SELECCIONADA && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {
                                    errorsThirdStage.ERRORS_DEMANDADOS[index]
                                      .COMUNA_SELECCIONADA
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[12]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.third_stage_form_cna[13]
                          }
                          name="DIRECCION"
                          value={demandado.DIRECCION}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index]
                              .DIRECCION && {
                              error: true,
                              helperText:
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .DIRECCION,
                            })}
                        />
                      </Box>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[14]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.third_stage_form_cna[15]
                          }
                          name="TIPO_DOMICILIO"
                          value={demandado.TIPO_DOMICILIO}
                          sx={styles.inputSize}
                          onChange={(e) => handleInputChange(e, index)}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                        />
                      </Box>
                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.third_stage_form_cna[16]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder="+000 0000 0000"
                          name="CONTACTO"
                          value={demandado.CONTACTO}
                          sx={styles.inputSize}
                          inputProps={{
                            maxLength: 12,
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsThirdStage.ERRORS_DEMANDADOS &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index] &&
                            errorsThirdStage.ERRORS_DEMANDADOS[index]
                              .CONTACTO && {
                              error: true,
                              helperText:
                                errorsThirdStage.ERRORS_DEMANDADOS[index]
                                  .CONTACTO,
                            })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        ))}

      <Box component="div" pl={3} pt={2} pb={2}>
        <Box component="div" mb={3}>
          <Button
            sx={{
              fontFamily: "TitilliumWeb-SemiBoldItalic",
              fontSize: "1rem",
              color: "#7C9D1C",
            }}
            onClick={handleAgregarAbogado}
            {...(formValuesThirdStage.DEMANDADOS &&
              formValuesThirdStage.DEMANDADOS.length > 9 && {
                disabled: true,
              })}
          >
            {formValuesThirdStage.DEMANDADOS &&
            formValuesThirdStage.DEMANDADOS.length <= 9
              ? idiomaSeleccionado.third_stage_form_cna[17]
              : idiomaSeleccionado.third_stage_form_cna[18]}
          </Button>
        </Box>
        <Box component="div" sx={styles.contenedorBotones}>
          <Box component="div" mb={2}>
            <Button
              variant="contained"
              sx={{
                ...styles.button,
                backgroundColor: "#7C9D1C",
                marginRight: "1rem",
              }}
              onClick={() => handleChangeStage(2)}
            >
              {idiomaSeleccionado.third_stage_form_cna[19]}
            </Button>
          </Box>
          <Box component="div" mb={2}>
            <Button
              variant="contained"
              sx={{ ...styles.button, backgroundColor: "#4E87AE" }}
              onClick={handleSubmit}
            >
              {idiomaSeleccionado.third_stage_form_cna[20]}
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ThirdFormContent;
