import * as React from 'react';
import { Box, Button } from '@mui/material';

const styles = {
	contenedorBoton: {
		position: 'fixed',
		justifyContent: 'flex-end',
		bottom: '2rem',
		// marginTop: '40rem',
		textAlign: 'right',
		width: '100%',

		'@media(max-width: 1200px)': {
			visibility: 'hidden',
		},

		'@media(max-width: 960px)': {
			visibility: 'hidden',
		},

		'@media(max-width: 600px)': {
			visibility: 'hidden',
		},
	},

	button: {
		backgroundColor: '#4E87AE',
		color: '#F5F5F5',
		fontFamily: 'TitilliumWeb-Regular',
		fontSize: '1rem',
		borderRadius: '38px',
		textTransform: 'capitalize',
		marginRight: '2rem',
		width: '200px',
	},
};

const ArbitrationButton = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
	return (
		<Box sx={styles.contenedorBoton}>
			<Button variant="contained" sx={styles.button} href="/formulario_arbitraje">
				{idiomaSeleccionado.form_cna[0]}
			</Button>
		</Box>
	);
};

export default ArbitrationButton;
