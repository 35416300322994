import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";

import constitucion_arbitraje from "../../assets/icons/RatesTable/constitucion_arbitraje.webp";
import tarifa_administracion_cna from "../../assets/icons/RatesTable/tarifa_administracion_cna.webp";
import tasa_constitucion_arbitraje from "../../assets/icons/RatesTable/tasa_constitucion_arbitraje.webp";
import Poligono from "../../assets/icons/RatesTable/poligono.svg";
import NavbarMenu from "../../components/NavbarMenu";

const RatesTables = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "3rem",
    },

    titulo: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "1rem",

      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
    },

    subtitulo: {
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      marginBottom: "2rem",
    },
    datoImportante: {
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      marginBottom: "2rem",
      fontWeight: 'bold'
    },

    tarjeta: {
      height: "650px",
      "@media(max-width: 1536px)": {
        height: "720px",
      },
      "@media(max-width: 1200px)": {
        height: "900px",
      },
      "@media(max-width: 900px)": {
        height: "650px",
      },
      "@media(max-width: 600px)": {
        height: "850px",
      },
      "@media(max-width: 300px)": {
        height: "1000px",
      },
    },
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#388E97",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const rows = [
    {
      desde: "0,00",
      hasta: "1000,00",
      valor: idiomaSeleccionado.rates_table[6],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "1000,01",
      hasta: "3.000,00",
      valor: idiomaSeleccionado.rates_table[7],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "3.000,01",
      hasta: "8.000,00",
      valor: "7%",
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "8.000,01",
      hasta: "25.000,00",
      valor: idiomaSeleccionado.rates_table[8],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "25.000,01",
      hasta: "65.000,00",
      valor: idiomaSeleccionado.rates_table[9],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "65.000,01",
      hasta: "150.000,00",
      valor: idiomaSeleccionado.rates_table[10],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "150.000,01",
      hasta: "350.000,00",
      valor: idiomaSeleccionado.rates_table[11],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "350.000,01",
      hasta: "1.850.000,00",
      valor: idiomaSeleccionado.rates_table[12],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: "1.850.000,01",
      hasta: "5.000.000,00",
      valor: idiomaSeleccionado.rates_table[13],
      iva: idiomaSeleccionado.rates_table[15],
    },
    {
      desde: idiomaSeleccionado.rates_table[14],
      hasta: "5.000.000,00",
      valor: "28.250 UF",
      iva: idiomaSeleccionado.rates_table[15],
    },
  ];

  return (
    <Box component="div">
      <NavbarMenu
        value={4}
        menuItem={9}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Box sx={styles.contenedorGeneral} component="div">
        <Grid container>
          <Grid item xs={12} textAlign="center">
            <Box component="div">
              <Typography variant="h1" sx={styles.titulo}>
                {idiomaSeleccionado.rates_table[0]}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Box component="div">
              <Typography variant="h4" sx={styles.subtitulo}>
                {idiomaSeleccionado.rates_table[1]}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb={5}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: "57rem", margin: "auto" }}
            >
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        paddingLeft: "2.5rem",
                        fontFamily: "TitilliumWeb-SemiBold",
                        fontSize: "1.2rem",
                      }}
                    >
                      {idiomaSeleccionado.rates_table[2]}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        paddingLeft: "2.5rem",
                        fontFamily: "TitilliumWeb-SemiBold",
                        fontSize: "1.2rem",
                      }}
                    >
                      {idiomaSeleccionado.rates_table[3]}
                    </StyledTableCell>
                    <StyledTableCell
                      width={'40%'}
                      sx={{
                        paddingLeft: "2.5rem",
                        fontFamily: "TitilliumWeb-SemiBold",
                        fontSize: "1.2rem",
                      }}
                    >
                      {idiomaSeleccionado.rates_table[4]}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        paddingLeft: "2.5rem",
                        fontFamily: "TitilliumWeb-SemiBold",
                        fontSize: "1.2rem",
                      }}
                    >
                      {idiomaSeleccionado.rates_table[5]}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        sx={{
                          paddingLeft: "2.5rem",
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {row.desde}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          paddingLeft: "2.5rem",
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {row.hasta}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          paddingLeft: "2.5rem",
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {row.valor}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          paddingLeft: "2.5rem",
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {row.iva}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} textAlign="center" mb={5}>
            <Box component="div">
              <Typography variant="h4" sx={styles.datoImportante}>
                {idiomaSeleccionado.rates_table[16]}
              </Typography>
              <Typography variant="h4" sx={styles.datoImportante}>
                {idiomaSeleccionado.rates_table[17]}
              </Typography>
            </Box>
          </Grid>
          
        </Grid>

        <Grid container spacing={2} sx={{ backgroundColor: "#F5F5F5" }}>
          <Grid item xs={12} md={4}>
            <Box
              component="div"
              padding={5}
              sx={{ backgroundColor: "#FFFFFF", ...styles.tarjeta }}
            >
              <Box
                component="img"
                src={constitucion_arbitraje}
                alt="Icono Constitución del Arbitraje CNA"
                width="150px"
                mb={4}
              />
              <Typography
                color="#1C3254"
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                {idiomaSeleccionado.rates_table[18]}
              </Typography>
              <Typography
                color="#4E87AE"
                mb={4}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                {idiomaSeleccionado.rates_table[19]}
              </Typography>

              <Box component="div" display="flex">
                <Box
                  component="img"
                  src={Poligono}
                  alt="Poligono"
                  pr={1}
                  height="25px"
                  width="10px"
                />
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                >
                  {idiomaSeleccionado.rates_table[20]}
                </Typography>
              </Box>

              <Box component="div" display="flex">
                <Box
                  component="img"
                  src={Poligono}
                  alt="Poligono"
                  pr={1}
                  height="25px"
                  width="10px"
                />
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                >
                  {idiomaSeleccionado.rates_table[21]}
                </Typography>
              </Box>

              <Box component="div" display="flex">
                <Box
                  component="img"
                  src={Poligono}
                  alt="Poligono"
                  pr={1}
                  height="25px"
                  width="10px"
                />
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                >
                  {idiomaSeleccionado.rates_table[22]}
                </Typography>
              </Box>
              <Box component="div" display="flex">
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem",fontWeight:'bold' }}
                >
                  {idiomaSeleccionado.rates_table[23]}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              component="div"
              padding={5}
              sx={{ backgroundColor: "#FFFFFF", ...styles.tarjeta }}
            >
              <Box
                component="img"
                src={tarifa_administracion_cna}
                alt="Icono Constitución del Arbitraje CNA"
                width="150px"
                mb={4}
              />
              <Typography
                color="#1C3254"
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                {idiomaSeleccionado.rates_table[24]}
              </Typography>
              <Typography
                color="#4E87AE"
                mb={4}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                {idiomaSeleccionado.rates_table[25]}
              </Typography>

              <Box component="div" display="flex">
                <Box
                  component="img"
                  src={Poligono}
                  alt="Poligono"
                  pr={1}
                  height="25px"
                  width="10px"
                />
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                >
                  {idiomaSeleccionado.rates_table[26]}
                </Typography>
              </Box>

              <Box component="div" display="flex">
                <Box
                  component="img"
                  src={Poligono}
                  alt="Poligono"
                  pr={1}
                  height="25px"
                  width="10px"
                />
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                >
                  {idiomaSeleccionado.rates_table[27]}
                </Typography>
              </Box>

              <Box component="div" display="flex">
                <Box
                  component="img"
                  src={Poligono}
                  alt="Poligono"
                  pr={1}
                  height="25px"
                  width="10px"
                />
                <Typography
                  color="#172833"
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                >
                  {idiomaSeleccionado.rates_table[28]}
                </Typography>
              </Box>
              <Box component="div" display="flex" mt={2}>
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem",fontWeight:'bold' }}
                >
                  {idiomaSeleccionado.rates_table[29]}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              component="div"
              padding={5}
              sx={{ backgroundColor: "#FFFFFF", ...styles.tarjeta }}
            >
              <Box
                component="img"
                src={tasa_constitucion_arbitraje}
                alt="Icono Constitución del Arbitraje CNA"
                width="150px"
                mb={4}
              />
              <Typography
                color="#1C3254"
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                {idiomaSeleccionado.rates_table[30]}
              </Typography>


              <Typography
                mt={2}
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem", fontWeight:'bold' }}
              >
                {idiomaSeleccionado.rates_table[31]}
              </Typography>


              <Typography
                color="#172833"
                mt={2}
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem", fontWeight:'bold' }}
              >
                {idiomaSeleccionado.rates_table[32]}
              </Typography>
              
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.rates_table[33]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.rates_table[34]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.rates_table[35]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.rates_table[36]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.rates_table[37]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.rates_table[38]}
              </Typography>
              <Box component="div" display="flex" mt={2}>
                <Typography
                  color="#172833"
                  mb={2}
                  sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem",fontWeight:'bold' }}
                >
                  {idiomaSeleccionado.rates_table[39]}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RatesTables;
