import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Popover,
  Icon,
  Box,
  Link,
  Typography,
} from "@mui/material";

import es from "./../locales/es.json";
import en from "./../locales/en.json";

import MenuItem from "@mui/material/MenuItem";
import Logo_NavbarMenu_CNA from "../assets/imgs/Navbar/logo_cna_navbar.webp";
import Icon_Espanol from "../assets/icons/Navbar/icon_bandera_chile.webp";
import Icon_English from "../assets/icons/Navbar/icon_bandera_usa.webp";

const NavbarDesktop = ({ value, menuItem, idiomaSeleccionado, setIdiomaSeleccionado }) => {
  // const defaultLanguage =
  //   localStorage.getItem("language") === null
  //     ? "es"
  //     : localStorage.getItem("language");

  const [nosotros, setNosotros] = useState(false);
  const [procedimientos, setProcedimientos] = useState(false);
  const [documentacion, setDocumentacion] = useState(false);
  const [tarifas, setTarifas] = useState(false);
  const [cambiarIdioma, setCambiarIdioma] = useState(false);
  // const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(
  //   defaultLanguage === "es" ? es : en
  // );

  const handleClickProcedimientos = (event) => {
    setProcedimientos(event.currentTarget);
  };

  const handleCloseProcedimientos = () => {
    setProcedimientos(false);
  };

  const handleClickDocumentos = (event) => {
    setDocumentacion(event.currentTarget);
  };

  const handleCloseDocumentos = () => {
    setDocumentacion(false);
  };

  const handleClickTarifas = (event) => {
    setTarifas(event.currentTarget);
  };

  const handleCloseTarifas = () => {
    setTarifas(false);
  };

  const handleClickNosotros = (event) => {
    setNosotros(event.currentTarget);
  };

  const handleCloseNosotros = () => {
    setNosotros(false);
  };

  const handleClickCambiarIdioma = (event) => {
    setCambiarIdioma(event.currentTarget);
  };

  const handleCloseCambiarIdioma = () => {
    setCambiarIdioma(false);
  };

  const handleChangeLanguage = async () => {
    switch (idiomaSeleccionado) {
      case es:
        setIdiomaSeleccionado(en);
        setCambiarIdioma(false);
        await localStorage.setItem("language", "en");
        break;

      case en:
        setIdiomaSeleccionado(es);
        setCambiarIdioma(false);
        await localStorage.setItem("language", "es");
        break;

      default:
        setIdiomaSeleccionado(es);
        setCambiarIdioma(false);
        await localStorage.setItem("language", "es");
        break;
    }
  };

  const styles = {
    tabs: {
      textTransform: "capitalize",
      color: "#30406B",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "1.1rem",
      width: "200px",

      "@media(max-width: 1536px)": {
        width: "170px",
      },

      "@media(max-width: 1300px)": {
        fontSize: "1rem",
        width: "150px",
      },
    },

    menuItem: {
      color: "#172833",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      width: "200px",
      padding: "1rem",

      "@media(max-width: 1536px)": {
        width: "170px",
      },

      "@media(max-width: 1300px)": {
        width: "150px",
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "1rem",
        fontSize: "0.85rem",
      },
    },
  };

  const tabs = [
    {
      label: idiomaSeleccionado.menu[0],
      handleClick: handleClickNosotros,
    },
    {
      label: idiomaSeleccionado.menu[1],
      handleClick: handleClickProcedimientos,
    },
    {
      label: idiomaSeleccionado.menu[2],
      handleClick: handleClickDocumentos,
    },
    {
      label: idiomaSeleccionado.menu[3],
      handleClick: handleClickTarifas,
    },
  ];

  const Logo = () => (
    <Icon>
      <Box
        component="img"
        src={idiomaSeleccionado === es ? Icon_Espanol : Icon_English}
        height={12}
        width={12}
        sx={{ verticalAlign: "middle" }}
        alt={idiomaSeleccionado === es ? "Icono Bandera De Chile" : "Icono Bandera De Estados Unidos"} 
      />
    </Icon>
  );

  return (
    <AppBar sx={{ background: "#FFFFFF" }} position="static">
      <Toolbar>
        <Box component="div" pl={2} pr={2}>
          <Link href="/home" underline="none" color="#172833">
            <Box
              component="img"
              src={Logo_NavbarMenu_CNA}
              alt="Logo Del CNA (Centro Nacional De Arbitrajes)"
              width="45px"
              height="30px"
            />
          </Link>
        </Box>
        <Tabs
          textColor="inherit"
          // onChange={() => handleTabClick}
          sx={{ marginLeft: "auto" }}
          value={value}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.label}
              sx={styles.tabs}
              onClick={tab.handleClick}
              value={index + 1}
              key={tab.label}
            />
          ))}
          <Tab
            label="E-xpediente Virtual"
            sx={{ ...styles.tabs, textTransform: "none" }}
            value={5}
            href="/e-xpediente"
          />
          <Tab
            label={idiomaSeleccionado.menu[4]}
            sx={styles.tabs}
            value={6}
            href="/contacto"
          />
          <Tab
            icon={<Logo />}
            label={idiomaSeleccionado === es ? "Español" : "English"}
            sx={{
              ...styles.tabs,
              display: "inline-block",
              paddingTop: "1.1rem",
            }}
            onClick={handleClickCambiarIdioma}
            value={7}
          />
        </Tabs>

        <Popover
          id={nosotros ? "simple-popover" : undefined}
          open={nosotros}
          anchorEl={nosotros}
          onClose={handleCloseNosotros}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Link href="/nosotros/cna" underline="none" color="#172833">
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 1 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[0]}
            </MenuItem>
          </Link>
          <Link href="/nosotros/equipo" underline="none" color="#172833">
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 2 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[1]}
            </MenuItem>
          </Link>
          <Link
            href="/nosotros/arbitraje_pro_bonos"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 3 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[2]}
            </MenuItem>
          </Link>
        </Popover>

        <Popover
          id={procedimientos ? "simple-popover" : undefined}
          open={procedimientos}
          anchorEl={procedimientos}
          onClose={handleCloseProcedimientos}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Link
            href="/procedimientos/servicios"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 4 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[3]}
            </MenuItem>
          </Link>

          <Link
            href="/procedimientos/como_trabajamos"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 5 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[4]}
            </MenuItem>
          </Link>
        </Popover>

        <Popover
          id={documentacion ? "simple-popover" : undefined}
          open={documentacion}
          anchorEl={documentacion}
          onClose={handleCloseDocumentos}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Link
            href="/documentacion/clausulas"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 6 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[5]}
            </MenuItem>
          </Link>
          <Link
            href="/documentacion/reglamentos"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 7 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[6]}
            </MenuItem>
          </Link>
          <Link
            href="/documentacion/otros_documentos"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 8 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[7]}
            </MenuItem>
          </Link>
        </Popover>

        <Popover
          id={tarifas ? "simple-popover" : undefined}
          open={tarifas}
          anchorEl={tarifas}
          onClose={handleCloseTarifas}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Link href="/tarifas/tabla_tarifas" underline="none" color="#172833">
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 9 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[8]}
            </MenuItem>
          </Link>
          <Link
            href="/tarifas/simulador_tarifas"
            underline="none"
            color="#172833"
          >
            <MenuItem
              sx={styles.menuItem}
              {...(menuItem === 10 && {
                selected: true,
              })}
            >
              {idiomaSeleccionado.sub_menu[9]}
            </MenuItem>
          </Link>
        </Popover>

        <Popover
          id={cambiarIdioma ? "simple-popover" : undefined}
          open={cambiarIdioma}
          anchorEl={cambiarIdioma}
          onClose={handleCloseCambiarIdioma}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{
              ...styles.menuItem,
            }}
            onClick={handleChangeLanguage}
          >
            <Box component="div" sx={{ display: "flex" }}>
              <Box
                component="img"
                src={idiomaSeleccionado === es ? Icon_English : Icon_Espanol}
                alt={idiomaSeleccionado === es ? "Icono Bandera De Estados Unidos" : "Icono Bandera De Chile"}
                height={12}
                width={12}
                mt={1}
                mr={2}
              />
              <Typography
                color="#172833"
                fontFamily="TitilliumWeb-Regular"
                fontSize="1rem"
              >
                {idiomaSeleccionado === es ? "English" : "Español"}
              </Typography>
            </Box>
          </MenuItem>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarDesktop;
