import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer";
import Home from "./views/HomeView/index";
import AboutOfCNA from "./views/AboutOfCNAView/index";
import Regulation from "./views/RegulationsView/index";
import OthersDocuments from "./views/OthersDocumentsView/index";
import RatesTables from "./views/RatesTableView";
import Team from "./views/TeamView/index";
import ProBondArbitration from "./views/ProBondArbitrationView/index";
import RateSimulator from "./views/RateSimulatorView/index";
import Expediente from "./views/E-xpedienteView";
import Services from "./views/ServicesView";
import Clauses from "./views/ClausesView";
import HowWeWork from "./views/HowWeWorkView";
import Contact from "./views/ContactView";
import FrequentQuestions from "./views/FrequentQuestionsView";
import ArbitrationForm from "./views/ArbitrationFormView";
import TransbankSuccessful from "./views/TransbankSuccessfulView";
import TransbankFailed from "./views/TransbankFailedView";

import es from "./locales/es.json";
import en from "./locales/en.json";

const App = () => {
  const defaultLanguage =
    localStorage.getItem("language") === null
      ? "es"
      : localStorage.getItem("language");
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(
    defaultLanguage === "es" ? es : en
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/transbank_success">
          <TransbankSuccessful
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/transbank_failed">
          <TransbankFailed
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/formulario_arbitraje">
          <ArbitrationForm
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/preguntas_frecuentes">
          <FrequentQuestions
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/contacto">
          <Contact
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/e-xpediente">
          <Expediente
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/tarifas/simulador_tarifas">
          <RateSimulator
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/tarifas/tabla_tarifas">
          <RatesTables
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/documentacion/otros_documentos">
          <OthersDocuments
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/documentacion/reglamentos">
          <Regulation
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/documentacion/clausulas">
          <Clauses
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/procedimientos/como_trabajamos">
          <HowWeWork
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/procedimientos/servicios">
          <Services
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/nosotros/arbitraje_pro_bonos">
          <ProBondArbitration
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/nosotros/equipo">
          <Team
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/nosotros/cna">
          <AboutOfCNA
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/home">
          <Home
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
        <Route path="/">
          <Home
            idiomaSeleccionado={idiomaSeleccionado}
            setIdiomaSeleccionado={setIdiomaSeleccionado}
          />
        </Route>
      </Switch>
      <Footer
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
    </BrowserRouter>
  );
};

export default App;
