import React, { useState } from "react";
import { Grid } from "@mui/material";
import FirstFormContent from "../../components/ArbitrationForm/FirstFormContent";
import SecondFormContent from "../../components/ArbitrationForm/SecondFormContent";
import ThirdFormContent from "../../components/ArbitrationForm/ThirdFormContent";
import FourthFormContent from "../../components/ArbitrationForm/FourthFormContent";

const defaultValuesFirstStage = {
  SOLICITANTES: [
    {
      INDEX: 0,
      NOMBRE_COMPLETO: "",
      RUT: "",
      CORREO: "",
      CONFIRMAR_CORREO: "",
      PAIS: "CL",
      REGION: "",
      COMUNAS: "",
      COMUNA_SELECCIONADA: "",
      DIRECCION: "",
      TIPO_DOMICILIO: "",
      CONTACTO: "",
    },
  ],
  PROCEDIMIENTO: "Arbitraje regular sugerido por el CNA",
  TIPO_ARBITRAJE: "Nacional",
  CIRCUNSTANCIA_INTERNACIONAL: "",
  INSTANCIA: "Sin doble instancia"
};

const defaultValuesSecondStage = {
  ABOGADOS: [
    {
      INDEX: 0,
      NOMBRE_COMPLETO: "",
      RUT: "",
      CORREO: "",
      CONFIRMAR_CORREO: "",
      PAIS: "CL",
      REGION: "",
      COMUNAS: "",
      COMUNA_SELECCIONADA: "",
      DIRECCION: "",
      TIPO_DOMICILIO: "",
      CONTACTO: "",
    },
  ],
  TIENE_ABOGADO: "si"
};

const defaultValuesThirdStage = {
  DEMANDADOS: [
    {
      INDEX: 0,
      NOMBRE_COMPLETO: "",
      RUT: "",
      CORREO: "",
      CONFIRMAR_CORREO: "",
      PAIS: "CL",
      REGION: "",
      COMUNAS: "",
      COMUNA_SELECCIONADA: "",
      DIRECCION: "",
      TIPO_DOMICILIO: "",
      CONTACTO: "",
    },
  ],
};

const defaultValuesFourthStage = {

  MATERIA: "",
  OTROS_ANTECEDENTES: "",
  CUANTIA: "1.000",
  TIPO_MONEDA: "UF",
  CONTRATO_CORRETAJE: false,
  CORREO_COMPROBANTE: "",
  ARCHIVOS: [],

};

const Content = ({ stage, handleChangeStage, idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const [formValuesFirstStage, setFormValuesFirstStage] = useState(
    defaultValuesFirstStage
  );
  const [errorsFirstStage, setErrorsFirstStage] = useState({});

  const [formValuesSecondStage, setFormValuesSecondStage] = useState(
    defaultValuesSecondStage
  );
  const [errorsSecondStage, setErrorsSecondStage] = useState({});

  const [formValuesThirdStage, setFormValuesThirdStage] = useState(
    defaultValuesThirdStage
  );
  const [errorsThirdStage, setErrorsThirdStage] = useState({});

  const [formValuesFourthStage, setFormValuesFourthStage] = useState(
    defaultValuesFourthStage
  );
  const [errorsFourthStage, setErrorsFourthStage] = useState({});

  return (
    <Grid item xs={12} md={8} sx={{ backgroundColor: "#F5F5F5" }}>
      {stage === 1 ? (
        <FirstFormContent
          handleChangeStage={handleChangeStage}
          formValuesFirstStage={formValuesFirstStage}
          setFormValuesFirstStage={setFormValuesFirstStage}
          errorsFirstStage={errorsFirstStage}
          setErrorsFirstStage={setErrorsFirstStage}
          idiomaSeleccionado={idiomaSeleccionado}
          setIdiomaSeleccionado={setIdiomaSeleccionado}
        />
      ) : stage === 2 ? (
        <SecondFormContent
          handleChangeStage={handleChangeStage}
          formValuesSecondStage={formValuesSecondStage}
          setFormValuesSecondStage={setFormValuesSecondStage}
          errorsSecondStage={errorsSecondStage}
          setErrorsSecondStage={setErrorsSecondStage}
          idiomaSeleccionado={idiomaSeleccionado}
          setIdiomaSeleccionado={setIdiomaSeleccionado}
        />
      ) : stage === 3 ? (
        <ThirdFormContent
          handleChangeStage={handleChangeStage}
          formValuesThirdStage={formValuesThirdStage}
          setFormValuesThirdStage={setFormValuesThirdStage}
          errorsThirdStage={errorsThirdStage}
          setErrorsThirdStage={setErrorsThirdStage}
          idiomaSeleccionado={idiomaSeleccionado}
          setIdiomaSeleccionado={setIdiomaSeleccionado}
        />
      ) : (
        <FourthFormContent
          handleChangeStage={handleChangeStage}
          formValuesFourthStage={formValuesFourthStage}
          setFormValuesFourthStage={setFormValuesFourthStage}
          errorsFourthStage={errorsFourthStage}
          setErrorsFourthStage={setErrorsFourthStage}
          infoFirstStage={formValuesFirstStage}
          infoSecondStage={formValuesSecondStage}
          infoThirdStage={formValuesThirdStage}
          idiomaSeleccionado={idiomaSeleccionado}
          setIdiomaSeleccionado={setIdiomaSeleccionado}
        />
      )}
    </Grid>
  );
};

export default Content;
