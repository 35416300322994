import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from "@mui/material";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";

import es from "../../locales/es.json";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "../../assets/icons/ArbitrationForm/download-icon.svg";

// import Excel from "../../assets/icons/ArbitrationForm/excel.svg";
import Xls from "../../assets/icons/ArbitrationForm/xls.svg";
import Doc from "../../assets/icons/ArbitrationForm/doc.svg";
import PDF from "../../assets/icons/ArbitrationForm/pdf.svg";
import Image from "../../assets/icons/ArbitrationForm/image.svg";

import Eliminar from "../../assets/icons/ArbitrationForm/eliminar.svg";

import ReCAPTCHA from "react-google-recaptcha";
import CryptoJS from "crypto-js";

import {
  ingresarDatosFormulario,
  guardarArchivosContrato,
  realizarPago,
} from "../../api/form";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#172833",
    borderColor: "#0F7099",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",

    "& fieldset": {
      borderColor: "#0F7099",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },
}));

const FourthFormContent = ({
  handleChangeStage,
  formValuesFourthStage,
  setFormValuesFourthStage,
  errorsFourthStage,
  setErrorsFourthStage,
  infoFirstStage,
  infoSecondStage,
  infoThirdStage,
  idiomaSeleccionado,
  setIdiomaSeleccionado,
}) => {
  const [captcha, setCaptcha] = useState(null);
  const [open, setOpen] = useState(false);
  const isMobile = window.matchMedia("(max-width: 350px)").matches;

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "CONTRATO_CORRETAJE") {
      setFormValuesFourthStage({
        ...formValuesFourthStage,
        [name]: checked,
      });
      return;
    }

    setFormValuesFourthStage({
      ...formValuesFourthStage,
      [name]: value,
    });
  };

  const handleGetFile = (e) => {
    const { files } = e.target;

    if (files.length > 0) {
      if (formValuesFourthStage.ARCHIVOS.length + files.length > 10) {
        Swal.fire({
          title: "¡Ups!",
          icon: "warning",
          text: idiomaSeleccionado.validations_fourth_stage_form_cna[5],
        });
        return;
      }

      const uploadedFile = files[0];
      let nameFile = uploadedFile.name;

      if (uploadedFile.size > 10000000) {
        Swal.fire({
          title: "¡Ups!",
          icon: "warning",
          text: idiomaSeleccionado.validations_fourth_stage_form_cna[6],
        });
        return;
      }

      const extensionesValidas = [
        "jpg",
        "png",
        "pdf",
        "jpeg",
        "xlsx",
        "xls",
        "docx",
      ];
      const extensionArchivo = nameFile.split(".").pop();
      if (!extensionesValidas.includes(extensionArchivo)) {
        Swal.fire({
          title: "¡Ups!",
          icon: "warning",
          text:idiomaSeleccionado.validations_fourth_stage_form_cna[7],
        });

        return;
      }

      setFormValuesFourthStage({
        ...formValuesFourthStage,
        ARCHIVOS: [
          ...formValuesFourthStage.ARCHIVOS,
          {
            archivo: uploadedFile,
            nombreArchivo: nameFile,
            extension: extensionArchivo,
            url: URL.createObjectURL(uploadedFile),
          },
        ],
      });
    }
  };

  const handleRemoveFile = (nombreArchivo) => {
    const archivosActualizados = formValuesFourthStage.ARCHIVOS.filter(
      (archivo) => {
        return archivo.nombreArchivo.toString() !== nombreArchivo.toString();
      }
    );

    console.log("archivosActualizados");
    console.log(archivosActualizados);

    setFormValuesFourthStage({
      ...formValuesFourthStage,
      ARCHIVOS: archivosActualizados,
    });
  };

  const onChangeCaptcha = (value) => {
    if (value && value !== null) {
      setCaptcha(value);
    } else {
      setCaptcha(null);
    }
  };

  const handleValidate = () => {
    let err = {};

    err.MATERIA =
      formValuesFourthStage.MATERIA.length > 0
        ? ""
        : idiomaSeleccionado.validations_fourth_stage_form_cna[0];

    err.ARCHIVOS =
      formValuesFourthStage.ARCHIVOS.length > 0
        ? ""
        : idiomaSeleccionado.validations_fourth_stage_form_cna[1];

    err.CORREO_COMPROBANTE =
      formValuesFourthStage.CORREO_COMPROBANTE.length > 0
        ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
            formValuesFourthStage.CORREO_COMPROBANTE
          )
          ? ""
          : idiomaSeleccionado.validations_fourth_stage_form_cna[2]
        : idiomaSeleccionado.validations_fourth_stage_form_cna[3];

    err.CAPTCHA = captcha === null ? idiomaSeleccionado.validations_fourth_stage_form_cna[4] : "";

    setErrorsFourthStage({
      ...err,
    });

    return Object.values(err).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidate()) {
      setOpen(true);
      const t = new Date();
      const day = t.getDate() > 9 ? t.getDate() : "0" + t.getDate();
      const month =
        t.getMonth() + 1 > 9 ? t.getMonth() + 1 : "0" + (t.getMonth() + 1);
      const hour = t.getHours() > 9 ? t.getHours() : "0" + t.getHours();
      const minutes =
        t.getMinutes() > 9 ? t.getMinutes() : "0" + t.getMinutes();
      const seconds =
        t.getSeconds() > 9 ? t.getSeconds() : "0" + t.getSeconds();
      const today =
        day +
        "-" +
        month +
        "-" +
        t.getFullYear() +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds;

      const PERSONAS = [];
      const ABOGADOS = [];
      const DEMANDADOS = [];
      const nombresArchivosContrato = [];
      let ARBITRAJE = {};
      let MATERIA_DEMANDA = {};

      infoFirstStage.SOLICITANTES.forEach((data) => {
        const solicitante = {
          NOMBRE_COMPLETO: data.NOMBRE_COMPLETO,
          RUT: data.RUT,
          CORREO: data.CORREO,
          PAIS: data.PAIS,
          REGION: data.REGION,
          COMUNA: data.COMUNA_SELECCIONADA,
          DIRECCION: data.DIRECCION,
          TIPO_DOMICILIO: data.TIPO_DOMICILIO,
          CONTACTO: data.CONTACTO,
        };

        PERSONAS.push(solicitante);
      });

      if (infoSecondStage.TIENE_ABOGADO === "si") {
        infoSecondStage.ABOGADOS.forEach((data) => {
          const abogado = {
            NOMBRE_COMPLETO: data.NOMBRE_COMPLETO,
            RUT: data.RUT,
            CORREO: data.CORREO,
            PAIS: data.PAIS,
            REGION: data.REGION,
            COMUNA: data.COMUNA_SELECCIONADA,
            DIRECCION: data.DIRECCION,
            TIPO_DOMICILIO: data.TIPO_DOMICILIO,
            CONTACTO: data.CONTACTO,
          };

          ABOGADOS.push(abogado);
        });
      }

      infoThirdStage.DEMANDADOS.forEach((data) => {
        const demandado = {
          NOMBRE_COMPLETO: data.NOMBRE_COMPLETO,
          RUT: data.RUT,
          CORREO: data.CORREO,
          PAIS: data.PAIS,
          REGION: data.REGION,
          COMUNA: data.COMUNA_SELECCIONADA,
          DIRECCION: data.DIRECCION,
          TIPO_DOMICILIO: data.TIPO_DOMICILIO,
          CONTACTO: data.CONTACTO,
        };

        DEMANDADOS.push(demandado);
      });

      let formDataArchivos = new FormData();
      formValuesFourthStage.ARCHIVOS.forEach((data) => {
        nombresArchivosContrato.push(data.nombreArchivo);
        formDataArchivos.append("archivoContrato", data.archivo);
      });

      ARBITRAJE.CORREO_COMPROBANTE = formValuesFourthStage.CORREO_COMPROBANTE;
      ARBITRAJE.PROCEDIMIENTO = infoFirstStage.PROCEDIMIENTO;
      ARBITRAJE.TIPO_ARBITRAJE = infoFirstStage.TIPO_ARBITRAJE;
      ARBITRAJE.CIRCUNSTANCIA_INTERNACIONAL =
        infoFirstStage.CIRCUNSTANCIA_INTERNACIONAL;
      ARBITRAJE.TIENE_ABOGADO =
        infoSecondStage.TIENE_ABOGADO === "si" ? true : false;
      ARBITRAJE.INSTANCIA = infoFirstStage.INSTANCIA;
      ARBITRAJE.CAUSA_CORREDORA = formValuesFourthStage.CONTRATO_CORRETAJE;

      MATERIA_DEMANDA.CUANTIA = formValuesFourthStage.CUANTIA;
      MATERIA_DEMANDA.TIPO_MONEDA = formValuesFourthStage.TIPO_MONEDA;
      MATERIA_DEMANDA.MATERIA = formValuesFourthStage.MATERIA;
      MATERIA_DEMANDA.OTROS_ANTECEDENTES =
        formValuesFourthStage.OTROS_ANTECEDENTES;
      MATERIA_DEMANDA.ARCHIVOS = formDataArchivos;

      const objRequest = {
        PERSONAS,
        ABOGADOS,
        DEMANDADOS,
        ARBITRAJE,
        MATERIA_DEMANDA,
        nombresArchivosContrato,
        nombresArchivosOtros: [],
        fechaSolicitud: today,
      };

      const encryptedRequest = CryptoJS.AES.encrypt(
        JSON.stringify(objRequest),
        "my-secret-key@123"
      ).toString();

      const objEncrypted = { data: encryptedRequest };
      const response = await ingresarDatosFormulario(objEncrypted, idiomaSeleccionado);

      if (response) {
        const { idArbitraje, idMateria } = response.data;

        formDataArchivos.delete("idMateria");
        formDataArchivos.append("idMateria", idMateria);

        // formDataArchivos.delete("idArbitraje");
        // formDataArchivos.append("idArbitraje", idArbitraje);

        // formDataArchivos.delete("tipoArchivos");
        // formDataArchivos.append("tipoArchivos", "CONTRATO");

        const objResponseArchivosContratos = await guardarArchivosContrato(
          formDataArchivos,
          idiomaSeleccionado
        );

        if (objResponseArchivosContratos) {
          const responseRealizarPago = await realizarPago(
            idArbitraje,
            idMateria,
            idiomaSeleccionado
          );

          if (responseRealizarPago) {
            setOpen(false);
            window.location.replace(responseRealizarPago.data);
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      } else {
        setOpen(false);
      }
    }
  };

  const styles = {
    inputSize: {
      width: "550px",
      "@media(max-width: 1810px)": {
        width: "350px",
      },

      "@media(max-width: 400px)": {
        width: "270px",
      },

      "@media(max-width: 310px)": {
        width: "220px",
      },
    },

    contenedorBotones: {
      display: "flex",
      "@media(max-width: 480px)": {
        display: "block",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      textTransform: "none",
      height: "50px",
      width: "200px",
    },
  };
  return (
    <Grid container>
      <Loading open={open} />
      <Grid item xs={12}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
          >
            <Box component="div" display="block">
              <Typography
                color="#4E87AE"
                pl={2}
                sx={{
                  fontFamily: "TitilliumWeb-SemiBold",
                  fontSize: "1.3rem",
                }}
              >
                {idiomaSeleccionado.fourth_stage_form_cna[0]}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="div" pt={2} pl={2} pr={2} pb={2}>
              <Grid container>
                <Grid item xs={12} mt={5}>
                  <StyledTextField
                    id="outlined-basic"
                    multiline
                    label={idiomaSeleccionado.fourth_stage_form_cna[1]}
                    InputLabelProps={{
                      style: {
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      },
                    }}
                    variant="outlined"
                    placeholder={idiomaSeleccionado.fourth_stage_form_cna[2]}
                    name="MATERIA"
                    value={formValuesFourthStage.MATERIA}
                    rows={5}
                    sx={{ width: "100%" }}
                    inputProps={{
                      style: {
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      },
                    }}
                    onChange={handleInputChange}
                    {...(errorsFourthStage.MATERIA && {
                      error: true,
                      helperText: errorsFourthStage.MATERIA,
                    })}
                  />
                </Grid>
                <Grid item xs={12} mt={5} mb={5}>
                  <StyledTextField
                    id="outlined-basic"
                    multiline
                    label={idiomaSeleccionado.fourth_stage_form_cna[3]}
                    InputLabelProps={{
                      style: {
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      },
                    }}
                    variant="outlined"
                    placeholder={idiomaSeleccionado.fourth_stage_form_cna[4]}
                    rows={5}
                    name="OTROS_ANTECEDENTES"
                    sx={{ width: "100%" }}
                    inputProps={{
                      style: {
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      },
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Typography
                color="#172833"
                fontFamily="TitilliumWeb-Regular"
                fontSize="1rem"
                mb={3}
              >
                {idiomaSeleccionado.fourth_stage_form_cna[5]}
              </Typography>
              <Grid container>
                <Grid item xs={12} mb={4}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      <Typography
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.fourth_stage_form_cna[6]}
                      </Typography>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formValuesFourthStage.CUANTIA}
                      label="Cuantía"
                      onChange={handleInputChange}
                      sx={styles.inputSize}
                      name="CUANTIA"
                    >
                      <MenuItem value="1.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          0,00 - 1000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="3.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          1000,01 - 3.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="8.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          3.000,01 - 8.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="25.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          8.000,01 - 25.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="65.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          25.000,01 - 65.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="150.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          65.000,01 - 150.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="350.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          150.000,01 - 350.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="1.850.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          350.000,01 - 1.850.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="5.000.000">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          1.850.000,01 - 5.000.000,00
                        </Typography>
                      </MenuItem>
                      <MenuItem value="SUPERIOR">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          5.000.000,01 - SUPERIOR
                        </Typography>
                      </MenuItem>
                      <MenuItem value="INDETERMINADO">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          {idiomaSeleccionado.fourth_stage_form_cna[7]}
                        </Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="CONTRATO_CORRETAJE"
                      value={formValuesFourthStage.CONTRATO_CORRETAJE}
                      checked={formValuesFourthStage.CONTRATO_CORRETAJE}
                      onChange={handleInputChange}
                    />
                  }
                  label={
                    <Typography
                      color="#172833"
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.fourth_stage_form_cna[8]}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
          >
            <Typography
              color="#4E87AE"
              pl={2}
              sx={{
                fontFamily: "TitilliumWeb-SemiBold",
                fontSize: "1.3rem",
              }}
            >
              {idiomaSeleccionado.fourth_stage_form_cna[9]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container pl={2} spacing={5} pt={2} pb={2}>
              <Grid item xs={12} lg={6}>
                <Box component="div" mb={4}>
                  <Typography
                    color="#172833"
                    fontFamily="TitilliumWeb-Regular"
                    fontSize="1rem"
                  >
                    {idiomaSeleccionado.fourth_stage_form_cna[10]}
                  </Typography>
                  <Typography
                    color="#172833"
                    fontFamily="TitilliumWeb-Regular"
                    fontSize="1rem"
                  >
                    {idiomaSeleccionado.fourth_stage_form_cna[11]}
                  </Typography>
                  <Typography
                    color="#172833"
                    fontFamily="TitilliumWeb-Regular"
                    fontSize="1rem"
                  >
                    {idiomaSeleccionado.fourth_stage_form_cna[12]}
                  </Typography>
                </Box>

                <Box component="div">
                  <Typography
                    color="#172833"
                    fontFamily="TitilliumWeb-Regular"
                    fontSize="1rem"
                  >
                    {idiomaSeleccionado.fourth_stage_form_cna[13]}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl sx={{ ...styles.inputSize }}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "105px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 3px 6px #5088AC65",
                      marginBottom: "1rem",
                    }}
                    display="flex"
                    textAlign="center"
                    justifyContent="center"
                  >
                    <input
                      type="file"
                      hidden
                      id="archivo-demanda"
                      accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/xlsx, application/pdf, image/png, image/jpeg, image/jpg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      name="ARCHIVOS"
                      onChange={(e) => handleGetFile(e)}
                      onClick={(e) => (e.target.value = "")}
                    />

                    <Box
                      component="img"
                      src={DownloadIcon}
                      alt="Subir archivo CNA (Centro Nacional De Arbitrajes)"
                      width="30px"
                      mr={2}
                    />

                    <Typography
                      color="#172833"
                      fontFamily="TitilliumWeb-Regular"
                      fontSize="1rem"
                      textAlign="center"
                      mt="auto"
                      mb="auto"
                      sx={{ textTransform: "none" }}
                    >
                      {idiomaSeleccionado.fourth_stage_form_cna[14]}
                    </Typography>
                  </Button>

                  {errorsFourthStage.ARCHIVOS && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errorsFourthStage.ARCHIVOS}
                    </FormHelperText>
                  )}
                </FormControl>

                {formValuesFourthStage.ARCHIVOS.length > 0 &&
                  formValuesFourthStage.ARCHIVOS.map((archivo, index) => (
                    <Box
                      key={index}
                      component="div"
                      sx={{
                        ...styles.inputSize,
                        height: "35px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 3px 6px #5088AC65",
                      }}
                      id="lista-archivo-contrato"
                      display="flex"
                      pt={2}
                      pb={2}
                      mb={3}
                    >
                      <Box
                        component="img"
                        src={
                          archivo.extension === "pdf"
                            ? PDF
                            : archivo.extension === "xlsx" ||
                              archivo.extension === "xls"
                            ? Xls
                            : archivo.extension === "docx" ||
                              archivo.extension === "doc"
                            ? Doc
                            : Image
                        }
                        alt={
                          archivo.extension === "pdf"
                            ? "Icono Extensión PDF"
                            : archivo.extension === "xlsx" ||
                              archivo.extension === "xls"
                            ? "Icono Extensión XLS"
                            : archivo.extension === "docx" ||
                              archivo.extension === "doc"
                            ? "Icono Extensión DOC"
                            : "Icono Extensión de Imagen"
                        }
                        width="40px"
                        height="40px"
                        mr={3}
                        pl={1}
                      />
                      <Typography
                        color="#172833"
                        fontFamily="TitilliumWeb-Regular"
                        fontSize="1rem"
                      >
                        {archivo.nombreArchivo}
                      </Typography>

                      <Box
                        component="img"
                        src={Eliminar}
                        alt="Icono eliminar archivo adjunto"
                        sx={{
                          display: "block",
                          marginLeft: "auto",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        pr={2}
                        onClick={() => handleRemoveFile(archivo.nombreArchivo)}
                      />
                    </Box>
                  ))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Box component="div" pl={3} pt={5} pb={2}>
        <Box component="div" mb={5}>
          <StyledTextField
            id="outlined-basic"
            label={idiomaSeleccionado.fourth_stage_form_cna[15]}
            InputLabelProps={{
              style: {
                fontFamily: "TitilliumWeb-Regular",
                fontSize: "1rem",
              },
            }}
            variant="outlined"
            placeholder={idiomaSeleccionado.fourth_stage_form_cna[16]}
            name="CORREO_COMPROBANTE"
            value={formValuesFourthStage.CORREO_COMPROBANTE}
            sx={styles.inputSize}
            inputProps={{
              style: {
                fontFamily: "TitilliumWeb-Regular",
                fontSize: "1rem",
              },
            }}
            onChange={handleInputChange}
            {...(errorsFourthStage.CORREO_COMPROBANTE && {
              error: true,
              helperText: errorsFourthStage.CORREO_COMPROBANTE,
            })}
          />
          <Typography
            pt={5}
            color="#1C3254"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1.5rem"
            fontWeight={800}
          >
           {idiomaSeleccionado.fourth_stage_form_cna[19]}
          </Typography>
        </Box>

        <Box component="div" mb={4} sx={styles.contenedorCaptcha}>
          {isMobile ? (
            <ReCAPTCHA
              key="compact-recaptcha"
              size="compact"
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              style={styles.captcha}
              onChange={onChangeCaptcha}
              hl={idiomaSeleccionado === es ? "es" : "en"}
            />
          ) : (
            <ReCAPTCHA
              key="normal-recaptcha"
              size="normal"
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              style={styles.captcha}
              onChange={onChangeCaptcha}
              hl={idiomaSeleccionado === es ? "es" : "en"}
            />
          )}

          {errorsFourthStage.CAPTCHA && (
            <Typography
              color="#DE3232"
              pl={2}
              mb={2}
              sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "0.85rem" }}
            >
              {errorsFourthStage.CAPTCHA}
            </Typography>
          )}
        </Box>

        <Box component="div" sx={styles.contenedorBotones}>
          <Box component="div" mb={2}>
            <Button
              variant="contained"
              sx={{
                ...styles.button,
                backgroundColor: "#7C9D1C",
                marginRight: "1rem",
              }}
              onClick={() => handleChangeStage(3)}
            >
              {idiomaSeleccionado.fourth_stage_form_cna[17]}
            </Button>
          </Box>
          <Box component="div">
            <Button
              variant="contained"
              sx={{ ...styles.button, backgroundColor: "#4E87AE" }}
              onClick={handleSubmit}
            >
              {idiomaSeleccionado.fourth_stage_form_cna[18]}
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default FourthFormContent;
