import React from "react";
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Imagen_Clausulas from "../../assets/imgs/Clauses/imagen_clausulas.webp";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const Clauses = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "10rem",
    },

    contenedorInfoClausula: {
      paddingLeft: "10rem",
      paddingRight: "10rem",

      "@media(max-width: 1746px)": {
        paddingLeft: "11rem",
        paddingRight: "7rem",
      },

      "@media(max-width: 1536px)": {
        paddingLeft: "4rem",
        paddingRight: "8rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "0",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorImagen: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    imagen: {
      width: "420px",

      "@media(max-width: 900px)": {
        width: "300px",
      },

      "@media(max-width: 600px)": {
        width: "200px",
      },
    },

    contenedorTitulo: {
      display: "block",
      "@media(max-width: 1200px)": {
        display: "flex",
        justifyContent: "center",
      },
    },

    contenedorSubtitulo: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    contenedorBoton: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4rem",
      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 299px)": {
        fontSize: "1.5rem",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "300px",

      "@media(max-width: 600px)": {
        width: "250px",
      },
    },

    contenedorClausulas: {
      paddingRight: "20rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      // "@media(max-width: 1200px)": {
      //   paddingLeft: "5rem",
      //   paddingRight: "5rem",
      // },

      "@media(max-width: 900px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={3}
        menuItem={6}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} lg={5} pb={5}>
          <Box component="div" sx={styles.contenedorInfoClausula}>
            <Box component="div" sx={styles.contenedorImagen}>
              <Box
                component="img"
                src={Imagen_Clausulas}
                alt="Imagen de cláusulas del CNA (Centro Nacional De Arbitrajes)"
                sx={styles.imagen}
                mb={3}
              />
            </Box>

            <Box component="div" sx={styles.contenedorTitulo}>
              <Typography
                variant="h1"
                mr={1}
                color="#1C3254"
                sx={styles.titulo}
              >
                {idiomaSeleccionado.clauses_cna[0]}
              </Typography>
              <Typography
                variant="h1"
                color="#1C3254"
                mb={4}
                sx={styles.titulo}
              >
                {idiomaSeleccionado.clauses_cna[1]}
              </Typography>
            </Box>
            <Box component="div" mb={3} sx={styles.contenedorSubtitulo}>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.clauses_cna[2]}
              </Typography>
            </Box>
            {/* <Box component="div" mb={3} sx={styles.contenedorBoton}>
            <Button
              variant="contained"
              disableElevation
              sx={{ ...styles.button, backgroundColor: "#4E87AE" }}
            >
              Descargar reglamento vigente
            </Button>
          </Box>
          <Box component="div" sx={styles.contenedorBoton}>
            <Button
              variant="contained"
              disableElevation
              sx={{ ...styles.button, backgroundColor: "#7C9D1C" }}
            >
              Descargar cláusulas en inglés
            </Button>
          </Box> */}
          </Box>
        </Grid>

        <Grid item xs={12} lg={7} sx={styles.contenedorClausulas}>
          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Box component="div" display="block">
                  <Typography
                    color="#1C3254"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[3]}
                  </Typography>
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[4]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box component="div" pt={2} pl={2} pr={2} pb={2}>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[5]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[6]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[7]}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Box component="div" display="block">
                  <Typography
                    color="#1C3254"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[8]}
                  </Typography>
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[9]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box component="div" pt={2} pl={2} pr={2} pb={2}>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[10]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[11]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[12]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[13]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[14]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[15]}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Box component="div" display="block">
                  <Typography
                    color="#1C3254"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[16]}
                  </Typography>
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[17]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box component="div" pt={2} pl={2} pr={2} pb={2}>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[18]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[19]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[20]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[21]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBoldItalic",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[22]}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Box component="div" display="block">
                  <Typography
                    color="#1C3254"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[23]}
                  </Typography>
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[24]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box component="div" pt={2} pl={2} pr={2} pb={2}>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[25]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[26]}
                  </Typography>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[27]}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {idiomaSeleccionado.clauses_cna[28]}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Clauses;
