import axiosClient from "../config/axios";
import Swal from "sweetalert2";

export const ingresarDatosFormulario = async (objEncrypted, idiomaSeleccionado) => {
  try {
    let response = await axiosClient.post(`/Formulario/Crear`, objEncrypted);
    if (response.status === 201) {
      return response.data;
    } else {
      Swal.fire({
        title: "¡Ups!",
        icon: "error",
        text: idiomaSeleccionado.validations_fourth_stage_form_cna[8],
      });
      return;
    }
  } catch (error) {
    Swal.fire({
      title: "¡Ups!",
      icon: "error",
      text: idiomaSeleccionado.validations_fourth_stage_form_cna[8],
    });
    return false;
  }
};

export const guardarArchivosContrato = async (formDataArchivos, idiomaSeleccionado) => {
  try {
    let response = await axiosClient.post(
      `/Archivos/Upload/NuevoFormulario`,
      formDataArchivos
    );
    if (response.status === 200) {
      return response.data;
    } else {
      Swal.fire({
        title: "¡Ups!",
        icon: "error",
        text: idiomaSeleccionado.validations_fourth_stage_form_cna[8],
      });
      return;
    }
  } catch (error) {
    Swal.fire({
      title: "¡Ups!",
      icon: "error",
      text: idiomaSeleccionado.validations_fourth_stage_form_cna[8],
    });
    return false;
  }
};

export const realizarPago = async (idArbitraje, idMateria, idiomaSeleccionado) => {
  try {
    let response = await axiosClient.post(`/Pago/inicio/new`, {
      idArbitraje,
      idMateria,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      Swal.fire({
        title: "¡Ups!",
        icon: "error",
        text: idiomaSeleccionado.validations_fourth_stage_form_cna[8],
      });
      return;
    }
  } catch (error) {
    Swal.fire({
      title: "¡Ups!",
      icon: "error",
      text: idiomaSeleccionado.validations_fourth_stage_form_cna[8],
    });
    return;
  }
};
