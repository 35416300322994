import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, TextField, Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { enviarContacto } from "../../api/contact";
import Swal from "sweetalert2";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import Loading from "../../components/Loading";
import NavbarMenu from "../../components/NavbarMenu";
import es from "../../locales/es.json";

const defaultValues = {
  NOMBRE: "",
  CORREO: "",
  TELEFONO: "",
  ASUNTO: "",
  MENSAJE: "",
  FECHA: new Date(),
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#172833",
    borderColor: "#0F7099",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",

    "& fieldset": {
      borderColor: "#0F7099",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },
}));

const Contact = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [captcha, setCaptcha] = useState(null);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const isMobile = window.matchMedia("(max-width: 350px)").matches;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const onChangeCaptcha = (value) => {
    if (value && value !== null) {
      setCaptcha(value);
    } else {
      setCaptcha(null);
    }
  };

  const validate = () => {
    let err = {};
    err.NOMBRE =
      formValues.NOMBRE.length > 0
        ? ""
        : idiomaSeleccionado.validations_contact_cna[0];
    err.CORREO =
      formValues.CORREO.length > 0
        ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
            formValues.CORREO
          )
          ? ""
          : idiomaSeleccionado.validations_contact_cna[2]
        : idiomaSeleccionado.validations_contact_cna[1];
    err.TELEFONO =
      formValues.TELEFONO.length > 0
        ? ""
        : idiomaSeleccionado.validations_contact_cna[3];
    err.ASUNTO =
      formValues.ASUNTO.length > 0
        ? ""
        : idiomaSeleccionado.validations_contact_cna[4];
    err.MENSAJE =
      formValues.MENSAJE.length > 0
        ? ""
        : idiomaSeleccionado.validations_contact_cna[5];

    err.CAPTCHA =
      captcha === null ? idiomaSeleccionado.validations_contact_cna[6] : "";
    setErrors({
      ...err,
    });

    return Object.values(err).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setOpen(true);
      let respuesta = await enviarContacto(formValues, idiomaSeleccionado);
      if (respuesta) {
        setOpen(false);

        Swal.fire({
          icon: respuesta.icon,
          title: respuesta.title,
          text: respuesta.message,
          confirmButtonColor: "#004D77 ",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((value) => {
          if (respuesta.status === 201 && value.isConfirmed) {
            setFormValues(defaultValues);
            window.location.href = "/home";
          }
        });
      }
    }
  };

  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "10rem",
    },

    contenedorContacto: {
      paddingLeft: "10rem",
      paddingRight: "10rem",

      "@media(max-width: 1746px)": {
        paddingLeft: "11rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 1536px)": {
        paddingTop: "0",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorTitulo: {
      display: "block",
      "@media(max-width: 1536px)": {
        display: "flex",
        justifyContent: "center",
      },
    },

    contenedorSubtitulo: {
      "@media(max-width: 1536px)": {
        textAlign: "center",
      },
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4rem",
      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 299px)": {
        fontSize: "1.5rem",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "200px",

      "@media(max-width: 600px)": {
        width: "250px",
      },
    },

    contenedorFormularioContacto: {
      paddingRight: "20rem",
      "@media(max-width: 1536px)": {
        paddingLeft: "5rem",
        paddingRight: "5rem",
        textAlign: "center",
      },

      "@media(max-width: 900px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    textFields: {
      width: "550px",

      "@media(max-width: 600px)": {
        width: "370px",
      },

      "@media(max-width: 420px)": {
        width: "255px",
      },

      "@media(max-width: 280px)": {
        width: "200px",
      },
    },

    contenedorCaptcha: {
      "@media(max-width: 1536px)": {
        textAlign: "center",
      },
    },

    captcha: {
      display: "inline-block",
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={6}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Loading open={open} />
        <Grid item xs={12} xl={5} pb={5}>
          <Box component="div" sx={styles.contenedorContacto} pt={20}>
            <Box component="div" sx={styles.contenedorTitulo} mb={2}>
              <Typography
                variant="h1"
                mr={1}
                color="#1C3254"
                sx={styles.titulo}
              >
                {idiomaSeleccionado.contact_cna[0]}
              </Typography>
            </Box>
            <Box component="div" mb={3} sx={styles.contenedorSubtitulo}>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.contact_cna[1]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.contact_cna[2]}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} xl={7} sx={styles.contenedorFormularioContacto}>
          <Box component="div" mb={4}>
            <StyledTextField
              id="outlined-basic"
              label={idiomaSeleccionado.contact_cna[3]}
              InputLabelProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              variant="outlined"
              placeholder={idiomaSeleccionado.contact_cna[4]}
              name="NOMBRE"
              value={formValues.NOMBRE}
              sx={styles.textFields}
              inputProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              onChange={handleInputChange}
              {...(errors.NOMBRE && { error: true, helperText: errors.NOMBRE })}
            />
          </Box>

          <Box component="div" mb={4}>
            <StyledTextField
              id="outlined-basic"
              label={idiomaSeleccionado.contact_cna[5]}
              InputLabelProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              variant="outlined"
              placeholder={idiomaSeleccionado.contact_cna[6]}
              name="CORREO"
              value={formValues.CORREO}
              type="email"
              sx={styles.textFields}
              inputProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              onChange={handleInputChange}
              {...(errors.CORREO && { error: true, helperText: errors.CORREO })}
            />
          </Box>

          <Box component="div" mb={4}>
            <StyledTextField
              id="outlined-basic"
              label={idiomaSeleccionado.contact_cna[7]}
              InputLabelProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              variant="outlined"
              placeholder="+000 0000 0000"
              name="TELEFONO"
              value={formValues.TELEFONO}
              sx={styles.textFields}
              inputProps={{
                maxLength: 12,
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              onChange={handleInputChange}
              {...(errors.TELEFONO && {
                error: true,
                helperText: errors.TELEFONO,
              })}
            />
          </Box>

          <Box component="div" mb={4}>
            <StyledTextField
              id="outlined-basic"
              label={idiomaSeleccionado.contact_cna[8]}
              InputLabelProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              variant="outlined"
              placeholder={idiomaSeleccionado.contact_cna[4]}
              name="ASUNTO"
              value={formValues.ASUNTO}
              sx={styles.textFields}
              inputProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              onChange={handleInputChange}
              {...(errors.ASUNTO && { error: true, helperText: errors.ASUNTO })}
            />
          </Box>

          <Box component="div" mb={4}>
            <StyledTextField
              id="outlined-basic"
              multiline
              label={idiomaSeleccionado.contact_cna[9]}
              InputLabelProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              variant="outlined"
              placeholder={idiomaSeleccionado.contact_cna[4]}
              rows={7}
              // maxRows={30}
              name="MENSAJE"
              value={formValues.MENSAJE}
              sx={styles.textFields}
              inputProps={{
                style: {
                  fontFamily: "TitilliumWeb-Regular",
                  fontSize: "1rem",
                },
              }}
              onChange={handleInputChange}
              {...(errors.MENSAJE && {
                error: true,
                helperText: errors.MENSAJE,
              })}
            />
          </Box>

          <Box component="div" mb={4} sx={styles.contenedorCaptcha}>
            {isMobile ? (
              <ReCAPTCHA
                key="compact-recaptcha"
                size="compact"
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                style={styles.captcha}
                onChange={onChangeCaptcha}
                hl={idiomaSeleccionado === es ? "es" : "en"}
              />
            ) : (
              <ReCAPTCHA
                key="normal-recaptcha"
                size="normal"
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                style={styles.captcha}
                onChange={onChangeCaptcha}
                hl={idiomaSeleccionado === es ? "es" : "en"}
              />
            )}

            {errors.CAPTCHA && (
              <Typography
                color="#DE3232"
                pl={2}
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "0.85rem" }}
              >
                {errors.CAPTCHA}
              </Typography>
            )}
          </Box>

          <Button
            variant="contained"
            disableElevation
            size="normal"
            sx={{ ...styles.button, backgroundColor: "#4E87AE" }}
            onClick={handleSubmit}
          >
            {idiomaSeleccionado.contact_cna[10]}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
