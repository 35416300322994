import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import Ilustracion_1 from "../../assets/imgs/Home/ilustracion_1_home.webp";
import Ilustracion_2 from "../../assets/imgs/Home/ilustracion_2_home.webp";
import Ilustracion_3 from "../../assets/imgs/Home/ilustracion_3_home.webp";
import Ilustracion_4 from "../../assets/imgs/Home/ilustracion_4_home.webp";
import Ilustracion_5 from "../../assets/imgs/Home/ilustracion_5_home.webp";
import Ilustracion_6 from "../../assets/imgs/Home/ilustracion_6_home.webp";
import Ilustracion_7 from "../../assets/imgs/Home/ilustracion_7_home.webp";
import MasterCard from "../../assets/imgs/Home/mastercard.svg";
import DinersClub from "../../assets/imgs/Home/dinersclub.svg";
import Visa from "../../assets/imgs/Home/visa.svg";
import AmericanExpress from "../../assets/imgs/Home/americanexpress.svg";
import WebPay from "../../assets/imgs/Home/webpay.svg";
import Check from "../../assets/icons/Home/check.svg";

import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const Home = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorPlomo: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },

    contenedorBlanco: {
      backgroundColor: "#FFFFFF",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },

    titulo: {
      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
    },

    subtitulo: {
      "@media(max-width: 600px)": {
        fontSize: "1.5rem",
      },
    },

    contenedorTextos1: {
      paddingTop: "10rem",
      paddingLeft: "10rem",
      paddingRight: "3rem",

      "@media(max-width: 1536px)": {
        paddingTop: "5rem",
        paddingLeft: "2rem",
        paddingRight: "0",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        textAlign: "center",
      },

      "@media(max-width: 600px)": {
        paddingTop: "1rem",
      },
    },

    contenedorImagen1: {
      paddingTop: "2rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingBottom: "2rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "0",
        paddingRight: "0",
      },

      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    imagen1: {
      width: "880px",
      height: "561px",

      "@media(max-width: 1536px)": {
        width: "600px",
        height: "382px",
      },

      "@media(max-width: 1200px)": {
        width: "800px",
        height: "510px",
      },

      "@media(max-width: 900px)": {
        width: "600px",
        height: "382px",
      },

      "@media(max-width: 600px)": {
        width: "400px",
        height: "255px",
      },

      "@media(max-width: 400px)": {
        width: "280px",
        height: "178px",
      },

      "@media(max-width: 280px)": {
        width: "200px",
        height: "127px",
      },
    },

    contenedorTextos2: {
      paddingTop: "8rem",
      paddingLeft: "15rem",
      paddingRight: "5rem",

      "@media(max-width: 1536px)": {
        paddingTop: "5rem",
        paddingLeft: "10rem",
        paddingRight: "0",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "1rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingTop: "1rem",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorImagen2: {
      paddingTop: "2rem",
      paddingLeft: "4rem",
      paddingBottom: "2rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },

      "@media(max-width: 1200px)": {
        textAlign: "center",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    imagen2: {
      width: "700px",
      height: "492px",

      "@media(max-width: 1536px)": {
        width: "570px",
        height: "401px",
      },

      "@media(max-width: 1200px)": {
        width: "800px",
        height: "562px",
      },

      "@media(max-width: 900px)": {
        width: "600px",
        height: "422px",
      },

      "@media(max-width: 600px)": {
        width: "300px",
        height: "211px",
      },

      "@media(max-width: 330px)": {
        width: "200px",
        height: "141px",
      },
    },

    contenedorTextos3: {
      paddingLeft: "4rem",
      paddingRight: "5rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "1rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "1rem",
        paddingLeft: "10rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingTop: "1rem",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorImagen3: {
      paddingLeft: "15rem",

      "@media(max-width: 1200px)": {
        textAlign: "center",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    imagen3: {
      width: "240px",
      height: "240px",

      "@media(max-width: 1200px)": {
        width: "400px",
        height: "400px",
      },

      "@media(max-width: 600px)": {
        width: "300px",
        height: "300px",
      },

      "@media(max-width: 330px)": {
        width: "200px",
        height: "200px",
      },
    },

    contenedorTextos4: {
      paddingTop: "10rem",
      paddingLeft: "15rem",
      paddingRight: "5rem",

      "@media(max-width: 1536px)": {
        paddingTop: "5rem",
        paddingLeft: "10rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "1rem",
        paddingLeft: "10rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingTop: "1rem",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorImagen4: {
      paddingLeft: "4rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },

      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    imagen4: {
      width: "700px",
      height: "495px",

      "@media(max-width: 1536px)": {
        width: "570px",
        height: "403px",
      },

      "@media(max-width: 1200px)": {
        width: "800px",
        height: "566px",
      },

      "@media(max-width: 900px)": {
        width: "600px",
        height: "424px",
      },

      "@media(max-width: 600px)": {
        width: "300px",
        height: "212px",
      },

      "@media(max-width: 330px)": {
        width: "200px",
        height: "141px",
      },
    },

    contenedorTextos5: {
      paddingLeft: "4rem",
      paddingRight: "10rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "1rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "1rem",
        paddingLeft: "10rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingTop: "1rem",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorImagen5: {
      paddingLeft: "15rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "15rem",
      },

      "@media(max-width: 1200px)": {
        paddingLeft: "0",
        textAlign: "center",
      },
    },

    imagen5: {
      width: "240px",
      height: "240px",

      "@media(max-width: 900px)": {
        width: "400px",
        height: "400px",
      },

      "@media(max-width: 600px)": {
        width: "270px",
        height: "270px",
      },

      "@media(max-width: 330px)": {
        width: "200px",
        height: "200px",
      },
    },

    contenedorTextos6: {
      paddingTop: "10rem",
      paddingLeft: "15rem",
      paddingRight: "5rem",

      "@media(max-width: 1536px)": {
        paddingTop: "5rem",
        paddingLeft: "10rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "1rem",
        paddingLeft: "10rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingTop: "1rem",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorImagen6: {
      paddingLeft: "4rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },

      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    imagen6: {
      width: "700px",
      height: "496px",

      "@media(max-width: 1536px)": {
        width: "570px",
        height: "404px",
      },

      "@media(max-width: 1200px)": {
        width: "800px",
        height: "567px",
      },

      "@media(max-width: 900px)": {
        width: "600px",
        height: "425px",
      },

      "@media(max-width: 600px)": {
        width: "300px",
        height: "213px",
      },

      "@media(max-width: 330px)": {
        width: "200px",
        height: "142px",
      },
    },

    contenedorMediosDePago: {
      paddingLeft: "12rem",
      paddingRight: "1rem",

      "@media(max-width: 1536px)": {
        paddingLeft: "5rem",
        paddingRight: "0",
      },

      "@media(max-width: 1200px)": {
        textAlign: "center",
        paddingLeft: "0",
        paddingRight: "0",
      },
    },

    contenedorTarjetas: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
        justifyContent: "center",
      },
    },

    contenedorContactanos: {
      display: "flex",

      "@media(max-width: 1200px)": {
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      },

      "@media(max-width: 600px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },

      "@media(max-width: 385px)": {
        display: "block",
      },
    },

    contenedorTextosContactanos: {
      "@media(max-width: 600px)": {
        paddingTop: "1.5rem",
        paddingLeft: "0",
        paddingRight: "0",
      },
    },

    imagen7: {
      width: "200px",
      height: "200px",

      "@media(max-width: 600px)": {
        width: "150px",
        height: "150px",
      },

      // "@media(max-width: 375px)": {
      //   width: "120px",
      //   height: "120px",
      // },
    },

    blueButton: {
      backgroundColor: "#4E87AE",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "14px",
      borderRadius: "38px",
      padding: "2%",
      textTransform: "none",
      marginBottom: "2vh",
      width: "220px",
    },

    greenButton: {
      backgroundColor: "#7C9D17",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "14px",
      borderRadius: "38px",
      padding: "2%",
      textTransform: "none",
      width: "220px",
    },

    tarjetas: {
      width: "113px",
      height: "73px",
    },

    contenidoDesktop: {
      "@media(max-width: 1200px)": {
        display: "none",
      },
    },

    contenidoMobile: {
      display: "none",
      "@media(max-width: 1199px)": {
        display: "block",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={0}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorPlomo}>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorTextos1}>
          <Typography
            variant="h1"
            color="#1C3254"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="4rem"
            mb={5}
            sx={styles.titulo}
          >
            {idiomaSeleccionado.home[0]}
          </Typography>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            mb={2}
            sx={styles.contenidoDesktop}
          >
            {idiomaSeleccionado.home[1]}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} xl={7} sx={styles.contenedorImagen1}>
          <Box
            component="img"
            src={Ilustracion_1}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen1}
          />
        </Grid>

        <Grid item xs={12} textAlign="center" sx={styles.contenidoMobile}>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            mb={2}
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[1]}
          </Typography>
        </Grid>
      </Grid>

      <Grid container sx={styles.contenedorBlanco}>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorTextos2}>
          <Typography
            variant="h2"
            color="#4E87AE"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[2]}
          </Typography>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            mb={4}
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[3]}
          </Typography>
          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={1}
          >
            {idiomaSeleccionado.home[4]}
          </Typography>
          <Box component="div" sx={styles.contenidoDesktop}>
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
              mb={5}
            >
              {idiomaSeleccionado.home[5]}
            </Typography>
            <Box component="div">
              <Button
                variant="contained"
                href="/procedimientos/servicios"
                sx={styles.blueButton}
              >
                {idiomaSeleccionado.home[6]}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} xl={7} sx={styles.contenedorImagen2}>
          <Box
            component="img"
            src={Ilustracion_2}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen2}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ...styles.contenidoMobile, ...styles.contenedorTextos2 }}
        >
          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={5}
          >
            {idiomaSeleccionado.home[5]}
          </Typography>
          <Box component="div">
            <Button
              variant="contained"
              href="/procedimientos/servicios"
              sx={styles.blueButton}
            >
              {idiomaSeleccionado.home[6]}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={styles.contenedorPlomo}>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorImagen3}>
          <Box
            component="img"
            src={Ilustracion_3}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen3}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorTextos3}>
          <Typography
            variant="h2"
            color="#4E87AE"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[7]}
          </Typography>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
            mb={3}
          >
            {idiomaSeleccionado.home[8]}
          </Typography>

          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={3}
          >
            {idiomaSeleccionado.home[9]}
          </Typography>

          <Box component="div" display="flex">
            <Box
              component="img"
              src={Check}
              alt="Check"
              width="15px"
              height="20px"
              mr={2}
            />
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
              mb={2}
            >
              {idiomaSeleccionado.home[10]}
            </Typography>
          </Box>
          <Box component="div" display="flex">
            <Box
              component="img"
              src={Check}
              alt="Check"
              width="15px"
              height="20px"
              mr={2}
            />
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
            >
              {idiomaSeleccionado.home[11]}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={styles.contenedorBlanco}>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorTextos4}>
          <Typography
            variant="h2"
            color="#4E87AE"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[12]}
          </Typography>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
            mb={3}
          >
            {idiomaSeleccionado.home[13]}
          </Typography>
          <Box component="div" sx={styles.contenidoDesktop}>
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
              mb={3}
            >
              {idiomaSeleccionado.home[14]}
            </Typography>
            <Box component="div">
              <Button
                variant="contained"
                href="/tarifas/tabla_tarifas"
                sx={styles.blueButton}
              >
                {idiomaSeleccionado.home[15]}
              </Button>
            </Box>
            <Box component="div">
              <Button
                variant="contained"
                href="/tarifas/simulador_tarifas"
                sx={styles.greenButton}
              >
                {idiomaSeleccionado.home[16]}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorImagen4}>
          <Box
            component="img"
            src={Ilustracion_4}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen4}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ...styles.contenedorTextos4, ...styles.contenidoMobile }}
        >
          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={3}
          >
            {idiomaSeleccionado.home[14]}
          </Typography>
          <Box component="div">
            <Button
              variant="contained"
              href="/tarifas/tabla_tarifas"
              sx={styles.blueButton}
            >
              {idiomaSeleccionado.home[15]}
            </Button>
          </Box>
          <Box component="div">
            <Button
              variant="contained"
              href="/tarifas/simulador_tarifas"
              sx={styles.greenButton}
            >
              {idiomaSeleccionado.home[16]}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={styles.contenedorPlomo}>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorImagen5}>
          <Box
            component="img"
            src={Ilustracion_5}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen5}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorTextos5}>
          <Typography
            variant="h2"
            color="#4E87AE"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[17]}
          </Typography>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
            mb={3}
          >
            {idiomaSeleccionado.home[18]}
          </Typography>
          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={3}
          >
            {idiomaSeleccionado.home[19]}
          </Typography>
          <Box component="div" display="flex">
            <Box
              component="img"
              src={Check}
              alt="Check"
              width="15px"
              height="20px"
              mr={2}
            />
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
              mb={1}
            >
              {idiomaSeleccionado.home[20]}
            </Typography>
          </Box>

          <Box component="div" display="flex">
            <Box
              component="img"
              src={Check}
              alt="Check"
              width="15px"
              height="20px"
              mr={2}
            />
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
            >
              {idiomaSeleccionado.home[21]}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={styles.contenedorBlanco}>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorTextos6}>
          <Typography
            variant="h2"
            color="#4E87AE"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
          >
            {idiomaSeleccionado.home[22]}
          </Typography>
          <Typography
            variant="h2"
            color="#30406B"
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
            mb={2}
          >
            {idiomaSeleccionado.home[23]}
          </Typography>
          <Box component="div" sx={styles.contenidoDesktop}>
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
              mb={2}
            >
              {idiomaSeleccionado.home[24]}
            </Typography>
            <Box component="div" display="flex" mb={1}>
              <Box
                component="img"
                src={Check}
                alt="Check"
                width="15px"
                height="20px"
                mr={2}
              />
              <Typography
                variant="h3"
                color="#172833"
                fontFamily="TitilliumWeb-Regular"
                fontSize="1rem"
              >
                {idiomaSeleccionado.home[25]}
              </Typography>
            </Box>
            <Box component="div" display="flex">
              <Box
                component="img"
                src={Check}
                alt="Check"
                width="15px"
                height="20px"
                mr={2}
              />
              <Typography
                variant="h3"
                color="#172833"
                fontFamily="TitilliumWeb-Regular"
                fontSize="1rem"
              >
                {idiomaSeleccionado.home[26]}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} xl={5} sx={styles.contenedorImagen6}>
          <Box
            component="img"
            src={Ilustracion_6}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen6}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ...styles.contenedorTextos6, ...styles.contenidoMobile }}
        >
          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={2}
          >
            {idiomaSeleccionado.home[24]}
          </Typography>
          <Box component="div" display="flex">
            <Box
              component="img"
              src={Check}
              alt="Check"
              width="15px"
              height="20px"
              mr={2}
            />
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
            >
              {idiomaSeleccionado.home[25]}
            </Typography>
          </Box>
          <Box component="div" display="flex">
            <Box
              component="img"
              src={Check}
              alt="Check"
              width="15px"
              height="20px"
              mr={2}
            />
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
            >
              {idiomaSeleccionado.home[26]}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={styles.contenedorPlomo}>
        <Grid item xs={12} lg={7} sx={styles.contenedorMediosDePago} mb={10}>
          <Typography
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
            variant="h2"
            color="#4E87AE"
          >
            {idiomaSeleccionado.home[27]}
          </Typography>
          <Typography
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="2rem"
            sx={styles.subtitulo}
            variant="h2"
            color="#30406B"
            mb={3}
          >
            {idiomaSeleccionado.home[28]}
          </Typography>
          <Typography
            variant="h3"
            color="#172833"
            fontFamily="TitilliumWeb-Regular"
            fontSize="1rem"
            mb={5}
          >
            {idiomaSeleccionado.home[29]}
          </Typography>

          <Grid container sx={styles.contenedorTarjetas}>
            <Grid item xs={12} md={2}>
              <Box
                component="img"
                src={MasterCard}
                alt="Logo de tarjeta MasterCard"
                sx={styles.tarjetas}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                component="img"
                src={DinersClub}
                alt="Logo de tarjeta DinersClub"
                sx={styles.tarjetas}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                component="img"
                src={Visa}
                alt="Logo de tarjeta Visa"
                sx={styles.tarjetas}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                component="img"
                src={AmericanExpress}
                alt="Logo de tarjeta American Express"
                sx={styles.tarjetas}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                component="img"
                src={WebPay}
                alt="Logo de tarjeta WebPay"
                sx={styles.tarjetas}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5} sx={styles.contenedorContactanos}>
          <Box
            component="img"
            src={Ilustracion_7}
            alt="Personas hablando sobre CNA"
            sx={styles.imagen7}
            pt={1}
            pr={2}
          />
          <Box
            component="div"
            display="block"
            pl={2}
            pr={2}
            sx={styles.contenedorTextosContactanos}
          >
            <Typography
              fontFamily="TitilliumWeb-SemiBold"
              fontSize="2rem"
              sx={styles.subtitulo}
              variant="h2"
              color="#4E87AE"
            >
              {idiomaSeleccionado.home[30]}
            </Typography>
            <Typography
              fontFamily="TitilliumWeb-SemiBold"
              fontSize="2rem"
              sx={styles.subtitulo}
              variant="h2"
              color="#30406B"
              mb={3}
            >
              {idiomaSeleccionado.home[31]}
            </Typography>
            <Typography
              variant="h3"
              color="#172833"
              fontFamily="TitilliumWeb-Regular"
              fontSize="1rem"
              mb={3}
            >
              {idiomaSeleccionado.home[32]}
            </Typography>
            <Button href="/contacto" variant="contained" sx={styles.blueButton}>
              {idiomaSeleccionado.home[33]}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
