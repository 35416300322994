import React from "react";
import { Grid, Typography, Box, Button, Link } from "@mui/material";

import Logo_Expediente from "../../assets/imgs/Proceedings/logo_expediente.svg";
import Expediente from "../../assets/imgs/Proceedings/expediente.webp";
import Imagen_Video from "../../assets/imgs/Proceedings/logo_video.svg";
import ManualDelUsuarioAbogado from "../../assets/documents/Manual_del_usuario_abogado.pdf";
import ManualDelUsuarioArbitro from "../../assets/documents/Manual_del_usuario_arbitro.pdf";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const Proceedings = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const redireccionar = (value) => {
    switch (value) {
      case 1:
        window.open("https://expedientevirtual.cl/", "_blank");
        break;

      case 2:
        window.open(
          "https://www.youtube.com/channel/UCtw1GqQgNQUc_XQFlGjRLwA",
          "_blank"
        );
        break;

      default:
        break;
    }
  };

  const descargarDocumento = (value) => {
    const link = document.createElement("a");

    switch (value) {
      case 1:
        link.download = `Manual_Del_Usuario_Abogado.pdf`;
        link.href = ManualDelUsuarioAbogado;
        link.click();
        break;

      case 2:
        link.download = `Manual_Del_Usuario_Arbitro.pdf`;
        link.href = ManualDelUsuarioArbitro;
        link.click();
        break;

      default:
        break;
    }
  };

  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "10rem",
    },

    contenedorInfoExpediente: {
      paddingTop: "2rem",
      paddingLeft: "15rem",
      paddingRight: "12rem",
      // height: "560px",

      "@media(max-width: 1746px)": {
        // paddingTop: "3rem",
        paddingLeft: "11rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 1536px)": {
        paddingLeft: "3rem",
        paddingRight: "3rem",
        // textAlign: "center",
        // height: "480px",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "0",
        textAlign: "center",
        paddingLeft: "10rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    texto: {
      "@media(max-width: 1200px)": {
        textAlign: "justify",
      },
    },

    logoExpediente: {
      "@media(max-width: 1536px)": {
        width: "350px",
      },

      "@media(max-width: 1200px)": {
        width: "300px",
      },

      "@media(max-width: 600px)": {
        width: "250px",
      },

      "@media(max-width: 400px)": {
        width: "200px",
      },
    },

    contenedorTarjeta: {
      backgroundColor: "#FFFFFF",
      width: "400px",
      height: "560px",
      boxShadow: "0px 3px 6px #00000029",

      "@media(max-width: 1536px)": {
        width: "320px",
      },

      "@media(max-width: 1200px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },

      "@media(max-width: 400px)": {
        width: "250px",
      },
    },

    contenedorImagen: {
      paddingLeft: "2rem",
      paddingRight: "1rem",
      // width: "400px",

      // "@media(max-width: 1536px)": {
      //   width: "320px",
      // },

      "@media(max-width: 1200px)": {
        paddingRight: "0",
        paddingLeft: "0",
        textAlign: "center",
      },
    },

    imagenVideo: {
      "@media(max-width: 400px)": {
        maxWidth: "200px",
      },
    },

    imagen: {
      maxWidth: "400px",

      "@media(max-width: 1536px)": {
        width: "320px",
      },

      "@media(max-width: 1200px)": {
        width: "320px",
      },

      "@media(max-width: 400px)": {
        maxWidth: "250px",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "250px",

      "@media(max-width: 350px)": {
        width: "220px",
        fontSize: "0.8rem",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={5}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} lg={5} mb={3}>
          <Box component="div" sx={styles.contenedorInfoExpediente}>
            <Box
              component="img"
              src={Logo_Expediente}
              alt="Logo e-xpediente virtual"
              mb={5}
              sx={styles.logoExpediente}
            />
            <Typography
              mb={3}
              color="#172833"
              sx={{
                fontFamily: "TitilliumWeb-Regular",
                fontSize: "1rem",
                ...styles.texto,
              }}
            >
              {idiomaSeleccionado.expediente[0]}
            </Typography>
            <Box component="div" mb={2}>
              <Link
                underline="none"
                onClick={() => redireccionar(1)}
                sx={{ cursor: "pointer" }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  sx={{ backgroundColor: "#4E87AE", ...styles.button }}
                >
                  {idiomaSeleccionado.expediente[1]}
                </Button>
              </Link>
            </Box>
            <Box component="div" mb={2}>
              <Button
                variant="contained"
                disableElevation
                sx={{ backgroundColor: "#7C9D1C", ...styles.button }}
                onClick={() => descargarDocumento(1)}
              >
                {idiomaSeleccionado.expediente[2]}
              </Button>
            </Box>
            <Box component="div">
              <Button
                variant="contained"
                disableElevation
                sx={{ backgroundColor: "#7C9D1C", ...styles.button }}
                onClick={() => descargarDocumento(2)}
              >
                {idiomaSeleccionado.expediente[3]}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={3} mb={5}>
          <Box component="div" borderRadius="4px" sx={styles.contenedorTarjeta}>
            <Box component="div" pt={5} pl={5} pr={5}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{
                  fontFamily: "TitilliumWeb-SemiBold",
                  fontSize: "1.2rem",
                }}
              >
                {idiomaSeleccionado.expediente[4]}
                <Typography
                  variant="span"
                  display="inline-block"
                  sx={{ marginRight: "3px" }}
                >
                  E-xpediente
                </Typography>
                Virtual?
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
                mb={5}
              >
                {idiomaSeleccionado.expediente[5]}
              </Typography>
              <Box
                component="img"
                src={Imagen_Video}
                alt="Ilustración de personas revisando los videos del canal de e-xpediente"
                mb={5}
                sx={styles.imagenVideo}
              />
              <Box
                component="div"
                pb={5}
                sx={{
                  bottom: "0",
                }}
              >
                <Link
                  onClick={() => redireccionar(2)}
                  sx={{ cursor: "pointer" }}
                  underline="none"
                >
                  <Typography
                    color="#7C9D1C"
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBoldItalic",
                      fontSize: "1.2rem",
                    }}
                  >
                    {idiomaSeleccionado.expediente[6]}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={3} sx={styles.contenedorImagen}>
          <Box
            component="img"
            src={Expediente}
            alt="Imagen de e-xpediente"
            height="560px"
            sx={styles.imagen}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Proceedings;
