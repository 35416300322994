import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Miembro_1 from "../../assets/imgs/Team/francisco_leturia.webp";
import Miembro_2 from "../../assets/imgs/Team/jose_miguel_ried.webp";
import Miembro_3 from "../../assets/imgs/Team/millaray_gonzalez.webp";

import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const Team = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },

    titulo: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "1rem",

      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
    },

    contenidoTarjeta: {
      marginBottom: "3rem",
      width: "1164px",
      height: "350px",
      backgroundColor: "#FFFFFF",
      borderRadius: "13px",
      boxShadow: "0px 3px 6px #42949C7E",
      padding: "5rem",
      display: "flex",
      marginLeft: "3rem",
      marginRight: "3rem",

      "@media(max-width: 1200px)": {
        // width: "80vh",
        paddingLeft: "10rem",
        paddingRight: "10rem",
        height: "800px",
        // padding: "7vh",
        display: "block",
      },

      "@media(max-width: 960px)": {
        height: "700px",
        width: "700px",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        marginLeft: "1rem",
        marginRight: "1rem",
        padding: "2rem",
        height: "600px",
      },

      "@media(max-width: 300px)": {
        padding: "1rem",
        height: "700px",
      },
    },

    contenedorNombreyProfesion: {
      marginBottom: "1rem",

      "@media(max-width: 1200px)": {
        marginBottom: "2rem",
        textAlign: "center",
        width: "100%",
      },
    },

    labelNombreyProfesion: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "1.5rem",

      "@media(max-width: 600px)": {
        fontSize: "1.2rem",
      },

      "@media(max-width: 380px)": {
        fontSize: "1rem",
      },
    },

    labelDescripcion: {
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1.1rem",
      marginBottom: "1rem",

      "@media(max-width: 600px)": {
        fontSize: "0.8rem",
      },
    },

    contenedorImagenTarjeta: {
      marginRight: "7vh",

      "@media(max-width: 1200px)": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
      },
    },

    imagenTarjeta: {
      width: "200px",
      padding: "2vh",

      "@media(max-width: 960px)": {
        width: "100px",
      },

      "@media(max-width: 600px)": {
        width: "70px",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={1}
        menuItem={2}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} textAlign="center" sx={{ marginBottom: "5vh" }}>
          <Box component="div">
            <Typography sx={styles.titulo} variant="h1">
              {idiomaSeleccionado.team_cna[0]}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Box component="div" sx={styles.contenidoTarjeta}>
            <Box component="div" sx={styles.contenedorImagenTarjeta}>
              <Box
                component="img"
                src={Miembro_1}
                alt="Imagen De Francisco Javier Leturia Infante Del Equipo Del CNA"
                sx={styles.imagenTarjeta}
              />
            </Box>
            <Box component="div">
              <Box component="div" sx={styles.contenedorNombreyProfesion}>
                <Typography color="#4E87AE" sx={styles.labelNombreyProfesion}>
                  {idiomaSeleccionado.team_cna[1]}
                </Typography>
                <Typography color="#1C3254" sx={styles.labelNombreyProfesion}>
                  {idiomaSeleccionado.team_cna[2]}
                </Typography>
              </Box>
              <Box component="div">
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[3]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[4]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[5]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[6]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[7]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[8]}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Box component="div" sx={styles.contenidoTarjeta}>
            <Box component="div" sx={styles.contenedorImagenTarjeta}>
              <Box
                component="img"
                src={Miembro_2}
                alt="Imagen De José Miguel Ried Undurraga Del Equipo Del CNA"
                sx={styles.imagenTarjeta}
              />
            </Box>
            <Box component="div">
              <Box component="div" sx={styles.contenedorNombreyProfesion}>
                <Typography color="#4E87AE" sx={styles.labelNombreyProfesion}>
                  {idiomaSeleccionado.team_cna[9]}
                </Typography>
                <Typography color="#1C3254" sx={styles.labelNombreyProfesion}>
                  {idiomaSeleccionado.team_cna[10]}
                </Typography>
              </Box>
              <Box component="div">
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[11]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[12]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[13]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[14]}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={12} display="flex" justifyContent="center">
          <Box component="div" sx={styles.contenidoTarjeta}>
            <Box component="div" sx={styles.contenedorImagenTarjeta}>
              <Box
                component="img"
                src={Miembro_3}
                alt="Imagen no disponible de miembro de equipo CNA"
                sx={styles.imagenTarjeta}
              />
            </Box>
            <Box component="div">
              <Box component="div" sx={styles.contenedorNombreyProfesion}>
                <Typography color="#4E87AE" sx={styles.labelNombreyProfesion}>
                  {idiomaSeleccionado.team_cna[15]}
                </Typography>
                <Typography color="#1C3254" sx={styles.labelNombreyProfesion}>
                  {idiomaSeleccionado.team_cna[16]}
                </Typography>
              </Box>
              <Box component="div">
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[17]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[18]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[19]}
                </Typography>
                <Typography color="#172833" sx={styles.labelDescripcion}>
                  {idiomaSeleccionado.team_cna[20]}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Team;
