import React from "react";
import { Grid, Button, Typography, Box, Divider, Link } from "@mui/material";
import Logo_Blanco_CNA from "../assets/imgs/Footer/logo_blanco_cna.webp";
import Icono_Mapa from "../assets/icons/Footer/icon_mapa_footer.webp";
import Icono_Mensaje from "../assets/icons/Footer/icon_mensaje_footer.webp";
import Icono_Telefono from "../assets/icons/Footer/icon_telefono_footer.webp";
import es from "../locales/es.json";

const Footer = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {

  const styles = {
    footer: {
      width: "100%",
      backgroundColor: "#243348",
      textAlign: "center",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },
  
    contenedorInfoCNA: {
      display: "flex",
      marginBottom: "3rem",
  
      "@media(max-width: 1200px)": {
        display: "block",
      },
    },
  
    button: {
      backgroundColor: "#4E87AE",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      textTransform: "none",
      width: idiomaSeleccionado === es ? "220px" : "300px",

      "@media(max-width: 600px)": {
        width: idiomaSeleccionado === es ? "220px" : "270px",
      },

    },
  
    logoCNA: {
      width: "380px",
      height: "86px",
  
      "@media(max-width: 1480px)": {
        width: "330px",
        height: "75px",
      },
  
      "@media(max-width: 960px)": {
        width: "250px",
        height: "57px",
      },
  
      "@media(max-width: 600px)": {
        width: "200px",
        height: "45px",
      },
    },
  
    contenedorPreguntasFrecuentes: {
      textAlign: "left",
    },
  
    hrMobile: {
      display: "none",
      "@media(max-width: 1200px)": {
        display: "block",
        border: "1px solid #707070",
        color: "#707070",
        height: "0px",
        width: "80%",
        margin: "auto",
        marginBottom: "3rem",
      },
    },
  
    hr: {
      border: "1px solid #707070",
      color: "#707070",
      height: "0px",
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "3rem",
  
      "@media(max-width: 1200px)": {
        width: "80%",
      },
    },
  
    contenedorContacto: {
      textAlign: "left",
      display: "flex",
  
      "@media(max-width: 1200px)": {
        display: "block",
        textAlign: "center",
      },
    },
  
    contenedorLabel: {
      marginBottom: "1rem",
  
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },
  
    contenedorBoton: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
        marginBottom: "3rem",
      },
    },
  
    label: {
      color: "#FFFFFF",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1.1rem",
      marginLeft: "5px",
  
      "@media(max-width: 600px)": {
        fontSize: "1rem",
      },
    },
  
    labelContacto: {
      color: "#FFFFFF",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1.1rem",
      paddingTop: "0px",
      marginTop: "0px",
      marginLeft: "10px",
  
      "@media(max-width: 1200px)": {
        marginBottom: "3rem",
      },
  
      "@media(max-width: 600px)": {
        fontSize: "1rem",
      },
    },
  
    copyright: {
      color: "#fff",
      fontSize: "1.1rem",
      fontFamily: "TitilliumWeb-Regular",
      textAlign: "center",
      opacity: "1",
  
      "@media(max-width: 600px)": {
        fontSize: "1rem",
      },
    },
  };

  const iconosContacto = [
    {
      src: Icono_Telefono,
      alt: "Icono Teléfono Del CNA (Centro Nacional De Arbitrajes)",
      text: "+562 27992426",
    },
    {
      src: Icono_Mensaje,
      alt: "Icono Mail Del CNA (Centro Nacional De Arbitrajes)",
      text: "contacto@cna.cl",
    },
    {
      src: Icono_Mapa,
      alt: "Icono Dirección Del CNA (Centro Nacional De Arbitrajes)",
      text: idiomaSeleccionado.footer[2],
    },
  ];

  return (
    <Grid container sx={styles.footer}>
      <Grid item xs={12} sx={styles.contenedorInfoCNA}>
        <Grid item xs={12} lg={4} mb={3}>
          <Box
            component="img"
            src={Logo_Blanco_CNA}
            alt="Logo del CNA (Centro Nacional De Arbitrajes)"
            sx={styles.logoCNA}
          />
        </Grid>

        <Divider sx={styles.hrMobile} />

        <Grid item xs={12} lg={3} textAlign="left">
          <Box component="div" sx={styles.contenedorLabel}>
            <Typography sx={styles.label}>
              {idiomaSeleccionado.footer[0]}
            </Typography>
          </Box>
          <Box sx={styles.contenedorBoton}>
            <Button
              variant="contained"
              disableElevation
              sx={styles.button}
              href="/preguntas_frecuentes"
            >
              {idiomaSeleccionado.footer[1]}
            </Button>
          </Box>
        </Grid>

        <Divider sx={styles.hrMobile} />

        <Grid item xs={12} lg={5}>
          {iconosContacto.map((icon, index) => (
            <Box component="div" sx={styles.contenedorContacto} key={index}>
              <Box
                component="img"
                src={icon.src}
                alt={icon.alt}
                height="30px"
                width="30px"
                mb={2}
                sx={{ verticalAlign: "middle" }}
              />
              {icon.text === "contacto@cna.cl" ? (
                <Link href="mailto:contacto@cna.cl" underline="none">
                  <Typography sx={styles.labelContacto}>{icon.text}</Typography>
                </Link>
              ) : (
                <Typography sx={styles.labelContacto}>{icon.text}</Typography>
              )}
            </Box>
          ))}
        </Grid>
      </Grid>

      <Divider sx={styles.hr} />

      <Grid item xs={12}>
        <Typography sx={styles.copyright}>
          {idiomaSeleccionado.footer[3]}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
