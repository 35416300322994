import React, { useState } from "react";
import { Grid, Box } from "@mui/material";

import Introduction from "../../components/ArbitrationForm/Introduction";
import StagesForm from "../../components/ArbitrationForm/StagesForm";
import Content from "../../components/ArbitrationForm/Content";
import NavbarMenu from "../../components/NavbarMenu";

const ArbitrationForm = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const [stage, setStage] = useState(0);

  const handleStartForm = () => {
    setStage(1);
  };

  const handleChangeStage = (value) => {
    setStage(value);
  };

  return stage === 0 ? (
    <Box component="div">
      <NavbarMenu idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado}  />
      <Introduction handleStartForm={handleStartForm} idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado} />
    </Box>
  ) : (
    <Box component="div">
      <NavbarMenu idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado}  />
      <Grid container>
        <StagesForm stage={stage} handleChangeStage={handleChangeStage} idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado} />
        <Content stage={stage} handleChangeStage={handleChangeStage} idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado} />
      </Grid>
    </Box>
  );
};

export default ArbitrationForm;
