import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";

import Triangulo from "../../assets/icons/RateSimulator/triangulo.svg";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";

import NavbarMenu from "../../components/NavbarMenu";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "white",
  },

  "& .MuiOutlinedInput-root": {
    maxHeight: "35px",
    borderRadius: "0",
    marginTop: "auto",
    marginBottom: "auto",

    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },

  "& .MuiInputBase-input": {
    maxHeight: "1px",
    borderRadius: "0",
    marginTop: "auto",
    marginBottom: "auto",
    backgroundColor: "#FFFFFF",
  },

  // "& .MuiOutlinedInput-notchedOutline": {
  //   border: "1px solid #484850",
  //   borderRadius: "5px 5px 0 0",
  // },
}));

const StyledSecondTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    maxHeight: "35px",
    borderRadius: "0",
    marginTop: "auto",
    marginBottom: "auto",

    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },

  "& .MuiInputBase-input": {
    maxHeight: "1px",
    borderRadius: "0",
    marginTop: "auto",
    marginBottom: "auto",
    backgroundColor: "#CDE0ED",
  },
}));

const RateSimulator = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const [tarifaSimulada, setTarifaSimulada] = useState(false);
  const [montoDisputa, setMontoDisputa] = useState();
  const [corretaje, setCorretaje] = useState(true);
  const [tasaSolicitud, setTasaSolicitud] = useState();
  const [honorariosArbitrales, setHonorariosArbitrales] = useState("");
  const [tasaAdministrativaCNA, setTasaAdministrativaCNA] = useState("");
  const [total, setTotal] = useState("");
  const [mensajeError, setMensajeError] = useState("");
  const [IVA,setIVA] = useState("");
  const [totalConIVA,setTotalConIVA] = useState("");

  const simularTarifa = () => {
    let honorarioAlbitral = 0;
    let tasaAdministrativa = 0;
    let tasaDeSolicitud = 0;

    if (typeof montoDisputa === "undefined" || montoDisputa.length === 0) {
      setMensajeError(idiomaSeleccionado.fare_simulator_cna[19]);
      return;
    }

    let montoDisputaUF = parseFloat(montoDisputa.toString().replace(",", "."));

    if (montoDisputaUF <= 1000.0) {
      honorarioAlbitral = 30;
      tasaDeSolicitud = corretaje ? 5 : 10;
    } else if (montoDisputaUF > 1000.0 && montoDisputaUF <= 3000.0) {
      honorarioAlbitral = 45;
      tasaAdministrativa = 15;
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 3000.0 && montoDisputaUF <= 8000.0) {
      honorarioAlbitral = ((montoDisputaUF * 7) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 8000.0 && montoDisputaUF <= 25000.0) {
      honorarioAlbitral =
        (montoDisputaUF * 5) / 100 < 561
          ? 561
          : ((montoDisputaUF * 5) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 25000.0 && montoDisputaUF <= 65000.0) {
      honorarioAlbitral =
        (montoDisputaUF * 3.5) / 100 < 1251
          ? 1251
          : ((montoDisputaUF * 3.5) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 65000.0 && montoDisputaUF <= 150000.0) {
      honorarioAlbitral =
        (montoDisputaUF * 2.5) / 100 < 2276
          ? 2276
          : ((montoDisputaUF * 2.5) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 150000.0 && montoDisputaUF <= 350000.0) {
      honorarioAlbitral =
        (montoDisputaUF * 1.5) / 100 < 3751
          ? 3751
          : ((montoDisputaUF * 1.5) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 350000.0 && montoDisputaUF <= 1850000.0) {
      honorarioAlbitral =
        (montoDisputaUF * 0.5) / 100 < 5251
          ? 5251
          : ((montoDisputaUF * 0.5) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 1850000.0 && montoDisputaUF <= 5000000.0) {
      honorarioAlbitral =
        (montoDisputaUF * 0.25) / 100 < 9251
          ? 9251
          : ((montoDisputaUF * 0.25) / 100).toFixed(3);
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else if (montoDisputaUF > 5000000.0) {
      honorarioAlbitral = 28250;
      tasaAdministrativa =
        (honorarioAlbitral * 10) / 100 < 30
          ? 30
          : ((honorarioAlbitral * 10) / 100).toFixed(3);
      tasaDeSolicitud = corretaje ? 5 : 20;
    } else {
      honorarioAlbitral = 0;
      tasaAdministrativa = 0;
      tasaDeSolicitud = 0;
    }

    let sumTotal = (
      parseFloat(honorarioAlbitral) +
      parseFloat(tasaAdministrativa) +
      parseFloat(tasaDeSolicitud)
    ).toFixed(3);
    console.log('honorarioAlbitral: ',honorarioAlbitral)
    console.log('tasaAdministrativa: ',tasaAdministrativa)
    console.log('tasaDeSolicitud: ',tasaDeSolicitud)
    console.log('total: ',sumTotal)
    const IVA = (parseFloat(sumTotal) * 0.19);
    const TOTAL_CON_IVA = (parseFloat(sumTotal) + IVA);
    console.log(TOTAL_CON_IVA)

    sumTotal = sumTotal.toString().replace(".000", "");
    
    setIVA(`${IVA.toFixed(2).replace(".00", "").replace(".", ",")} U.F`);
    setTotalConIVA(`${TOTAL_CON_IVA.toFixed(3).replace(".000", "").replace(".", ",")} U.F`);

    setHonorariosArbitrales(
      honorarioAlbitral.toString().replace(".", ",") + " U.F"
    );
    setTasaAdministrativaCNA(
      tasaAdministrativa.toString().replace(".", ",") + " U.F"
    );
    setTasaSolicitud(tasaDeSolicitud.toString().replace(".", ",") + " U.F");

    setTotal(sumTotal.replace(".", ",") + " U.F");
    setMensajeError("");
    setTarifaSimulada(true);
  };

  const volver = () => {
    setHonorariosArbitrales("");
    setTasaAdministrativaCNA("");
    setTasaSolicitud("");
    setTotal("");
    setMensajeError("");
    setTarifaSimulada(false);
  };

  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "10rem",
    },

    contenedorTextoSimulador: {
      paddingLeft: "10rem",
      paddingRight: "10rem",

      "@media(max-width: 1746px)": {
        // paddingTop: "3rem",
        paddingLeft: "11rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 1536px)": {
        // paddingTop: "3rem",
        paddingLeft: "4rem",
        paddingRight: "3rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "0",
        paddingLeft: "10rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 900px)": {
        paddingTop: "0",
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 600px)": {
        paddingTop: "0",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorTitulo: {
      display: "block",
      "@media(max-width: 1200px)": {
        display: "flex",
        justifyContent: "center",
      },
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4rem",
      "@media(max-width: 900px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 300px)": {
        fontSize: "1.5rem",
      },
    },

    contenidoDesktop: {
      "@media(max-width: 1200px)": {
        display: "none",
      },
    },

    contenedorSimulador: {
      backgroundColor: "#FFFFFF",
      height: "650px",
      maxWidth: "700px",

      "@media(max-width: 1536px)": {
        maxWidth: "580px",
      },

      "@media(max-width: 1200px)": {
        maxWidth: "700px",
        marginLeft: "auto",
        marginRight: "auto",
      },

      "@media(max-width: 900px)": {
        maxWidth: "500px",
      },

      "@media(max-width: 600px)": {
        maxWidth: "300px",
      },
    },

    button: {
      backgroundColor: "#4E87AE",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "220px",
    },

    buttonVolver: {
      backgroundColor: "#7C9D1C",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "220px",
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={4}
        menuItem={10}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} lg={6} pb={5}>
          <Box component="div" sx={styles.contenedorTextoSimulador}>
            <Box component="div" sx={styles.contenedorTitulo}>
              <Typography
                variant="h1"
                mr={1}
                color="#1C3254"
                sx={styles.titulo}
              >
               {idiomaSeleccionado.fare_simulator_cna[0]}
              </Typography>
              <Typography
                variant="h1"
                color="#1C3254"
                mb={4}
                sx={styles.titulo}
              >
                 {idiomaSeleccionado.fare_simulator_cna[1]}
              </Typography>
            </Box>
            <Typography
              mb={3}
              color="#172833"
              sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
            >
               {idiomaSeleccionado.fare_simulator_cna[2]}
            </Typography>
            <Typography
              color="#1C3254"
              mb={4}
              sx={{
                fontFamily: "TitilliumWeb-SemiBold",
                fontSize: "1.2rem",
                ...styles.contenidoDesktop,
              }}
            >
               {idiomaSeleccionado.fare_simulator_cna[3]}
            </Typography>

            <Box component="div" display="flex">
              <Box
                component="img"
                src={Triangulo}
                alt="Triangulo"
                height="25px"
                width="10px"
                mr={2}
              />
              <Typography
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                  {idiomaSeleccionado.fare_simulator_cna[4]}
              </Typography>
            </Box>

            <Box component="div" display="flex" mb={2}>
              <Box
                component="img"
                src={Triangulo}
                alt="Triangulo"
                height="25px"
                width="10px"
                mr={2}
              />
              <Typography
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
               {idiomaSeleccionado.fare_simulator_cna[5]}
              </Typography>
            </Box>

            <Box component="div" display="flex" mb={2}>
              <Box
                component="img"
                src={Triangulo}
                alt="Triangulo"
                height="25px"
                width="10px"
                mr={2}
              />
              <Typography
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                  {idiomaSeleccionado.fare_simulator_cna[6]}
              </Typography>
            </Box>

            <Box component="div" display="flex">
              <Box
                component="img"
                src={Triangulo}
                alt="Triangulo"
                height="25px"
                width="10px"
                mr={2}
              />
              <Typography
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                 {idiomaSeleccionado.fare_simulator_cna[7]}
              </Typography>
            </Box>
            <Box component="div" display="flex">
              <Box
                component="img"
                src={Triangulo}
                alt="Triangulo"
                height="25px"
                width="10px"
                mr={2}
              />
              <Typography
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                 {idiomaSeleccionado.fare_simulator_cna[22]}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Box component="div" sx={styles.contenedorSimulador}>
            <Box
              component="div"
              sx={{ backgroundColor: "#E1EEF7", height: "70px" }}
            >
              <Typography
                color="#1C3254"
                pt={3}
                pb={3}
                pl={5}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                 {idiomaSeleccionado.fare_simulator_cna[8]}
              </Typography>
            </Box>

            <Box component="div" padding={5}>
              {!tarifaSimulada ? (
                <>
                  {mensajeError !== "" && (
                    <Typography
                      color="red"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {mensajeError}
                    </Typography>
                  )}
                
                  <Typography
                    mb={3}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[10]}
                  </Typography>
                  <Box component="div" mb={3}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="si"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                        onChange={(e) =>
                          e.target.value === "si"
                            ? setCorretaje(true)
                            : setCorretaje(false)
                        }
                      >
                        <FormControlLabel
                          value="si"
                          control={<Radio />}
                          label={
                            <Typography
                              sx={{
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              }}
                            >
                               {idiomaSeleccionado.fare_simulator_cna[11]}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label={
                            <Typography
                              sx={{
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              }}
                            >
                               {idiomaSeleccionado.fare_simulator_cna[12]}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[9]}
                  </Typography>
                  <Box
                    component="div"
                    mb={5}
                    pl={1}
                    pr={1}
                    sx={{
                      display: "flex",
                      height: "40px",
                      border: "1px solid #0F7099",
                      borderRadius: "7px",
                      maxWidth: "370px",
                    }}
                  >
                    <StyledTextField
                      hiddenLabel
                      id="outlined-basic"
                      sx={{ width: "80%" }}
                      placeholder="00"
                      value={montoDisputa}
                      onChange={(e) => setMontoDisputa(e.target.value)}
                    />
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                      }}
                    >
                      <Typography
                        color="#1C3254"
                        pr={2}
                        pl={2}
                        sx={{
                          fontFamily: "TitilliumWeb-SemiBold",
                          fontSize: "1.2rem",
                        }}
                      >
                        UF
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="div" pb={4} onClick={simularTarifa}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={styles.button}
                    >
                      {idiomaSeleccionado.fare_simulator_cna[13]}
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    mb={2}
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[9]}
                  </Typography>
                  <Box
                    component="div"
                    pl={1}
                    pr={1}
                    mb={3}
                    display="flex"
                    height="40px"
                    border="1px solid #0F7099"
                    borderRadius="7px"
                    maxWidth="370px"
                    backgroundColor="#CDE0ED"
                  >
                    <StyledSecondTextField
                      hiddenLabel
                      id="outlined-basic"
                      sx={{ width: "80%" }}
                      value={montoDisputa}
                      disabled
                    />
                    <Box
                      component="div"
                      border="0px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="#CDE0ED"
                      sx={{ width: "20%" }}
                    >
                      <Typography
                        color="#1C3254"
                        pr={2}
                        pl={2}
                        sx={{
                          fontFamily: "TitilliumWeb-SemiBold",
                          fontSize: "1.2rem",
                        }}
                      >
                        UF
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#4E87AE",
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.2rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[14]}
                  </Typography>
                  <Typography
                    mb={1}
                    color="#172833"
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {tasaSolicitud}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4E87AE",
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.2rem",
                    }}
                  >
                   {idiomaSeleccionado.fare_simulator_cna[15]}
                  </Typography>
                  <Typography
                    mb={1}
                    color="#172833"
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {honorariosArbitrales}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4E87AE",
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.2rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[16]}
                  </Typography>
                  <Typography
                    mb={1}
                    color="#172833"
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {tasaAdministrativaCNA}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4E87AE",
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.2rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[17]}
                  </Typography>
                  <Typography
                    color="#172833"
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {total}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4E87AE",
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.2rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[20]}
                  </Typography>
                  <Typography
                    color="#172833"
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {IVA}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4E87AE",
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.2rem",
                    }}
                  >
                     {idiomaSeleccionado.fare_simulator_cna[21]}
                  </Typography>
                  <Typography
                    mb={2}
                    color="#172833"
                    sx={{
                      fontFamily: "TitilliumWeb-Regular",
                      fontSize: "1rem",
                    }}
                  >
                    {totalConIVA}
                  </Typography>

                  <Box component="div" pb={4} onClick={volver}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={styles.buttonVolver}
                    >
                      {idiomaSeleccionado.fare_simulator_cna[18]}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RateSimulator;
