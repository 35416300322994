import React from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import Download from "../../assets/imgs/Regulations/icon_download.webp";
// import CodigoEtica from "../../assets/documents/CNA-Código-de-Ética-2017.pdf";
import CodigoEtica from "../../assets/documents/codigodeetica_CNA_161024v1.pdf";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const OthersDocuments = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5vh",
      paddingBottom: "15vh",
    },

    titulo: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "2vh",

      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
    },

    subtitulo: {
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      marginBottom: "2vh",
    },

    contenedorCodigoEtica: {
      justifyContent: "center",
      display: "flex",
    },

    tarjetaReglamentos: {
      backgroundColor: "#FFFFFF",
      borderRadius: "6px",
      boxShadow: "0px 3px 6px #42949C7E",
      height: "400px",
      width: "370px",

      "@media(max-width: 960px)": {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "5vh",
      },

      "@media(max-width: 600px)": {
        height: "300px",
        width: "300px",
      },

      "@media(max-width: 340px)": {
        width: "230px",
      },
    },

    iconDescarga: {
      width: "110px",
      marginBottom: "3vh",

      "@media(max-width: 600px)": {
        width: "60px",
      },
    },

    labelReglamentoArbitral: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "1.5rem",

      "@media(max-width: 600px)": {
        fontSize: "1.2rem",
      },
    },

    labelAnioReglamento: {
      color: "#4E87AE",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4.4rem",

      "@media(max-width: 600px)": {
        fontSize: "2.5rem",
      },
    },
  };

  const descargarCodigoEtica = () => {
    const link = document.createElement("a");
    link.download = `codigodeetica_CNA_161024v1.pdf`;
    link.href = CodigoEtica;
    link.click();
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={3}
        menuItem={8}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <ArbitrationButton
          idiomaSeleccionado={idiomaSeleccionado}
          setIdiomaSeleccionado={setIdiomaSeleccionado}
        />
        <Grid item xs={12} textAlign="center">
          <Box component="div">
            <Typography sx={styles.titulo} variant="h1">
              {idiomaSeleccionado.others_regulations_cna[0]}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box component="div" sx={{ marginBottom: "4vh" }}>
            <Typography
              color="#172833"
              sx={{
                fontFamily: "TitilliumWeb-Regular",
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              {idiomaSeleccionado.others_regulations_cna[1]}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={styles.contenedorCodigoEtica}>
          <Box component="div" sx={styles.tarjetaReglamentos}>
            <Link
              underline="none"
              onClick={descargarCodigoEtica}
              sx={{ cursor: "pointer" }}
            >
              <Box
                component="div"
                textAlign="center"
                sx={{ paddingTop: "8.6vh", paddingBottom: "5vh" }}
              >
                <Box
                  component="img"
                  src={Download}
                  alt="Icono descarga de otros documentos"
                  sx={styles.iconDescarga}
                />
                <Typography sx={styles.labelReglamentoArbitral}>
                  {idiomaSeleccionado.others_regulations_cna[2]}
                </Typography>
                <Typography sx={styles.labelAnioReglamento}>
                  {idiomaSeleccionado.others_regulations_cna[3]}
                </Typography>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OthersDocuments;
