import React from "react";
import {
  Box,
} from "@mui/material";

import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

const styles = {
  contenidoDesktop: {
    "@media(max-width: 1200px)": {
      display: "none",
    },
  },

  contenidoMobile: {
    display: "none",
    "@media(max-width: 1200px)": {
      display: "flex",
    },
  },
};

const NavbarMenu = ({value, menuItem, idiomaSeleccionado, setIdiomaSeleccionado}) => {
  return (
    <Box component="div">
      <Box component="div" sx={styles.contenidoDesktop}>
        <NavbarDesktop value={value} menuItem={menuItem} idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado} />
      </Box>
      <Box component="div" sx={styles.contenidoMobile}>
        <NavbarMobile idiomaSeleccionado={idiomaSeleccionado} setIdiomaSeleccionado={setIdiomaSeleccionado} />
      </Box>
    </Box>
  );
};

export default NavbarMenu;
