import React from "react";
import { Grid, Box, Typography, Divider, Button } from "@mui/material";

import PrimeraEtapaFormularioCNA from "../../assets/imgs/ArbitrationForm/PrimeraEtapaFormularioCNA.png";
import SegundaEtapaFormularioCNA from "../../assets/imgs/ArbitrationForm/SegundaEtapaFormularioCNA.png";
import TerceraEtapaFormularioCNA from "../../assets/imgs/ArbitrationForm/TerceraEtapaFormularioCNA.png";
import CuartaEtapaFormularioCNA from "../../assets/imgs/ArbitrationForm/CuartaEtapaFormularioCNA.png";

const StagesForm = ({
  stage,
  handleChangeStage,
  idiomaSeleccionado,
  setIdiomaSeleccionado,
}) => {
  return (
    <Grid item xs={12} md={4}>
      <Box component="div" sx={{ textAlign: "center" }} padding={5}>
        {stage === 1 && (
          <Box component="div" sx={{ textAlign: "left" }}>
            <Button
              sx={{
                fontFamily: "TitilliumWeb-SemiBoldItalic",
                fontSize: "1rem",
                color: "#7C9D1C",
              }}
              onClick={() => handleChangeStage(0)}
            >
              {idiomaSeleccionado.stages_form_cna[0]}
            </Button>
          </Box>
        )}

        <Typography
          color="#30406B"
          fontFamily="TitilliumWeb-SemiBold"
          fontSize="1rem"
        >
          {idiomaSeleccionado.stages_form_cna[1]}
        </Typography>
        <Box
          component="img"
          src={
            stage === 1
              ? PrimeraEtapaFormularioCNA
              : stage === 2
              ? SegundaEtapaFormularioCNA
              : stage === 3
              ? TerceraEtapaFormularioCNA
              : CuartaEtapaFormularioCNA
          }
          alt={
            stage === 1
              ? "Paso 1 de 4 del formulario de ingreso de arbitraje del CNA (Centro Nacional De Arbitrajes)"
              : stage === 2
              ? "Paso 2 de 4 del formulario de ingreso de arbitraje del CNA (Centro Nacional De Arbitrajes)"
              : stage === 3
              ? "Paso 3 de 4 del formulario de ingreso de arbitraje del CNA (Centro Nacional De Arbitrajes)"
              : "Paso 4 de 4 del formulario de ingreso de arbitraje del CNA (Centro Nacional De Arbitrajes)"
          }
          mb={5}
        />
        <Box component="div" sx={{ textAlign: "left" }}>
          <Divider />
          <Typography
            pt={5}
            pb={5}
            color={stage === 1 ? "#1C3254" : "#ACACAC"}
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="1.4rem"
          >
            {idiomaSeleccionado.stages_form_cna[2]}
          </Typography>
          <Divider />
          <Typography
            color={stage === 2 ? "#1C3254" : "#ACACAC"}
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="1.4rem"
            pt={5}
            pb={5}
          >
            {idiomaSeleccionado.stages_form_cna[3]}
          </Typography>
          <Divider />
          <Typography
            color={stage === 3 ? "#1C3254" : "#ACACAC"}
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="1.4rem"
            pt={5}
            pb={5}
          >
            {idiomaSeleccionado.stages_form_cna[4]}
          </Typography>
          <Divider />
          <Typography
            color={stage === 4 ? "#1C3254" : "#ACACAC"}
            fontFamily="TitilliumWeb-SemiBold"
            fontSize="1.4rem"
            pt={5}
            pb={5}
          >
            {idiomaSeleccionado.stages_form_cna[5]}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default StagesForm;
