import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Ilustracion from "../../assets/imgs/ProBondArbitration/ilustracion.webp";
import Instituciones from "../../assets/imgs/ProBondArbitration/instituciones.webp";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const ProBondArbitration = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "10rem",
    },

    contenedorArbitrajePROBonos: {
      paddingTop: "5rem",
      paddingLeft: "23rem",
      paddingRight: "10rem",

      "@media(max-width: 1746px)": {
        paddingTop: "3rem",
        paddingLeft: "11rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 1536px)": {
        paddingTop: "0",
        paddingLeft: "7rem",
        paddingRight: "6rem",
      },

      "@media(max-width: 1200px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorTitulo: {
      display: "block",
      "@media(max-width: 1200px)": {
        display: "flex",
        justifyContent: "center",
      },
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4rem",
      "@media(max-width: 900px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 299px)": {
        fontSize: "1.5rem",
      },
    },

    contenedorImagenArbitrajePROBonos: {
      paddingRight: "15rem",

      "@media(max-width: 1200px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginBottom: "1rem",
        textAlign: "center",
      },
    },

    contenidoDesktop: {
      "@media(max-width: 1200px)": {
        display: "none",
      },
    },

    contenidoMobile: {
      display: "none",
      "@media(max-width: 1200px)": {
        display: "block",
      },
    },

    contenedorImagenFundacionesCNA: {
      paddingTop: "1rem",
      paddingLeft: "23rem",
      paddingRight: "1rem",

      "@media(max-width: 1746px)": {
        paddingLeft: "11rem",
      },

      "@media(max-width: 1536px)": {
        paddingLeft: "7rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "0",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginBottom: "2rem",
        textAlign: "center",
      },
    },

    contenedorFundacionesCNA: {
      paddingTop: "10rem",
      paddingLeft: "10rem",
      paddingRight: "18rem",

      "@media(max-width: 1746px)": {
        paddingLeft: "5rem",
        paddingRight: "12rem",
      },

      "@media(max-width: 1536px)": {
        paddingTop: "5rem",
        paddingRight: "13rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "0",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginBottom: "2rem",
      },
    },

    imagenes: {
      maxWidth: "700px",

      "@media(max-width: 1536px)": {
        maxWidth: "550px",
      },

      "@media(max-width: 1200px)": {
        maxWidth: "400px",
      },

      "@media(max-width: 600px)": {
        maxWidth: "270px",
      },

      "@media(max-width: 300px)": {
        maxWidth: "220px",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={1}
        menuItem={3}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} lg={6} pb={5}>
          <Box component="div" sx={styles.contenedorArbitrajePROBonos}>
            <Box component="div" sx={styles.contenedorTitulo}>
              <Typography
                variant="h1"
                mr={1}
                color="#1C3254"
                sx={styles.titulo}
              >
               {idiomaSeleccionado.pro_bono_arbitration_cna[0]}
              </Typography>
              <Typography
                variant="h1"
                color="#1C3254"
                mb={4}
                sx={styles.titulo}
              >
               {idiomaSeleccionado.pro_bono_arbitration_cna[1]}
              </Typography>
            </Box>
            <Typography
              mb={2}
              color="#172833"
              sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
            >
              {idiomaSeleccionado.pro_bono_arbitration_cna[2]}
            </Typography>
            <Typography
              color="#172833"
              sx={{
                fontFamily: "TitilliumWeb-Regular",
                fontSize: "1rem",
                ...styles.contenidoDesktop,
              }}
            >
              {idiomaSeleccionado.pro_bono_arbitration_cna[3]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} mb={2}>
          <Box component="div" sx={styles.contenedorImagenArbitrajePROBonos}>
            <Box
              component="img"
              src={Ilustracion}
              alt="Arbitraje PRO Bonos CNA Ilustración"
              // maxWidth="100%"
              sx={styles.imagenes}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} sx={styles.contenidoMobile}>
          <Typography
            color="#172833"
            pl="1rem"
            pr="1rem"
            mb="1rem"
            sx={{
              fontFamily: "TitilliumWeb-Regular",
              fontSize: "1rem",
            }}
          >
             {idiomaSeleccionado.pro_bono_arbitration_cna[3]}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} sx={styles.contenidoDesktop}>
          <Box component="div" sx={styles.contenedorImagenFundacionesCNA}>
            <Box
              component="img"
              src={Instituciones}
              alt="Alianza del CNA con fundaciones"
              maxWidth="100%"
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box component="div" sx={styles.contenedorFundacionesCNA}>
            <Typography
              color="#172833"
              sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
            >
              {idiomaSeleccionado.pro_bono_arbitration_cna[4]}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} sx={styles.contenidoMobile}>
          <Box component="div" sx={styles.contenedorImagenFundacionesCNA}>
            <Box
              component="img"
              src={Instituciones}
              alt="Alianza del CNA con fundaciones"
              // maxWidth="40%"
              sx={styles.imagenes}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProBondArbitration;
