import React from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import Download from "../../assets/imgs/Regulations/icon_download.webp";
import Reglamento2017 from "../../assets/documents/Reglamento-CNA-2017.pdf";
import Reglamento2020 from "../../assets/documents/REGLAMENTO-ARBITRAL-2020.pdf";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const Regulations = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const descargarDocumento = (value) => {
    const link = document.createElement("a");

    switch (value) {
      case 2017:
        link.download = `Reglamento-CNA-2017.pdf`;
        link.href = Reglamento2017;
        link.click();
        break;

      case 2020:
        link.download = `REGLAMENTO-ARBITRAL-2020.pdf`;
        link.href = Reglamento2020;
        link.click();
        break;

      default:
        break;
    }
  };

  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5vh",
      paddingBottom: "15vh",
    },

    titulo: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "2vh",

      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
    },

    subtitulo: {
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      marginBottom: "2vh",
    },

    contenedorReglamento2020: {
      paddingRight: "0.5rem",

      "@media(max-width: 960px)": {
        paddingRight: 0,
      },
    },

    contenedorReglamento2017: {
      paddingLeft: "0.5rem",

      "@media(max-width: 960px)": {
        paddingLeft: 0,
      },
    },

    tarjetaReglamentos: {
      backgroundColor: "#FFFFFF",
      borderRadius: "6px",
      boxShadow: "0px 3px 6px #42949C7E",
      height: "400px",
      width: "370px",

      "@media(max-width: 960px)": {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "5vh",
      },

      "@media(max-width: 600px)": {
        height: "300px",
        width: "300px",
      },

      "@media(max-width: 340px)": {
        width: "230px",
      },
    },

    iconDescarga: {
      width: "110px",
      marginBottom: "3vh",

      "@media(max-width: 600px)": {
        width: "60px",
      },
    },

    labelReglamentoArbitral: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "1.5rem",

      "@media(max-width: 600px)": {
        fontSize: "1.2rem",
      },
    },

    labelAnioReglamento: {
      color: "#4E87AE",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4.4rem",

      "@media(max-width: 600px)": {
        fontSize: "2.5rem",
      },
    },

    labelVigente: {
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      padding: "0.5vh",
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={3}
        menuItem={7}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} textAlign="center">
          <Box component="div">
            <Typography sx={styles.titulo} variant="h1">
              {idiomaSeleccionado.regulations_cna[0]}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box component="div" sx={{ marginBottom: "4vh" }}>
            <Typography
              color="#172833"
              sx={{
                fontFamily: "TitilliumWeb-Regular",
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              {idiomaSeleccionado.regulations_cna[1]}
              {/* <Box
                component="span"
                color="#4E87AE"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem", marginLeft: "5px", marginRight: "5px" }}
              >
                la descarga
              </Box> */}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={styles.contenedorReglamento2020}>
          <Box
            component="div"
            sx={{
              ...styles.tarjetaReglamentos,
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <Link
              underline="none"
              onClick={() => descargarDocumento(2020)}
              sx={{ cursor: "pointer" }}
            >
              <Box
                component="div"
                sx={{
                  backgroundColor: "#D4E7F4",
                  textAlign: "center",
                  width: "128px",
                  marginLeft: "auto",
                  marginRight: 0,
                  borderBottomLeftRadius: "6px",
                }}
              >
                <Typography sx={styles.labelVigente}>
                  {idiomaSeleccionado.regulations_cna[3]}
                </Typography>
              </Box>
              <Box
                component="div"
                textAlign="center"
                sx={{ paddingTop: "5vh", paddingBottom: "5vh" }}
              >
                <Box
                  component="img"
                  src={Download}
                  alt="Icono descarga de reglamento arbitral"
                  sx={styles.iconDescarga}
                />
                <Typography sx={styles.labelReglamentoArbitral}>
                  {idiomaSeleccionado.regulations_cna[2]}
                </Typography>
                <Typography sx={styles.labelAnioReglamento}>2020</Typography>
              </Box>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={styles.contenedorReglamento2017}>
          <Box component="div" sx={styles.tarjetaReglamentos}>
            <Link
              underline="none"
              onClick={() => descargarDocumento(2017)}
              sx={{ cursor: "pointer" }}
            >
              <Box
                component="div"
                textAlign="center"
                sx={{ paddingTop: "8.6vh", paddingBottom: "5vh" }}
              >
                <Box
                  component="img"
                  src={Download}
                  alt="Icono descarga de reglamento arbitral"
                  sx={styles.iconDescarga}
                />
                <Typography sx={styles.labelReglamentoArbitral}>
                  {idiomaSeleccionado.regulations_cna[2]}
                </Typography>
                <Typography sx={styles.labelAnioReglamento}>2017</Typography>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Regulations;
