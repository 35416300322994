import React, { useState } from "react";
import { Grid, Typography, Box, Button, Dialog , DialogTitle , DialogContent , DialogContentText , DialogActions, Alert, AlertTitle } from "@mui/material";
import Poligono from "../../assets/icons/FrequentQuestions/poligono.svg";
// import Warning from "../../assets/imgs/ArbitrationForm/warning.png";

const Introduction = ({
  handleStartForm,
  idiomaSeleccionado,
  setIdiomaSeleccionado,
}) => {

  const [open, setOpen] = useState(false);
  const [mantencion, setMantencion] = useState(false);
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "10rem",
    },

    contenedorTexto: {
      paddingLeft: "10rem",
      paddingRight: "10rem",

      "@media(max-width: 1746px)": {
        // paddingTop: "3rem",
        paddingLeft: "11rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 1536px)": {
        paddingTop: "0",
        paddingLeft: "5rem",
      },

      "@media(max-width: 1200px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorTitulo: {
      display: "block",
      "@media(max-width: 1200px)": {
        display: "flex",
        justifyContent: "center",
      },
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4rem",
      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 299px)": {
        fontSize: "1.5rem",
      },
    },

    contenedorIframe: {
      position: "relative",
      paddingBottom: "30%",
      height: "0",
      
      "@media(max-width: 1200px)": {
        paddingBottom: "60%",
      },
    },

    contenedorBoton: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    button: {
      backgroundColor: "#4E87AE",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "220px",
    },

    //style modal
    tituloModal: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",
      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 299px)": {
        fontSize: "2rem",
      },
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container sx={styles.contenedorGeneral}>
      <Grid item xs={12} lg={5} pb={5}>
        <Box component="div" sx={styles.contenedorTexto}>
          <Box component="div" sx={styles.contenedorTitulo}>
            <Typography variant="h1" mr={1} color="#1C3254" sx={styles.titulo}>
              {idiomaSeleccionado.introduction_form_cna[0]}
            </Typography>
            <Typography variant="h1" color="#1C3254" mb={4} sx={styles.titulo}>
              {idiomaSeleccionado.introduction_form_cna[1]}
            </Typography>
          </Box>
          <Box component="div">
            <Typography
              mb={3}
              color="#172833"
              sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
            >
              {idiomaSeleccionado.introduction_form_cna[2]}
            </Typography>
            <Typography
              mb={3}
              color="#172833"
              sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
            >
              {idiomaSeleccionado.introduction_form_cna[3]}
            </Typography>
          </Box>
          <Box component="div" mb={3} sx={styles.contenedorBoton}>
            <Button
              variant="contained"
              sx={styles.button}
              onClick={()=>{
                handleClickOpen();
              }}
              disabled={false}
              //style={{display:"none"}}
            >
              {idiomaSeleccionado.introduction_form_cna[4]}
            </Button>
          </Box>
          {/* <Box component="div" mb={3} sx={styles.contenedorBoton}>
            <Typography
              color="red"
              fontFamily="TitilliumWeb-SemiBold"
              fontSize="1.2rem"
            >
              Estamos realizando actualizaciones, por el momento no se podra acceder al formulario. Disculpen las molestias.
            </Typography>
            <br/>
            <Typography
              color="red"
              fontFamily="TitilliumWeb-SemiBold"
              fontSize="1.2rem"
            >
              Pueden contactarse por correo a contacto@cna.cl
            </Typography>
          </Box> */}
          <Box component="div">
            <Box component="div" mb={3}>
              <Typography
                color="#1C3254"
                fontFamily="TitilliumWeb-SemiBold"
                fontSize="1.2rem"
              >
                {idiomaSeleccionado.introduction_form_cna[5]}
              </Typography>
            </Box>
            <Box component="div" display="flex">
              <Box component="img" src={Poligono} alt="Poligono" mr={1} />
              <Typography
                color="#172833"
                fontFamily="TitilliumWeb-Regular"
                fontSize="1rem"
              >
                {idiomaSeleccionado.introduction_form_cna[6]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle 
           variant="h3" mr={1} color="#1C3254" sx={styles.tituloModal}
        >
          {idiomaSeleccionado.introduction_form_cna[9]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText 
             color="#172833"
             sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1.5rem" }}
          >
          {idiomaSeleccionado.introduction_form_cna[10]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{idiomaSeleccionado.introduction_form_cna[11]}</Button>
          <Button onClick={()=>{
            handleStartForm();
          }} autoFocus>
          {idiomaSeleccionado.introduction_form_cna[12]}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mantencion}
        onClose={()=> setMantencion(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        style={{
          paddingTop: '80px',
          paddingBottom: '80px'
        }}
      >
        <DialogTitle 
           variant="h3" mr={1} color="#1C3254" sx={styles.tituloModal} style={{textAlign:'center',marginTop: '80px'}}
        >
          Página en mantención
        </DialogTitle>
        <DialogContent>
          <DialogContentText 
             color="#172833"
             sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1.5rem", textAlign: 'center' }}
          >
           Enviar sus consultas a contacto@cna.cl
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center",marginBottom: '80px' }} >
          <Button 
            onClick={()=>{
              setMantencion(false);
            }} 
            autoFocus
          >
          Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Grid item xs={12} lg={6} sx={styles.contenedorIframe}>
        <Box component="div" textAlign="center">
          <Box
            component="img"
            src={Warning}
            alt="Video de formulario de arbitraje del CNA (Centro Nacional De Arbitrajes) no disponible"
            width="50%"
          />

          <Typography fontSize="1rem" fontFamily="TitilliumWeb-Regular">
            {idiomaSeleccionado.introduction_form_cna[7]}
          </Typography>
          <Typography fontSize="1rem" fontFamily="TitilliumWeb-Regular">
            {idiomaSeleccionado.introduction_form_cna[8]}
          </Typography>
        </Box>
      </Grid> */}

      <Grid item xs={12} lg={6} sx={styles.contenedorIframe}>
        <Box
          component="div"
          dangerouslySetInnerHTML={{
            __html:
              "<iframe style='width: 100%; height: 100%; border: none; position: absolute;' src='https://www.youtube.com/embed/f9dYa615sHs' />",
          }}
        />
      </Grid>

    </Grid>
  );
};

export default Introduction;
