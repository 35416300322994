import axiosClient from "../config/axios";

export const enviarContacto = async (obj, idiomaSeleccionado) => {
  let status;
  let icon;
  let title;
  let message;
  try {
    let response = await axiosClient.post(`/Correo/Consulta_Confirmada`, obj);
    switch (response.status) {
      case 201:
        status = response.status;
        icon = "success";
        title = idiomaSeleccionado.validations_contact_cna[7];
        message = idiomaSeleccionado.validations_contact_cna[8];
        break;
      default:
        status = response.status;
        icon = "error";
        title = idiomaSeleccionado.validations_contact_cna[9];
        message = idiomaSeleccionado.validations_contact_cna[10];
        break;
    }
    return { icon, title, message, status };
  } catch (error) {
    icon = "error";
    title = idiomaSeleccionado.validations_contact_cna[11];
    message = idiomaSeleccionado.validations_contact_cna[12];

    return { icon, title, message };
  }
};
