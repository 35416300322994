import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Logo_CNA from "../../assets/imgs/AboutUs/logo_cna.webp";

import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const AboutOfCNA = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },

    contenedorTextos: {
      paddingLeft: "10rem",
      marginTop: "auto",
      marginBottom: "auto",

      "@media(max-width: 1536px)": {
        paddingLeft: "5rem",
      },

      "@media(max-width: 1199px)": {
        paddingLeft: "0",
        marginBottom: "1rem",
      },
    },

    titulo: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",

      "@media(max-width: 1199px)": {
        textAlign: "center",
      },
    },

    subtitulo: {
      color: "#172833",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",

      "@media(max-width: 1199px)": {
        paddingLeft: "5vh",
        paddingRight: "5vh",
      },
    },

    contenedorLogoCNA: {
      textAlign: "center",

      "@media(max-width: 1199px)": {
        marginBottom: "1rem",
      },

      "@media(max-width: 600px)": {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      },
    },

    logoCNA: {
      width: "60%",

      "@media(max-width: 600px)": {
        width: "70%",
      },

      "@media(max-width: 375px)": {
        width: "80%",
      },
    },

    subtitulo2: {
      "@media(max-width: 1199px)": {
        display: "none",
      },
    },

    contenedorTextoMobile: {
      display: "none",

      "@media(max-width: 1199px)": {
        display: "block",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={1}
        menuItem={1}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} lg={5} sx={styles.contenedorTextos}>
          <Typography sx={styles.titulo} variant="h1" mb={3}>
            CNA
          </Typography>
          <Typography sx={styles.subtitulo} mb={3}>
            {idiomaSeleccionado.us_cna[0]}
          </Typography>
          <Typography sx={{ ...styles.subtitulo, ...styles.subtitulo2 }} mb={3}>
            {idiomaSeleccionado.us_cna[1]}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7} sx={styles.contenedorLogoCNA}>
          <Box component="div">
            <Box
              component="img"
              src={Logo_CNA}
              alt="Logo del CNA (Centro Nacional De Arbitrajes)"
              sx={styles.logoCNA}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={5} sx={styles.contenedorTextoMobile}>
          <Typography sx={styles.subtitulo}>
            {idiomaSeleccionado.us_cna[1]}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutOfCNA;
