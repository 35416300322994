import React from "react";
import {
  Grid,
  Box,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Poligono from "../../assets/icons/FrequentQuestions/poligono.svg";
import NavbarMenu from "../../components/NavbarMenu";

const FrequentQuestions = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3.5rem",
      "@media(max-width: 600px)": {
        fontSize: "2.3rem",
      },
    },

    contenedorImagen: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    columna: {
      paddingLeft: "1rem",
      paddingRight: "5rem",
      "@media(max-width: 600px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },

      "@media(max-width: 300px)": {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      },
    },

    imagen: {
      width: "340px",

      "@media(max-width: 1536px)": {
        width: "300px",
      },

      "@media(max-width: 600px)": {
        width: "240px",
      },

      "@media(max-width: 300px)": {
        width: "140px",
      },
    },
  };
  return (
    <Box component="div">
      <NavbarMenu
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Box component="div" sx={styles.contenedorGeneral}>
        <Box component="div" textAlign="center">
          <Typography variant="h1" color="#1C3254" mb={2} sx={styles.titulo}>
            {idiomaSeleccionado.frequent_questions_cna[0]}
          </Typography>
        </Box>

        <Box component="div" padding={5}>
          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.frequent_questions_cna[1]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={3}>
                  <Grid item xs={12} sx={styles.columna}>
                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[2]}
                    </Typography>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[3]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[4]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[5]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[6]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[7]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[8]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[9]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[10]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex">
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[11]}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.frequent_questions_cna[12]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={3}>
                  <Grid item xs={12} sx={styles.columna}>
                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[13]}
                    </Typography>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[14]}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex" mb={1}>
                      <Box
                        component="img"
                        src={Poligono}
                        alt="Poligono"
                        mr={1}
                        height="25px"
                        width="10px"
                      />
                      <Typography
                        color="#172833"
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.frequent_questions_cna[15]}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.frequent_questions_cna[16]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={3}>
                  <Grid item xs={12} sx={styles.columna}>
                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[17]}
                    </Typography>

                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[18]}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.frequent_questions_cna[19]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={3}>
                  <Grid item xs={12} sx={styles.columna}>
                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[20]}
                    </Typography>

                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[21]}
                    </Typography>

                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[22]}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box component="div" mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
              >
                <Typography
                  color="#1C3254"
                  pl={2}
                  sx={{
                    fontFamily: "TitilliumWeb-SemiBold",
                    fontSize: "1.3rem",
                  }}
                >
                  {idiomaSeleccionado.frequent_questions_cna[23]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pt={3}>
                  <Grid item xs={12} sx={styles.columna}>
                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                       {idiomaSeleccionado.frequent_questions_cna[24]}
                    </Typography>

                    <Typography
                      color="#172833"
                      mb={2}
                      sx={{
                        fontFamily: "TitilliumWeb-Regular",
                        fontSize: "1rem",
                      }}
                    >
                      {idiomaSeleccionado.frequent_questions_cna[25]}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FrequentQuestions;
