import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";

import NavbarMenu from "../../components/NavbarMenu";
import Transbank_Failed from "../../assets/imgs/TransbankFailed/transbank_failed.svg";

const TransbankFailed = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },

    contenedorTextos: {
      textAlign: "center",
    },

    titulo: {
      color: "#1C3254",
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "3rem",

      "@media(max-width: 600px)": {
        fontSize: "2.5rem",
      },

      "@media(max-width: 300px)": {
        fontSize: "2rem",
      },
    },

    subtitulo: {
      color: "#172833",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
    },

    contenedorImagen: {
      textAlign: "center",

      "@media(max-width: 600px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    imagen: {
      width: "230px",

      "@media(max-width: 600px)": {
        width: "200px",
      },

      "@media(max-width: 300px)": {
        width: "150px",
      },
    },

    button: {
      backgroundColor: "#4E87AE",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      textTransform: "none",
      width: "250px",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} sx={styles.contenedorImagen} mb={3}>
          <Box component="div">
            <Box
              component="img"
              src={Transbank_Failed}
              alt="Icono operación fallida del pago de arbitraje del CNA"
              sx={styles.imagen}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={styles.contenedorTextos}>
          <Typography sx={styles.titulo} variant="h1" mb={1}>
            {idiomaSeleccionado.transbank_failed[0]}
          </Typography>
          <Typography sx={styles.subtitulo} mb={3}>
            {idiomaSeleccionado.transbank_failed[1]}
          </Typography>
          <Button
            variant="contained"
            disableElevation
            sx={styles.button}
            href="/formulario_arbitraje"
          >
            {idiomaSeleccionado.transbank_failed[2]}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransbankFailed;
