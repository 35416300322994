import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Collapse,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Link,
} from "@mui/material";

import es from "./../locales/es.json";
import en from "./../locales/en.json";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import Logo_NavbarMenu_CNA from "../assets/imgs/Navbar/logo_cna_navbar.webp";

import Icon_Espanol from "../assets/icons/Navbar/icon_bandera_chile.webp";
import Icon_English from "../assets/icons/Navbar/icon_bandera_usa.webp";

const drawerWidth = 240;
const MUIAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const NavbarMobile = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openNosotros, setOpenNosotros] = useState(false);
  const [openProcedimientos, setOpenProcedimientos] = useState(false);
  const [openDocumentacion, setOpenDocumentacion] = useState(false);
  const [openTarifas, setOpenTarifas] = useState(false);
  const [openLenguaje, setOpenLenguaje] = useState(false);
  const [cambiarIdioma, setCambiarIdioma] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCollapse = (item) => {
    switch (item) {
      case "Nosotros":
      case "Us":
        setOpenNosotros(openNosotros === true ? false : true);
        break;

      case "Procedimientos":
      case "Procedures":
        setOpenProcedimientos(openProcedimientos === true ? false : true);
        break;

      case "Documentación":
      case "Documentation":
        setOpenDocumentacion(openDocumentacion === true ? false : true);
        break;

      case "Tarifas":
      case "Tariffs":
        setOpenTarifas(openTarifas === true ? false : true);
        break;

      default:
        break;
    }
  };

  const handleCollapseLenguaje = () => {
    setOpenLenguaje(openLenguaje === true ? false : true);
  };

  const handleChangeLanguage = async (idioma) => {
    if (idioma !== idiomaSeleccionado) {
      switch (idiomaSeleccionado) {
        case es:
          setIdiomaSeleccionado(en);
          setCambiarIdioma(false);
          await localStorage.setItem("language", "en");
          break;

        case en:
          setIdiomaSeleccionado(es);
          setCambiarIdioma(false);
          await localStorage.setItem("language", "es");
          break;

        default:
          setIdiomaSeleccionado(es);
          setCambiarIdioma(false);
          await localStorage.setItem("language", "es");
          break;
      }
    }
  };

  const tabs = [
    {
      name: idiomaSeleccionado.menu[0],
      items: [
        {
          link: "/nosotros/cna",
          name: idiomaSeleccionado.sub_menu[0],
        },
        {
          link: "/nosotros/equipo",
          name: idiomaSeleccionado.sub_menu[1],
        },
        {
          link: "/nosotros/arbitraje_pro_bonos",
          name: idiomaSeleccionado.sub_menu[2],
        },
      ],
    },
    {
      name: idiomaSeleccionado.menu[1],
      items: [
        {
          link: "/procedimientos/servicios",
          name: idiomaSeleccionado.sub_menu[3],
        },
        {
          link: "/procedimientos/como_trabajamos",
          name: idiomaSeleccionado.sub_menu[4],
        },
      ],
    },
    {
      name: idiomaSeleccionado.menu[2],
      items: [
        {
          link: "/documentacion/clausulas",
          name: idiomaSeleccionado.sub_menu[5],
        },
        {
          link: "/documentacion/reglamentos",
          name: idiomaSeleccionado.sub_menu[6],
        },
        {
          link: "/documentacion/otros_documentos",
          name: idiomaSeleccionado.sub_menu[7],
        },
      ],
    },
    {
      name: idiomaSeleccionado.menu[3],
      items: [
        {
          link: "/tarifas/tabla_tarifas",
          name: idiomaSeleccionado.sub_menu[8],
        },
        {
          link: "/tarifas/simulador_tarifas",
          name: idiomaSeleccionado.sub_menu[9],
        },
      ],
    },
  ];

  return (
    <Box component="div" width="100%">
      <MUIAppBar position="static" open={open}>
        <Toolbar sx={{ backgroundColor: "#FFFFFF" }}>
          <Link href="/home" underline="none" color="#172833">
            <Box
              component="img"
              src={Logo_NavbarMenu_CNA}
              alt="Logo CNA (Centro Nacional De Arbitrajes)"
              sx={{ width: "50px", height: "34px" }}
            />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ marginLeft: "auto", ...(open && { display: "none" }) }}
          >
            <MoreVertOutlinedIcon sx={{ color: "blue" }} />
          </IconButton>
        </Toolbar>
      </MUIAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {tabs.map((tab, index) => (
            <Box component="div" key={tab.name}>
              <ListItem key={tab.name} disablePadding>
                <ListItemButton onClick={() => handleCollapse(tab.name)}>
                  <ListItemText>
                    <Typography
                      fontFamily="TitilliumWeb-Regular"
                      fontSize="1rem"
                    >
                      {tab.name}
                    </Typography>
                  </ListItemText>
                  {tab.name === "Nosotros" || tab.name === "Us" ? (
                    openNosotros ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : tab.name === "Procedimientos" ||
                    tab.name === "Procedures" ? (
                    openProcedimientos ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : tab.name === "Documentación" ||
                    tab.name === "Documentation" ? (
                    openDocumentacion ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : openTarifas ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>

              {tab.items.map((item) => (
                <Collapse
                  in={
                    tab.name === "Nosotros" || tab.name === "Us"
                      ? openNosotros
                      : tab.name === "Procedimientos" ||
                        tab.name === "Procedures"
                      ? openProcedimientos
                      : tab.name === "Documentación" ||
                        tab.name === "Documentation"
                      ? openDocumentacion
                      : openTarifas
                  }
                  timeout="auto"
                  unmountOnExit
                  key={item.name}
                >
                  <Link href={item.link} underline="none" color="#172833">
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText>
                          <Typography
                            fontFamily="TitilliumWeb-Regular"
                            fontSize="1rem"
                          >
                            {item.name}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </List>
                  </Link>
                </Collapse>
              ))}
            </Box>
          ))}

          <Link href="/e-xpediente" underline="none" color="#172833">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText>
                  <Typography fontFamily="TitilliumWeb-Regular" fontSize="1rem">
                    E-xpediente Virtual
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>

          <Link href="/contacto" underline="none" color="#172833">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText>
                  <Typography fontFamily="TitilliumWeb-Regular" fontSize="1rem">
                    {idiomaSeleccionado.menu[4]}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>

          <Link href="/formulario_arbitraje" underline="none" color="#172833">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText>
                  <Typography fontFamily="TitilliumWeb-Regular" fontSize="1rem">
                    {idiomaSeleccionado.menu[5]}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>

          <Box component="div">
            <ListItem disablePadding>
              <ListItemButton onClick={handleCollapseLenguaje}>
                <ListItemText>
                  <Typography fontFamily="TitilliumWeb-Regular" fontSize="1rem">
                    {idiomaSeleccionado.menu[6]}
                  </Typography>
                </ListItemText>
                {openLenguaje ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>

            <Collapse in={openLenguaje} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleChangeLanguage(es)}
                >
                  <ListItemText>
                    <Box component="div" display="flex">
                      <Box
                        component="img"
                        src={Icon_Espanol}
                        alt="Icono Bandera De Chile"
                        height={12}
                        width={12}
                        mt={1}
                        mr={2}
                      />
                      <Typography
                        fontFamily="TitilliumWeb-Regular"
                        fontSize="1rem"
                      >
                        Español
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItemButton>
              </List>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleChangeLanguage(en)}
                >
                  <ListItemText>
                    <Box component="div" display="flex">
                      <Box
                        component="img"
                        src={Icon_English}
                        alt="Icono Bandera De Estados Unidos"
                        height={12}
                        width={12}
                        mt={1}
                        mr={2}
                      />
                      <Typography
                        fontFamily="TitilliumWeb-Regular"
                        fontSize="1rem"
                      >
                        English
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          </Box>
        </List>
      </Drawer>
    </Box>
  );
};

export default NavbarMobile;
