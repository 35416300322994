import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  FormHelperText,
} from "@mui/material";

import Poligono from "../../assets/icons/FrequentQuestions/poligono.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Countries from "../../assets/JSON/Countries";
import RegionsAndCommunes from "../../assets/JSON/RegionsAndCommunes";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#172833",
    borderColor: "#0F7099",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",

    "& fieldset": {
      borderColor: "#0F7099",
    },
    "&:hover fieldset": {
      borderColor: "#0F7099",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0F7099",
    },
  },
}));

const FirstFormContent = ({
  handleChangeStage,
  formValuesFirstStage,
  setFormValuesFirstStage,
  errorsFirstStage,
  setErrorsFirstStage,
  idiomaSeleccionado,
  setIdiomaSeleccionado,
}) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    let items = [...formValuesFirstStage.SOLICITANTES];
    let item = { ...items[index] };

    switch (name) {
      case "NOMBRE_COMPLETO":
        item.NOMBRE_COMPLETO = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });

        break;

      case "RUT":
        item.RUT = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      case "CORREO":
        item.CORREO = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });

        break;

      case "CONFIRMAR_CORREO":
        item.CONFIRMAR_CORREO = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });

        break;

      case "PAIS":
        if (value !== "CL") {
          item.REGION = "";
          item.COMUNAS = "";
          item.COMUNA_SELECCIONADA = "";
        }

        item.PAIS = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });

        break;

      case "REGION":
        item.COMUNAS = RegionsAndCommunes.regiones.filter(
          (region) => region.NombreRegion.toString() === value.toString()
        )[0].comunas;

        item.REGION = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      case "COMUNAS":
        item.COMUNAS = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      case "COMUNA_SELECCIONADA":
        item.COMUNA_SELECCIONADA = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      case "DIRECCION":
        item.DIRECCION = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      case "TIPO_DOMICILIO":
        item.TIPO_DOMICILIO = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      case "CONTACTO":
        item.CONTACTO = value;
        items[index] = item;

        setFormValuesFirstStage({
          ...formValuesFirstStage,
          SOLICITANTES: items,
        });
        break;

      default:
        setFormValuesFirstStage({
          ...formValuesFirstStage,
          [name]: value,
        });
        break;
    }
  };

  const handleAgregarSolicitante = () => {
    const cantidadSolicitantes =
      formValuesFirstStage.SOLICITANTES &&
      formValuesFirstStage.SOLICITANTES.length;
    let items = { ...formValuesFirstStage };

    setFormValuesFirstStage({
      ...items,
      SOLICITANTES: [
        ...items.SOLICITANTES,
        {
          INDEX: cantidadSolicitantes,
          NOMBRE_COMPLETO: "",
          RUT: "",
          CORREO: "",
          CONFIRMAR_CORREO: "",
          PAIS: "CL",
          REGION: "",
          COMUNAS: "",
          COMUNA_SELECCIONADA: "",
          DIRECCION: "",
          TIPO_DOMICILIO: "",
          CONTACTO: "",
        },
      ],
    });
  };

  const handleValidate = () => {
    let err = {};

    let errorsSolicitantes = [];

    formValuesFirstStage.SOLICITANTES.forEach((solicitante, index) => {
      let errSolicitante = {};
      errSolicitante.INDEX = index;
      errSolicitante.NOMBRE_COMPLETO =
        solicitante.NOMBRE_COMPLETO !== ""
          ? undefined
          : idiomaSeleccionado.validations_first_stage_form_cna[0];

      errSolicitante.RUT =
        solicitante.RUT !== ""
          ? !/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(solicitante.RUT)
            ? idiomaSeleccionado.validations_first_stage_form_cna[2]
            : undefined
          : idiomaSeleccionado.validations_first_stage_form_cna[1];

      errSolicitante.CORREO =
        solicitante.CORREO !== ""
          ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
              solicitante.CORREO
            )
            ? undefined
            : idiomaSeleccionado.validations_first_stage_form_cna[4]
          : idiomaSeleccionado.validations_first_stage_form_cna[3];

      errSolicitante.CONFIRMAR_CORREO =
        solicitante.CONFIRMAR_CORREO !== ""
          ? /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
              solicitante.CONFIRMAR_CORREO
            )
            ? solicitante.CONFIRMAR_CORREO === solicitante.CORREO
              ? undefined
              : idiomaSeleccionado.validations_first_stage_form_cna[5]
            : idiomaSeleccionado.validations_first_stage_form_cna[4]
          : idiomaSeleccionado.validations_first_stage_form_cna[3];

      errSolicitante.DIRECCION =
        solicitante.DIRECCION !== "" ? undefined : idiomaSeleccionado.validations_first_stage_form_cna[6];

      errSolicitante.CONTACTO =
        solicitante.CONTACTO !== ""
          ? undefined
          : idiomaSeleccionado.validations_first_stage_form_cna[7];

      errSolicitante.REGION =
        solicitante.PAIS === "CL"
          ? solicitante.REGION !== ""
            ? undefined
            : idiomaSeleccionado.validations_first_stage_form_cna[8]
          : "";

      errSolicitante.COMUNA_SELECCIONADA =
        solicitante.PAIS === "CL" && solicitante.REGION !== ""
          ? solicitante.COMUNA_SELECCIONADA !== ""
            ? undefined
            : idiomaSeleccionado.validations_first_stage_form_cna[9]
          : "";

      if (
        !errSolicitante.NOMBRE_COMPLETO &&
        !errSolicitante.RUT &&
        !errSolicitante.CORREO &&
        !errSolicitante.CONFIRMAR_CORREO &&
        !errSolicitante.DIRECCION &&
        !errSolicitante.CONTACTO &&
        !errSolicitante.REGION &&
        !errSolicitante.COMUNA_SELECCIONADA
      ) {
        delete errSolicitante.INDEX;
        delete errSolicitante.NOMBRE_COMPLETO;
        delete errSolicitante.RUT;
        delete errSolicitante.CORREO;
        delete errSolicitante.CONFIRMAR_CORREO;
        delete errSolicitante.DIRECCION;
        delete errSolicitante.CONTACTO;
        delete errSolicitante.REGION;
        delete errSolicitante.COMUNA_SELECCIONADA;
      }

      if (Object.keys(errSolicitante).length > 0) {
        errorsSolicitantes.push(errSolicitante);
      }
    });

    err.ERRORS_SOLICITANTES =
      errorsSolicitantes.length > 0 ? errorsSolicitantes : "";

    err.CIRCUNSTANCIA_INTERNACIONAL =
      formValuesFirstStage.TIPO_ARBITRAJE === "Nacional"
        ? ""
        : formValuesFirstStage.CIRCUNSTANCIA_INTERNACIONAL.length > 0
        ? ""
        : idiomaSeleccionado.validations_first_stage_form_cna[10];

    setErrorsFirstStage({
      ...err,
    });

    return Object.values(err).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handleValidate()) {
      handleChangeStage(2);
    }
  };

  const styles = {
    inputSize: {
      width: "550px",
      "@media(max-width: 1810px)": {
        width: "350px",
      },

      "@media(max-width: 400px)": {
        width: "300px",
      },

      "@media(max-width: 340px)": {
        width: "220px",
      },
    },

    button: {
      backgroundColor: "#4E87AE",
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "220px",
    },
  };

  return (
    <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
      {formValuesFirstStage.SOLICITANTES.length > 0 &&
        formValuesFirstStage.SOLICITANTES.map((solicitante, index) => (
          <>
            <Grid item xs={12} key={solicitante.INDEX}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
                >
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.first_stage_form_cna[0]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pl={2} spacing={5} pt={2} pb={2}>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[1]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.first_stage_form_cna[2]
                          }
                          name="NOMBRE_COMPLETO"
                          value={solicitante.NOMBRE_COMPLETO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index]
                              .NOMBRE_COMPLETO && {
                              error: true,
                              helperText:
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .NOMBRE_COMPLETO,
                            })}
                        />
                      </Box>
                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[3]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder="XXXXXXXX-X"
                          name="RUT"
                          value={solicitante.RUT}
                          sx={styles.inputSize}
                          inputProps={{
                            maxLength: 10,
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index].RUT && {
                              error: true,
                              helperText:
                                errorsFirstStage.ERRORS_SOLICITANTES[index].RUT,
                            })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[4]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.first_stage_form_cna[5]
                          }
                          name="CORREO"
                          value={solicitante.CORREO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index]
                              .CORREO && {
                              error: true,
                              helperText:
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .CORREO,
                            })}
                        />
                      </Box>
                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[6]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.first_stage_form_cna[7]
                          }
                          name="CONFIRMAR_CORREO"
                          value={solicitante.CONFIRMAR_CORREO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index]
                              .CONFIRMAR_CORREO && {
                              error: true,
                              helperText:
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .CONFIRMAR_CORREO,
                            })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
                >
                  <Typography
                    color="#4E87AE"
                    pl={2}
                    sx={{
                      fontFamily: "TitilliumWeb-SemiBold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {idiomaSeleccionado.first_stage_form_cna[8]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container pl={2} spacing={5} pt={2} pb={2}>
                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            <Typography
                              sx={{
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              }}
                            >
                              {idiomaSeleccionado.first_stage_form_cna[9]}
                            </Typography>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="PAIS"
                            value={solicitante.PAIS}
                            label="País*"
                            onChange={(e) => handleInputChange(e, index)}
                            sx={styles.inputSize}
                            inputProps={{
                              style: {
                                fontFamily: "TitilliumWeb-Regular",
                                fontSize: "1rem",
                              },
                            }}
                          >
                            {Countries.map((country) => (
                              <MenuItem
                                value={country.value}
                                key={country.value}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "TitilliumWeb-Regular",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {country.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {solicitante.PAIS === "CL" && (
                        <Box component="div" mb={5}>
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              <Typography
                                sx={{
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                }}
                                {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                                  errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                                  errorsFirstStage.ERRORS_SOLICITANTES[index]
                                    .REGION && {
                                    color: "#d32f2f",
                                  })}
                              >
                                {idiomaSeleccionado.first_stage_form_cna[10]}
                              </Typography>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="REGION"
                              value={solicitante.REGION}
                              label="Región *"
                              onChange={(e) => handleInputChange(e, index)}
                              sx={styles.inputSize}
                              inputProps={{
                                style: {
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                },
                              }}
                              {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                                errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .REGION && {
                                  error: true,
                                })}
                            >
                              {RegionsAndCommunes.regiones.map(
                                (region, index) => (
                                  <MenuItem
                                    value={region.NombreRegion}
                                    key={region.NombreRegion}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "TitilliumWeb-Regular",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {region.NombreRegion}
                                    </Typography>
                                  </MenuItem>
                                )
                              )}
                            </Select>

                            {errorsFirstStage.ERRORS_SOLICITANTES &&
                              errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                              errorsFirstStage.ERRORS_SOLICITANTES[index]
                                .REGION && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {
                                    errorsFirstStage.ERRORS_SOLICITANTES[index]
                                      .REGION
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                      )}

                      {solicitante.COMUNAS && solicitante.COMUNAS.length > 0 && (
                        <Box component="div">
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              <Typography
                                sx={{
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                }}
                                {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                                  errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                                  errorsFirstStage.ERRORS_SOLICITANTES[index]
                                    .COMUNA_SELECCIONADA && {
                                    color: "#d32f2f",
                                  })}
                              >
                                {idiomaSeleccionado.first_stage_form_cna[11]}
                              </Typography>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="COMUNA_SELECCIONADA"
                              value={solicitante.COMUNA_SELECCIONADA}
                              label="COMUNA *"
                              onChange={(e) => handleInputChange(e, index)}
                              sx={styles.inputSize}
                              inputProps={{
                                style: {
                                  fontFamily: "TitilliumWeb-Regular",
                                  fontSize: "1rem",
                                },
                              }}
                              {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                                errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .COMUNA_SELECCIONADA && {
                                  error: true,
                                })}
                            >
                              {solicitante.COMUNAS.map((comuna, index) => (
                                <MenuItem value={comuna} key={comuna}>
                                  <Typography
                                    sx={{
                                      fontFamily: "TitilliumWeb-Regular",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {comuna}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>

                            {errorsFirstStage.ERRORS_SOLICITANTES &&
                              errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                              errorsFirstStage.ERRORS_SOLICITANTES[index]
                                .COMUNA_SELECCIONADA && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {
                                    errorsFirstStage.ERRORS_SOLICITANTES[index]
                                      .COMUNA_SELECCIONADA
                                  }
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[12]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.first_stage_form_cna[13]
                          }
                          name="DIRECCION"
                          value={solicitante.DIRECCION}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index]
                              .DIRECCION && {
                              error: true,
                              helperText:
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .DIRECCION,
                            })}
                        />
                      </Box>

                      <Box component="div" mb={5}>
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[14]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder={
                            idiomaSeleccionado.first_stage_form_cna[15]
                          }
                          name="TIPO_DOMICILIO"
                          value={solicitante.TIPO_DOMICILIO}
                          sx={styles.inputSize}
                          inputProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Box>

                      <Box component="div">
                        <StyledTextField
                          id="outlined-basic"
                          label={idiomaSeleccionado.first_stage_form_cna[16]}
                          InputLabelProps={{
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          variant="outlined"
                          placeholder="+000 0000 0000"
                          name="CONTACTO"
                          value={solicitante.CONTACTO}
                          sx={styles.inputSize}
                          inputProps={{
                            maxLength: 12,
                            style: {
                              fontFamily: "TitilliumWeb-Regular",
                              fontSize: "1rem",
                            },
                          }}
                          onChange={(e) => handleInputChange(e, index)}
                          {...(errorsFirstStage.ERRORS_SOLICITANTES &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index] &&
                            errorsFirstStage.ERRORS_SOLICITANTES[index]
                              .CONTACTO && {
                              error: true,
                              helperText:
                                errorsFirstStage.ERRORS_SOLICITANTES[index]
                                  .CONTACTO,
                            })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        ))}

      <Box component="div" pl={3} pt={2} pb={2}>
        <Button
          sx={{
            fontFamily: "TitilliumWeb-SemiBoldItalic",
            fontSize: "1rem",
            color: "#7C9D1C",
          }}
          onClick={handleAgregarSolicitante}
          {...(formValuesFirstStage.SOLICITANTES &&
            formValuesFirstStage.SOLICITANTES.length > 9 && {
              disabled: true,
            })}
        >
          {formValuesFirstStage.SOLICITANTES &&
          formValuesFirstStage.SOLICITANTES.length <= 9
            ? idiomaSeleccionado.first_stage_form_cna[17]
            : idiomaSeleccionado.first_stage_form_cna[18]}
        </Button>
      </Box>

      <Grid item xs={12}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ boxShadow: "0px 3px 6px #42949C7E" }}
          >
            <Typography
              color="#4E87AE"
              pl={2}
              sx={{
                fontFamily: "TitilliumWeb-SemiBold",
                fontSize: "1.3rem",
              }}
            >
              {idiomaSeleccionado.first_stage_form_cna[19]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container pl={2} spacing={5} pt={2} pb={2}>
              <Grid item xs={12} lg={6}>
                <Box component="div" mb={5}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      <Typography
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.first_stage_form_cna[20]}
                      </Typography>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formValuesFirstStage.PROCEDIMIENTO}
                      label="Clase de arbitraje que se solicita"
                      onChange={handleInputChange}
                      sx={styles.inputSize}
                      name="PROCEDIMIENTO"
                    >
                      <MenuItem value="Arbitraje regular sugerido por el CNA">
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[21]}
                        </Typography>
                      </MenuItem>
                      <MenuItem value="Arbitraje abreviado sugerido por el CNA">
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[22]}
                        </Typography>
                      </MenuItem>
                      <MenuItem value="Arbitraje de Derecho">
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[23]}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value="Arbitraje con procedimiento a determinar por el propio
                          árbitro"
                      >
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[24]}
                        </Typography>
                      </MenuItem>
                      <MenuItem value="Otro procedimiento acordado por las partes">
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[25]}
                        </Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box component="div" display="flex" mb={2}>
                  <Box component="img" src={Poligono} alt="Poligono" mr={1} />
                  <Typography
                    color="#4E87AE"
                    fontSize="1.2rem"
                    fontFamily="TitilliumWeb-SemiBold"
                  >
                    {idiomaSeleccionado.first_stage_form_cna[26]}
                  </Typography>
                </Box>
                <Box component="div" ml={3} mb={5}>
                  <Typography
                    color="#172833"
                    fontFamily="TitilliumWeb-Regular"
                    fontSize="1rem"
                  >
                    {idiomaSeleccionado.first_stage_form_cna[27]}
                  </Typography>
                  <Typography
                    color="#172833"
                    fontFamily="TitilliumWeb-Regular"
                    fontSize="1rem"
                  >
                    {idiomaSeleccionado.first_stage_form_cna[28]}
                  </Typography>
                </Box>

                <Typography
                  color="#172833"
                  fontFamily="TitilliumWeb-Regular"
                  fontSize="1rem"
                >
                  {idiomaSeleccionado.first_stage_form_cna[29]}
                </Typography>

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={formValuesFirstStage.INSTANCIA}
                    name="INSTANCIA"
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="Sin doble instancia"
                      control={<Radio />}
                      label={
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[30]}
                        </Typography>
                      }
                      color="#172833"
                    />
                    <FormControlLabel
                      value="Con doble instancia"
                      control={<Radio />}
                      label={
                        <Typography
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[31]}
                        </Typography>
                      }
                      color="#172833"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box component="div" mb={5}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      <Typography
                        sx={{
                          fontFamily: "TitilliumWeb-Regular",
                          fontSize: "1rem",
                        }}
                      >
                        {idiomaSeleccionado.first_stage_form_cna[32]}
                      </Typography>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formValuesFirstStage.TIPO_ARBITRAJE}
                      label="Arbitraje"
                      onChange={handleInputChange}
                      sx={styles.inputSize}
                      name="TIPO_ARBITRAJE"
                    >
                      <MenuItem value="Nacional">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[33]}
                        </Typography>
                      </MenuItem>
                      <MenuItem value="Internacional">
                        <Typography
                          fontSize="1rem"
                          fontFamily="TitilliumWeb-Regular"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[34]}
                        </Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box component="div">
                  {formValuesFirstStage.TIPO_ARBITRAJE === "Internacional" && (
                    <>
                      <Box component="div" pl={2} pr={10} mb={5}>
                        <Typography
                          color="#172833"
                          fontFamily="TitilliumWeb-Regular"
                          fontSize="1rem"
                        >
                          {idiomaSeleccionado.first_stage_form_cna[35]}
                        </Typography>
                      </Box>
                      <StyledTextField
                        id="outlined-basic"
                        multiline
                        label={idiomaSeleccionado.first_stage_form_cna[36]}
                        InputLabelProps={{
                          style: {
                            fontFamily: "TitilliumWeb-Regular",
                            fontSize: "1rem",
                          },
                        }}
                        variant="outlined"
                        placeholder={
                          idiomaSeleccionado.first_stage_form_cna[37]
                        }
                        rows={7}
                        // maxRows={30}
                        name="CIRCUNSTANCIA_INTERNACIONAL"
                        sx={styles.inputSize}
                        inputProps={{
                          style: {
                            fontFamily: "TitilliumWeb-Regular",
                            fontSize: "1rem",
                          },
                        }}
                        onChange={handleInputChange}
                        {...(errorsFirstStage.CIRCUNSTANCIA_INTERNACIONAL && {
                          error: true,
                          helperText:
                            errorsFirstStage.CIRCUNSTANCIA_INTERNACIONAL,
                        })}
                      />
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box component="div" pl={3} pt={2} pb={2}>
          <Button variant="contained" sx={styles.button} onClick={handleSubmit}>
            {idiomaSeleccionado.first_stage_form_cna[38]}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FirstFormContent;
