import React from "react";
import { Grid, Typography, Box } from "@mui/material";

import Paso_1 from "../../assets/imgs/HowWeWork/paso_1.webp";
import Paso_2 from "../../assets/imgs/HowWeWork/paso_2.webp";
import Paso_3 from "../../assets/imgs/HowWeWork/paso_3.webp";
import Paso_4 from "../../assets/imgs/HowWeWork/paso_4.webp";
import Paso_5 from "../../assets/imgs/HowWeWork/paso_5.webp";
import Paso_6 from "../../assets/imgs/HowWeWork/paso_6.webp";
import Paso_7 from "../../assets/imgs/HowWeWork/paso_7.webp";
import Case_Tracking from "../../assets/imgs/HowWeWork/casetracking-logo.svg";
import Lemontech from "../../assets/imgs/HowWeWork/lemontech-logo.svg";
import ArbitrationButton from "../../components/ArbitrationForm/ArbitrationButton";
import NavbarMenu from "../../components/NavbarMenu";

const HowWeWork = ({ idiomaSeleccionado, setIdiomaSeleccionado }) => {
  const styles = {
    contenedorGeneral: {
      backgroundColor: "#F5F5F5",
      paddingTop: "5rem",
    },

    contenedorInfoFormaTrabajo: {
      paddingLeft: "10rem",
      paddingRight: "10rem",

      "@media(max-width: 1746px)": {
        paddingLeft: "11rem",
        paddingRight: "10rem",
      },

      "@media(max-width: 1536px)": {
        paddingLeft: "4rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 1200px)": {
        paddingTop: "0",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorTitulo: {
      display: "block",
      "@media(max-width: 1200px)": {
        display: "flex",
        justifyContent: "center",
      },
    },

    contenedorSubtitulo: {
      "@media(max-width: 1200px)": {
        textAlign: "center",
      },
    },

    titulo: {
      fontFamily: "TitilliumWeb-SemiBold",
      fontSize: "4rem",
      "@media(max-width: 600px)": {
        fontSize: "2rem",
      },
      "@media(max-width: 299px)": {
        fontSize: "1.5rem",
      },
    },

    button: {
      color: "#F5F5F5",
      fontFamily: "TitilliumWeb-Regular",
      fontSize: "1rem",
      borderRadius: "38px",
      padding: "1rem",
      textTransform: "none",
      height: "50px",
      width: "300px",

      "@media(max-width: 600px)": {
        width: "250px",
      },
    },

    contenedorClausulas: {
      paddingRight: "20rem",

      "@media(max-width: 1746px)": {
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },

      "@media(max-width: 1200px)": {
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },

      "@media(max-width: 900px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorHerramientas: {
      padding: "5rem",

      "@media(max-width: 600px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    contenedorTextoHerramientas: {
      paddingLeft: "5rem",
      paddingRight: "2rem",

      "@media(max-width: 600px)": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
    },

    logosHerramientas: {
      "@media(max-width: 600px)": {
        width: "200px",
      },
    },
  };

  return (
    <Box component="div">
      <NavbarMenu
        value={2}
        menuItem={5}
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <ArbitrationButton
        idiomaSeleccionado={idiomaSeleccionado}
        setIdiomaSeleccionado={setIdiomaSeleccionado}
      />
      <Grid container sx={styles.contenedorGeneral}>
        <Grid item xs={12} lg={5} pb={5}>
          <Box component="div" sx={styles.contenedorInfoFormaTrabajo}>
            <Box component="div" sx={styles.contenedorTitulo}>
              <Typography
                variant="h1"
                mr={1}
                color="#1C3254"
                sx={styles.titulo}
              >
                {idiomaSeleccionado.how_work_cna[0]}
              </Typography>
              <Typography
                variant="h1"
                color="#1C3254"
                mb={4}
                sx={styles.titulo}
              >
                {idiomaSeleccionado.how_work_cna[1]}
              </Typography>
            </Box>
            <Box component="div" sx={styles.contenedorSubtitulo}>
              <Typography
                color="#172833"
                mb={3}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[2]}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={7} sx={styles.contenedorClausulas} mb={10}>
          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_1}
                alt="Imagen Paso 1 Ingreso De Solicitud CNA"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[3]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[4]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[5]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_2}
                alt="Imagen Paso 2 Recepción de antecedentes por parte de la Secretaría General"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[6]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[7]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_3}
                alt="Imagen Paso 1 Ingreso De Solicitud CNA"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[8]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[9]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[10]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[11]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[12]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_4}
                alt="Imagen Paso 1 Ingreso De Solicitud CNA"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[13]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[14]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[15]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_5}
                alt="Imagen Paso 1 Ingreso De Solicitud CNA"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[16]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[17]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[18]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[19]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[20]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_6}
                alt="Imagen Paso 1 Ingreso De Solicitud CNA"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[21]}
              </Typography>
              <Typography
                color="#172833"
                mb={1}
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[22]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[23]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={Paso_7}
                alt="Imagen Paso 1 Ingreso De Solicitud CNA"
                width="250px"
              />
            </Grid>
            <Grid item xs={12} md={8} padding={3}>
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.3rem" }}
              >
                {idiomaSeleccionado.how_work_cna[24]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[25]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignContent="center"
          bgcolor="#FFFFFF"
          sx={styles.contenedorHerramientas}
        >
          <Grid item xs={12} lg={4} mb={2}>
            <Box
              component="div"
              display="block"
              sx={styles.contenedorTextoHerramientas}
            >
              <Typography
                color="#1C3254"
                mb={2}
                sx={{ fontFamily: "TitilliumWeb-SemiBold", fontSize: "1.2rem" }}
              >
                {idiomaSeleccionado.how_work_cna[26]}
              </Typography>
              <Typography
                color="#172833"
                sx={{ fontFamily: "TitilliumWeb-Regular", fontSize: "1rem" }}
              >
                {idiomaSeleccionado.how_work_cna[27]}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} lg={4} mb={2} sx={{ textAlign: "center" }}>
            <Box
              pt={5}
              pb={5}
              component="img"
              src={Case_Tracking}
              alt="Logo De Case Tracking"
              sx={styles.logosHerramientas}
            />
          </Grid>

          <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
            <Box
              pt={5}
              pb={5}
              component="img"
              src={Lemontech}
              alt="Logo De Lemontech"
              sx={styles.logosHerramientas}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowWeWork;
